import { useEffect } from "react";
import { setCompaniesToAddPageFilter } from "../companyToConfirm/slice";
import { EmptyList } from "../emptyList/emptyList";
import { HeadingSize } from "../heading/dto";
import { LayoutComponent } from "../layout";
import { PaginationComponent } from "../pagination";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { RequestToConfirmFiltersComponent } from "./filters";
import { RequestToConfirmListComponent } from "./list";
import { RequestToConfirmModal } from "./requestToConfirmModal";
import { findAllRequestToConfirm, setConfirmOrDenyRequestStatus } from "./slice";
import { ErrorPopup } from "../errorPopup";
import { SuccessPopup } from "../successPopup";

export function RequestToConfirmView() {
    const requestToConfirmState = useAppSelector(state => state.requestToConfirm)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllRequestToConfirm(requestToConfirmState.requestToAddFilters))
    }, [requestToConfirmState.requestToAddFilters.page])

    return (
        <LayoutComponent
            breadcrumbItems={['Richieste da approvare']}
            headingSize={HeadingSize.LG}
            menuItem={MenuItems.REQUEST}
            headingLabel={'Richieste da approvare'}
            headingButtons={[]}
        >
            <ErrorPopup
                active={requestToConfirmState.confirmOrDenyRequestStatus === 'failed'}
                close={() => dispatch(setConfirmOrDenyRequestStatus('idle'))}
                message="Si è verificato un errore durante la modifica dell'azienda"
            />
            <SuccessPopup
                active={requestToConfirmState.confirmOrDenyRequestStatus === 'successfully'}
                close={() => dispatch(setConfirmOrDenyRequestStatus('idle'))}
                message="Azienda modificata correttamente"
            />
            <RequestToConfirmFiltersComponent />
            <RequestToConfirmListComponent />
            <RequestToConfirmModal />
            {
                requestToConfirmState.findAllUpdateCompanySettingsResponse !== undefined &&
                requestToConfirmState.findAllUpdateCompanySettingsResponse?.total > 0 &&
                <PaginationComponent
                    page={requestToConfirmState.findAllUpdateCompanySettingsResponse !== undefined ? requestToConfirmState.findAllUpdateCompanySettingsResponse.page : 0}
                    count={requestToConfirmState.findAllUpdateCompanySettingsResponse !== undefined ? requestToConfirmState.findAllUpdateCompanySettingsResponse.totalPage : 0}
                    onChange={page => dispatch(setCompaniesToAddPageFilter(page))}
                />
            }
            {
                requestToConfirmState.findAllUpdateCompanySettingsResponse !== undefined &&
                requestToConfirmState.findAllUpdateCompanySettingsResponse?.total === 0 &&
                <EmptyList />
            }
        </LayoutComponent>
    )
}