import { useEffect, useState } from "react";
import { findAccountantAvatarById, setEditAccountantFindAvatarStatus } from "../accountant/slice";
import { editMeAccountant, findMe, setEditMeAccountantDescription, setEditMeAccountantName, setEditMeAccountantPhoneNumber, setEditMeAccountantRequestActivityNotifications, setEditMeAccountantRequestOperationsNotifications, setEditMeAccountantStatus, setEditMeAccountantSurname, setFindMeStatus } from "../auth/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { findAllFinancialAdvisorsFilter } from "../dashboard/slice";
import { ErrorPopup } from "../errorPopup";
import { FileInputComponent } from "../fileInput";
import { findFinancialAdvisorsListAvatarsById } from "../financialAdvisor/slice";
import { FormGroup } from "../formGroup";
import { HeadingSize } from "../heading/dto";
import { MailIcon } from "../icons/mail";
import { SmartphoneIcon } from "../icons/smartphone";
import { keycloak } from "../keycloak";
import { LayoutComponent } from "../layout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { SwitchComponent } from "../switch";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";

export function EditAccountantView() {
    const dispatch = useAppDispatch()

    const accountantState = useAppSelector(state => state.accountant)
    const dashboardState = useAppSelector(state => state.dashboard)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const authState = useAppSelector(state => state.auth)

    let formData = new FormData();

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    useEffect(() => {
        if (
            dashboardState.findAllFinancialAdvisorsFilterStatus === "successfully" &&
            dashboardState.findAllFinancialAdvisorsFilterResponse !== undefined &&
            dashboardState.findAllFinancialAdvisorsFilterResponse.data !== undefined &&
            dashboardState.findAllFinancialAdvisorsFilterResponse.data.length > 0
        ) {
            dispatch(findFinancialAdvisorsListAvatarsById(dashboardState.findAllFinancialAdvisorsFilterResponse.data.map(data => data.avatarObjectId)))
        }
    }, [dashboardState.findAllFinancialAdvisorsFilterStatus])

    useEffect(() => {
        if (keycloak.hasRealmRole('accountant'))
            dispatch(findAllFinancialAdvisorsFilter({
                itemsPerPage: 0,
                page: 0,
                accountant: authState.findMeResponse?.id.toString() || '',
                financialAdvisor: '',
                allofall: false
            }))
    }, [])

    useEffect(() => {
        if (authState.editMeAccountantStatus === 'successfully') {
            dispatch(findMe())
        }
    }, [authState.editMeAccountantStatus])

    useEffect(() => {
        if (authState.findMeStatus === 'successfully' && authState.findMeResponse !== undefined && authState.findMeResponse.avatarObjectId !== null) {
            dispatch(findAccountantAvatarById(authState.findMeResponse.avatarObjectId))
        }
        dispatch(setFindMeStatus("idle"))
    }, [authState.findMeStatus])

    useEffect(() => {
        if (accountantState.editAccountantFindAvatarStatus === 'successfully') {
            dispatch(setEditAccountantFindAvatarStatus('idle'))
            if (accountantState.editAccountantAvatar !== undefined && accountantState.editAccountantAvatar !== null) {
                setAvatarToBase64(accountantState.editAccountantAvatar)
            }
        }
    }, [accountantState.editAccountantFindAvatarStatus])

    return (
        <LayoutComponent
            headingLabel={'Modifica Contabile'}
            menuItem={MenuItems.ACCOUNTANT}
            showSpinner={
                authState.findMeStatus === 'loading' || authState.editMeAccountantStatus === 'loading' || dashboardState.findAllFinancialAdvisorsFilterStatus === 'loading' ||
                authState.findMeStatus === 'failed'
            }
            headingButtons={[
                <ButtonComponent
                    key='heading-button-update-accountant'
                    label={"Aggiorna"}
                    onClick={() => {
                        if (file !== null)
                            formData.append('file', file)
                        formData.append('accountantUpdateDTO', JSON.stringify(authState.editMeAccountantRequest))
                        dispatch(editMeAccountant({ id: authState.findMeResponse !== undefined ? authState.findMeResponse.id.toString() : '', data: formData }))
                    }} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.SM} iconStyle={IconStyle.OFF}
                    disabled={
                        (authState.editMeAccountantRequest.name !== undefined && authState.editMeAccountantRequest.name === '') ||
                        (authState.editMeAccountantRequest.surname !== undefined && authState.editMeAccountantRequest.surname === '') || (
                            authState.editMeAccountantRequest.phoneNumber !== undefined && authState.editMeAccountantRequest.phoneNumber !== null && authState.editMeAccountantRequest.phoneNumber !== '' &&
                            (authState.editMeAccountantRequest.phoneNumber.length < 9 || authState.editMeAccountantRequest.phoneNumber.length > 10)
                        )
                    }
                />]}
            breadcrumbItems={['Contabile', 'Modifica Contabile']}
            headingSize={HeadingSize.LG}
        >
            <ErrorPopup
                active={authState.editMeAccountantStatus === 'failed'}
                close={() => dispatch(setEditMeAccountantStatus('idle'))}
                message="Si è verificato un errore durante la modifica del contabile"
            />
            <SuccessPopup
                active={authState.editMeAccountantStatus === 'successfully'}
                close={() => dispatch(setEditMeAccountantStatus('idle'))}
                message="Contabile modificato"
            />
            <FormGroup label={"Nome"} required
                inputs={[
                    <TextInput id={"accountant-edit-name"} defaultValue={authState.findMeResponse?.name} onChange={e => dispatch(setEditMeAccountantName(e.target.value))} key={"accountant-edit-name"} type={"text"} placeholder={"nome"} />,
                    <TextInput id={"accountant-edit-surname"} defaultValue={authState.findMeResponse?.surname} onChange={e => dispatch(setEditMeAccountantSurname(e.target.value))} key={"accountant-edit-surname"} type={"text"} placeholder={"cognome"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Email"}
                inputs={[
                    <TextInput startIcon={<MailIcon colorBase="" />} defaultValue={authState.findMeResponse?.email} disabled id={"accountant-edit-email"} key={"accountant-edit-email"} type={"text"} placeholder={"email"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Telefono"}
                inputs={[
                    <TextInput infoText="Inserire da 9 a 10 caratteri" startIcon={<SmartphoneIcon colorBase="" />} defaultValue={authState.findMeResponse?.phoneNumber} onChange={e => dispatch(setEditMeAccountantPhoneNumber(e.target.value === '' ? null : e.target.value))} id={"accountant-edit-phone"} key={"accountant-edit-phone"} type={"text"} placeholder={"telefono"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Avatar"} labelDescription="Verrà visualizzata sul vostro profilo"
                inputs={[
                    <div key={"accountant-edit-avatar-upload"} className="d-flex align-items-center justify-content-center gap-2">
                        <AvatarComponent src={avatarToBase64} key={"accountant-edit-avatar"} type={AvatarType.SINGLE} size={AvatarSize.XXL} />
                        <FileInputComponent onChange={e => { setFile(e[0]); toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data)) }} id={"accountant-edit-upload"} key={"accountant-edit-upload"} />
                    </div>
                ]}
                style={"row"}
            />
            <FormGroup label={"Bio"} labelDescription="Scrivi una breve introduzione"
                inputs={[
                    <TextInput label="Descrizione" defaultValue={authState.findMeResponse?.description} onChange={e => dispatch(setEditMeAccountantDescription(e.target.value))} id={"accountant-edit-bio"} key={"accountant-edit-bio"} type={"text"} multiline placeholder={"Un po' di informazioni su di te"} infoText="275 caratteri rimasti" />
                ]}
                style={"row"}
            />
            <FormGroup label={"Commercialisti associati"} labelDescription="Elenco dei commercialisti associati all'Account"
                inputs={
                    [
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            {dashboardState.findAllFinancialAdvisorsFilterResponse?.data
                                .map((financialAdvisor, index) => (
                                    <div className="company-roles-frame--active">
                                        <AvatarComponent
                                            src={financialAdvisorState.findFinancialAdvisorsListAvatarsByIdResponse[index] || ''}
                                            type={AvatarType.USER} size={AvatarSize.MD}
                                            name={financialAdvisor.name + ' ' + financialAdvisor.surname}
                                            subLabel={financialAdvisor.email}
                                        />
                                    </div>
                                ))}
                        </div>
                    ]
                }
                style={"row"}
            />
            <div className="mt-5">
                <FormGroup label={"Gestione Notifiche"}
                    inputs={[
                        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', gap: 40 }}>
                            <SwitchComponent
                                label="Attività"
                                id={"edit-accountant-activity-notifications"}
                                onChange={(_, checked) => dispatch(setEditMeAccountantRequestActivityNotifications(checked))}
                                checked={authState.editMeAccountantRequest.activityNotifications === undefined ? authState.findMeResponse?.activityNotifications : authState.editMeAccountantRequest.activityNotifications}
                            />
                            <SwitchComponent
                                label="Operazioni (messaggi, documenti, conto corrente)"
                                id={"edit-accountant-document-notifications"}
                                onChange={(_, checked) => dispatch(setEditMeAccountantRequestOperationsNotifications(checked))}
                                checked={authState.editMeAccountantRequest.operationsNotifications === undefined ? authState.findMeResponse?.operationsNotifications : authState.editMeAccountantRequest.operationsNotifications}
                            />
                        </div>
                    ]}
                    style={"row"}
                />
            </div>
        </LayoutComponent>
    )
}