import axios from "axios";
import { keycloak } from "../keycloak";
import { FinancialAdvisorResponseDTO, FindAllFinancialAdvisorFiltersDTO, FindAllFinancialAdvisors, SetAccountantDTO } from "./dto";
import { FinancialAdvisorService } from "./service";

export class FinancialAdvisorServiceImpl implements FinancialAdvisorService {

    public findAllFinancialAdvisor(filters: FindAllFinancialAdvisorFiltersDTO): Promise<FindAllFinancialAdvisors> {
        let url: string = "/api/financial-advisors?" +
            "page=" + filters.page +
            "&itemsPerPage=" + filters.itemsPerPage +
            (filters.financialAdvisor !== '' ? ('&financialAdvisor=' + filters.financialAdvisor) : '') +
            (filters.accountant !== '' ? ('&accountant=' + filters.accountant) : '') +
            (filters.allofall !== false ? ('&allofall=' + filters.allofall) : '')
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public createFinancialAdvisor(request: FormData): Promise<void> {
        let url: string = "/api/financial-advisors"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findFinancialAdvisor(id: string): Promise<FinancialAdvisorResponseDTO> {
        let url: string = "/api/financial-advisors/" + (window.location.pathname.split('edit-financial-advisor/')[1] === undefined ? id : window.location.pathname.split('edit-financial-advisor/')[1])
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editFinancialAdvisor(request: FormData, id: string): Promise<void> {
        let url: string = "/api/financial-advisors/" + (window.location.pathname.split('edit-financial-advisor/')[1] === undefined ? id : window.location.pathname.split('edit-financial-advisor/')[1])
        return axios({
            url: url,
            method: "PUT",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteFinancialAdvisor(id: string): Promise<void> {
        let url: string = "/api/financial-advisors/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }

    public setAccountant(id: string, accountant: SetAccountantDTO): Promise<void> {
        let url: string = "/api/financial-advisors/" + id + "/setAccountant"
        return axios({
            url: url,
            method: "PUT",
            data: accountant,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }
}
