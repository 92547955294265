import { TableBody } from "@mui/material";
import { ReactElement, useEffect } from "react";
import { findAllCompanyWithoutPagination } from "../company/slice";
import { AccountingSchemeEnum } from "../companyQuotationWizard/dto";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import { FinancialAdvisorMenuComponent } from "./menu";
import { findAllFinancialAdvisor } from "./slice";
import { findAllAccountant } from "../accountant/slice";

export function FinancialAdvisorListComponent() {
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const accountantState = useAppSelector(state => state.accountant)
    const companyState = useAppSelector(state => state.company)
    let financialAdvisorList: ReactElement[] = []

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (financialAdvisorState.findAllFinancialAdvisorResponse === undefined)
            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
        dispatch(findAllCompanyWithoutPagination())
        dispatch(findAllAccountant({...accountantState.findAllAccountantFilters, accountant: '', financialAdvisor: ''}))
    }, [])

    useEffect(() => {
        if (financialAdvisorState.deleteFinancialAdvisorStatus === "successfully") {
            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
            dispatch(findAllCompanyWithoutPagination())
            dispatch(findAllAccountant(accountantState.findAllAccountantFilters))
        }
    }, [financialAdvisorState.deleteFinancialAdvisorStatus])

    if (
        companyState.findAllCompaniesWithoutPaginationStatus === 'loading' ||
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading' ||
        accountantState.findAllAccountantStatus === 'loading'
    ) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <SpinnerComponent size={"small"} />
            </div>
        )
    }

    if (
        companyState.findAllCompaniesWithoutPaginationStatus === 'failed' ||
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed' ||
        accountantState.findAllAccountantStatus === 'failed'
    ) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    if (
        financialAdvisorState.findAllFinancialAdvisorStatus === 'successfully' &&
        financialAdvisorState.findAllFinancialAdvisorResponse !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorResponse.data !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorResponse.data.length > 0 &&
        companyState.findAllCompaniesWithoutPaginationStatus === 'successfully' &&
        companyState.findAllCompaniesWithoutPaginationResponse !== undefined &&
        companyState.findAllCompaniesWithoutPaginationResponse.data !== undefined &&
        accountantState.findAllAccountantResponse !== undefined &&
        accountantState.findAllAccountantResponse.data !== undefined
    ) {
        financialAdvisorState.findAllFinancialAdvisorResponse.data.forEach(financialAdvisor => {
            const accountant = accountantState.findAllAccountantResponse?.data.find(accountant => accountant.id === financialAdvisor.accountantId)
            const company = companyState.findAllCompaniesWithoutPaginationResponse?.data.filter(company => company.financialAdvisorId === financialAdvisor.id)
            const ordinaryCompany = company?.filter(_company => _company.accountingScheme === AccountingSchemeEnum.Ordinary)
            const simplifiedCompany = company?.filter(_company => _company.accountingScheme === AccountingSchemeEnum.Simplified)
            financialAdvisorList.push(
                <TableRowComponent key={'financial-advisor-row-user-' + financialAdvisor.id}>
                    <TableCellComponent label={financialAdvisor.name + ' ' + financialAdvisor.surname} cellType={"row-medium"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={String(company?.length)} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"center"} />
                    <TableCellComponent content={<PillComponent label={String(simplifiedCompany?.length)} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"center"} />
                    <TableCellComponent content={<PillComponent label={String(ordinaryCompany?.length)} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"center"} />
                    <TableCellComponent label={accountant?.name + ' ' + accountant?.surname} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent content={<FinancialAdvisorMenuComponent id={financialAdvisor.id} />} cellType={"row-regular"} alignLabel={"right"} />
                </TableRowComponent>
            )
        })
    }

    return (
        <TableComponent>
            <TableHeadComponent>
                <TableCellComponent label={"Commercialisti"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent size='small' label={"aziende"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent size='small' label={"in semplificata"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent size='small' label={"in ordinaria"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent size='large' label={"account"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
            </TableHeadComponent>
            <TableBody>
                {financialAdvisorList}
            </TableBody>
        </TableComponent>
    )
}