import { MenuItem, TableBody } from "@mui/material";
import { format } from "date-fns";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { CheckboxComponent } from "../../checkbox";
import { CheckboxSize, CheckboxStyle } from "../../checkbox/dto";
import { EmptyList } from "../../emptyList/emptyList";
import { ErrorPopup } from "../../errorPopup";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { DownloadIcon } from "../../icons/download";
import { SearchIcon } from "../../icons/search";
import { InfoTextPopup } from "../../infoTextPopup";
import { NewObjectService } from "../../objects/service";
import { findFileById, findMultipleFileById, setFindDocumentStatus, setFindMultipleFileById } from "../../objects/slice";
import { PaginationComponent } from "../../pagination";
import { PillComponent } from "../../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../../pills/dto";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SelectComponent } from "../../select";
import { SpinnerComponent } from "../../spinner";
import { SuccessPopup } from "../../successPopup";
import { TableComponent } from "../../table";
import { TableCellComponent } from "../../table/tableCell";
import { TableHeadComponent } from "../../table/tableHead";
import { TableRowComponent } from "../../table/tableRow";
import { TextInput } from "../../textInput";
import { downloadZip } from "../../utils";
import colors from '../../utils/index.module.scss';
import { F24Map, F24Typology, FiscalDocumentStatus, FiscalDocumentType, StatusMap } from "./dtoDocument";
import { FiscalDocumentAreaModal } from "./fiscalDocumentAreaModal";
import { MenuFiscalDocument } from "./menuFiscalDocument";
import { findAllFiscalDocumentsF24, findByIdFiscalDocument, findByIdFiscalDocuments, setDeleteFiscalDocumentStatus, setDownloadFiscalDocuments, setFileNameF24Filter, setFiscalDocumentF24FilterPage, setOpenFiscalDocumentAreaModal, setSaveFiscalDocumentStatus, setStatusF24Filter, setTypologyF24Filter } from "./sliceDocument";

export function F24Tab() {
    const dispatch = useAppDispatch()

    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)
    const companyState = useAppSelector(state => state.company)

    const objectService = NewObjectService()

    useEffect(() => {
        if (companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [companyState.findCompanyResponse])

    useEffect(() => {
        if (fiscalDocumentState.findAllFiscalDocumentF24Status === 'successfully' && fiscalDocumentState.findAllFiscalDocumentF24Response !== undefined) {
            if (fiscalDocumentState.findAllFiscalDocumentF24Response.page >= fiscalDocumentState.findAllFiscalDocumentF24Response.totalPage) {
                dispatch(setFiscalDocumentF24FilterPage(0))
                dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, page: 0 }))
            }
        }
    }, [fiscalDocumentState.findAllFiscalDocumentF24Status])

    useEffect(() => {
        if (fiscalDocumentState.createFiscalDocumentsStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [fiscalDocumentState.createFiscalDocumentsStatus, companyState.findCompanyResponse])

    useEffect(() => {
        if (fiscalDocumentState.deleteFiscalDocumentStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [fiscalDocumentState.deleteFiscalDocumentStatus, companyState.findCompanyResponse])

    if (
        fiscalDocumentState.findAllFiscalDocumentF24Status === 'loading' ||
        fiscalDocumentState.createFiscalDocumentsStatus === 'loading' ||
        fiscalDocumentState.deleteFiscalDocumentStatus === 'loading' ||
        companyState.findCompanyStatus === 'loading'
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    let fiscalDocumentF24Rows: ReactNode[] = []

    if (fiscalDocumentState.findAllFiscalDocumentF24Status === 'successfully' &&
        fiscalDocumentState.findAllFiscalDocumentF24Response !== undefined &&
        fiscalDocumentState.findAllFiscalDocumentF24Response.data !== undefined &&
        fiscalDocumentState.findAllFiscalDocumentF24Response.data.length > 0 &&
        companyState.taxAreaTabValue === 1
    ) {
        fiscalDocumentState.findAllFiscalDocumentF24Response.data.forEach(data => {
            let typology = F24Map.get(data.typology as F24Typology)
            let status = StatusMap.get(data.status)
            fiscalDocumentF24Rows.push(
                <TableRowComponent key={'fiscal-documents-f24-' + data.id}>
                    <TableCellComponent
                        content={
                            <CheckboxComponent
                                size={CheckboxSize.SM}
                                style={CheckboxStyle.SQUARE}
                                checked={fiscalDocumentState.downloadFiscalDocuments.find(doc => doc.objectId.toString() === data.objectId.toString()) !== undefined}
                                onChange={(_, checked) => {
                                    if (checked) {
                                        dispatch(setDownloadFiscalDocuments([...fiscalDocumentState.downloadFiscalDocuments, { id: data.id, name: data.fileName, objectId: data.objectId.toString() }]))
                                    } else {
                                        const remove = fiscalDocumentState.downloadFiscalDocuments.filter(_doc => _doc.objectId.toString() !== data.objectId.toString())
                                        dispatch(setDownloadFiscalDocuments(remove))
                                    }
                                }}
                            />
                        }
                        cellType={"row-regular"}
                        alignLabel={"right"}
                    />
                    <TableCellComponent label={data.fileName} cellType={"row-semibold"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={typology !== undefined ? typology : ''} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={status !== undefined ? status : ''} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.expirationDate && format(new Date(data.expirationDate), 'dd/MM/yyyy')} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.date && format(new Date(data.date), 'dd/MM/yyyy')} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.lastDownloadDate !== null ? '' : '-'} cellType={"row-regular"} content={data.lastDownloadDate !== null ? <PillComponent label={format(new Date(data.lastDownloadDate), 'dd/MM/yyyy')} size={PillsSize.SM} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} /> : ''} alignLabel={"left"} />
                    <TableCellComponent
                        content={
                            <div className="d-flex justify-content-end align-items-center w-100">
                                <InfoTextPopup
                                    label={"Scarica"}
                                    position="bottom"
                                    children={
                                        <ButtonComponent
                                            icon={<DownloadIcon colorBase={colors.neutral600} />}
                                            onClick={() => {
                                                dispatch(findFileById(data.objectId.toString())).then((e) => {
                                                    //@ts-ignore
                                                    objectService.downloadBase64WithExtension(data.fileName, e.payload !== null ? e.payload : '')
                                                    dispatch(findByIdFiscalDocument(data.id))
                                                        .then(() => {
                                                            dispatch(setFindDocumentStatus('idle'))
                                                            dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '' }))
                                                        })
                                                });
                                            }}
                                            color={Colors.NEUTRAL}
                                            label=""
                                            variant={Variant.LINK}
                                            size={Size.SM}
                                            iconStyle={IconStyle.ONLY}
                                        />
                                    }
                                />
                                <MenuFiscalDocument fiscalDocument={data} />
                            </div>
                        }
                        cellType={"row-regular"}
                        alignLabel={"right"}
                    />
                </TableRowComponent>
            )
        })
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: 'flex-start',
            gap: '40px',
            width: '100%'
        }}>
            <ErrorPopup
                active={fiscalDocumentState.deleteFiscalDocumentStatus === 'failed'}
                close={() => dispatch(setDeleteFiscalDocumentStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione del documento"
            />
            <ErrorPopup
                active={fiscalDocumentState.createFiscalDocumentsStatus === 'failed'}
                close={() => dispatch(setSaveFiscalDocumentStatus('idle'))}
                message="Si è verificato un errore durante il salvataggio del documento"
            />
            <SuccessPopup
                active={fiscalDocumentState.deleteFiscalDocumentStatus === 'successfully'}
                close={() => dispatch(setDeleteFiscalDocumentStatus('idle'))}
                message="Documento eliminato correttamente"
            />
            <SuccessPopup
                active={fiscalDocumentState.createFiscalDocumentsStatus === 'successfully'}
                close={() => dispatch(setSaveFiscalDocumentStatus('idle'))}
                message="Documento salvato correttamente"
            />
            <div style={{ width: '100%', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                <HeadingComponent
                    label={"F24"}
                    buttons={[
                        <>
                            {
                                fiscalDocumentState.downloadFiscalDocuments.length !== 0 &&
                                <ButtonComponent
                                    key={'accounting-area-documents-sent-button'}
                                    label={"Scarica"}
                                    onClick={() => {
                                        if (fiscalDocumentState.downloadFiscalDocuments.length === 1) {
                                            dispatch(findFileById(fiscalDocumentState.downloadFiscalDocuments.find(id => id.objectId)?.objectId!)).then((e) => {
                                                //@ts-ignore
                                                objectService.downloadBase64WithExtension(fiscalDocumentState.downloadFiscalDocuments.find(id => id.name)?.name, e.payload !== null ? e.payload : '')
                                                dispatch(findByIdFiscalDocument(fiscalDocumentState.downloadFiscalDocuments.find(id => id.id)?.id!))
                                                    .then(() => {
                                                        dispatch(setFindDocumentStatus('idle'))
                                                        dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '' }))
                                                    })
                                            });
                                        } else {
                                            dispatch(findMultipleFileById(fiscalDocumentState.downloadFiscalDocuments.map(doc => doc.objectId))).then((e) => {
                                                //@ts-ignore
                                                downloadZip(e.payload !== '' ? e.payload : '', fiscalDocumentState.downloadFiscalDocuments.map(name => name.name), companyState.findCompanyResponse?.businessName + "-DocumentiFiscali-F24")
                                                dispatch(findByIdFiscalDocuments(fiscalDocumentState.downloadFiscalDocuments.map(id => id.id)))
                                                    .then(() => {
                                                        dispatch(setFindMultipleFileById("idle"))
                                                        dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '' }))
                                                    })
                                            })
                                        }
                                    }}
                                    color={Colors.PRIMARY}
                                    variant={Variant.OUTLINE}
                                    size={Size.SM}
                                    iconStyle={IconStyle.OFF}
                                />
                            }
                            <ButtonComponent
                                key={'f24-button'}
                                label={"Aggiungi file"}
                                onClick={() => dispatch(setOpenFiscalDocumentAreaModal(true))}
                                color={Colors.PRIMARY}
                                variant={Variant.SOLID}
                                size={Size.SM}
                                iconStyle={IconStyle.OFF}
                            />
                        </>
                    ]}
                    size={HeadingSize.MD}
                />
                <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
                    <div className="col-12 col-lg p-0 m-0">
                        <TextInput value={fiscalDocumentState.fiscalDocumentF24Filters.fileName} onChange={(e) => dispatch(setFileNameF24Filter(e.target.value))} id="f24-name" type={"text"} placeholder={"Cerca nome del file"} startIcon={<SearchIcon colorBase={""} />} />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={fiscalDocumentState.fiscalDocumentF24Filters.typology}
                            onChange={(e) => {
                                dispatch(setTypologyF24Filter(e.target.value))
                                if (companyState.findCompanyResponse !== undefined) {
                                    dispatch(findAllFiscalDocumentsF24({
                                        typology: e.target.value as F24Typology,
                                        fileName: fiscalDocumentState.fiscalDocumentF24Filters.fileName,
                                        itemsPerPage: fiscalDocumentState.fiscalDocumentF24Filters.itemsPerPage,
                                        page: fiscalDocumentState.fiscalDocumentF24Filters.page,
                                        month: fiscalDocumentState.fiscalDocumentF24Filters.month,
                                        year: fiscalDocumentState.fiscalDocumentF24Filters.year,
                                        status: fiscalDocumentState.fiscalDocumentF24Filters.status,
                                        type: fiscalDocumentState.fiscalDocumentF24Filters.type,
                                        companyId: companyState.findCompanyResponse.id.toString()
                                    }))
                                }
                            }}
                            id={"f24-typology"}
                            menuItems={
                                [
                                    <MenuItem key='f24-typology-filter' value=''>Seleziona tipologia</MenuItem>,
                                    <MenuItem key={F24Typology.contributions} value={F24Typology.contributions}>Contributi</MenuItem>,
                                    <MenuItem key={F24Typology.directTax} value={F24Typology.directTax}>Imposte dirette</MenuItem>,
                                    <MenuItem key={F24Typology.iva} value={F24Typology.iva}>IVA</MenuItem>,
                                    <MenuItem key={F24Typology.other} value={F24Typology.other}>Altro</MenuItem>
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={fiscalDocumentState.fiscalDocumentF24Filters.status}
                            onChange={(e) => {
                                dispatch(setStatusF24Filter(e.target.value))
                                if (companyState.findCompanyResponse !== undefined) {
                                    dispatch(findAllFiscalDocumentsF24({
                                        typology: fiscalDocumentState.fiscalDocumentF24Filters.typology,
                                        fileName: fiscalDocumentState.fiscalDocumentF24Filters.fileName,
                                        itemsPerPage: fiscalDocumentState.fiscalDocumentF24Filters.itemsPerPage,
                                        page: fiscalDocumentState.fiscalDocumentF24Filters.page,
                                        month: fiscalDocumentState.fiscalDocumentF24Filters.month,
                                        year: fiscalDocumentState.fiscalDocumentF24Filters.year,
                                        status: e.target.value,
                                        type: fiscalDocumentState.fiscalDocumentF24Filters.type,
                                        companyId: companyState.findCompanyResponse.id.toString()
                                    }))
                                }
                            }}
                            id={"financial-statements-status"}
                            menuItems={
                                [
                                    <MenuItem key='financial-statements-status-filter' value=''>Seleziona stato</MenuItem>,
                                    <MenuItem key={FiscalDocumentStatus.toRead} value={FiscalDocumentStatus.toRead}>Da leggere</MenuItem>,
                                    <MenuItem key={FiscalDocumentStatus.read} value={FiscalDocumentStatus.read}>Letto</MenuItem>,
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                        <div className="row p-0 m-0" style={{ gap: '8px' }}>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Pulisci"}
                                    onClick={() => {
                                        dispatch(setFileNameF24Filter(''))
                                        dispatch(setTypologyF24Filter('' as F24Typology))
                                        dispatch(setStatusF24Filter(''))
                                        if (companyState.findCompanyResponse !== undefined) {
                                            dispatch(findAllFiscalDocumentsF24({
                                                typology: '' as F24Typology,
                                                fileName: '',
                                                itemsPerPage: fiscalDocumentState.fiscalDocumentF24Filters.itemsPerPage,
                                                page: fiscalDocumentState.fiscalDocumentF24Filters.page,
                                                month: fiscalDocumentState.fiscalDocumentF24Filters.month,
                                                year: fiscalDocumentState.fiscalDocumentF24Filters.year,
                                                status: '',
                                                type: fiscalDocumentState.fiscalDocumentF24Filters.type,
                                                companyId: companyState.findCompanyResponse.id.toString()
                                            }))
                                        }
                                    }}
                                    color={Colors.NEUTRAL}
                                    variant={Variant.OUTLINE}
                                    size={Size.MD}
                                    iconStyle={IconStyle.OFF}
                                />
                            </div>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Applica"}
                                    onClick={() => {
                                        if (companyState.findCompanyResponse !== undefined)
                                            dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, companyId: companyState.findCompanyResponse.id.toString() }))
                                    }}
                                    color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                            </div>
                        </div>
                    </div>
                </div>
                <TableComponent>
                    <TableHeadComponent>
                        <TableCellComponent
                            cellType={"head"}
                            alignLabel={"right"}
                            size="context-menu"
                            content={
                                <CheckboxComponent
                                    size={CheckboxSize.SM}
                                    checked={fiscalDocumentState.downloadFiscalDocuments.length !== 0 && fiscalDocumentState.downloadFiscalDocuments.length === fiscalDocumentState.findAllFiscalDocumentF24Response?.total}
                                    indeterminate={fiscalDocumentState.downloadFiscalDocuments.length < (fiscalDocumentState.findAllFiscalDocumentF24Response?.total || 0) && fiscalDocumentState.downloadFiscalDocuments.length > 0}
                                    style={CheckboxStyle.SQUARE}
                                    onChange={(_, checked) => {
                                        if (checked) {
                                            const total = fiscalDocumentState.findAllFiscalDocumentF24Response?.data.map(doc => { return ({ id: doc.id, name: doc.fileName, objectId: doc.objectId }) })
                                            dispatch(setDownloadFiscalDocuments(total))
                                        } else {
                                            dispatch(setDownloadFiscalDocuments([]))
                                        }
                                    }}
                                />
                            }
                        />
                        <TableCellComponent label={"Nome del file"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Tipologia"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Stato"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Scadenza"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Data invio"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Ultimo download"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
                    </TableHeadComponent>
                    <TableBody>
                        {fiscalDocumentF24Rows}
                    </TableBody>
                </TableComponent>
            </div>
            <FiscalDocumentAreaModal type={FiscalDocumentType.f24} />
            {
                fiscalDocumentState.findAllFiscalDocumentF24Response !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentF24Response.total > 0 &&
                <PaginationComponent
                    page={fiscalDocumentState.findAllFiscalDocumentF24Response !== undefined ? fiscalDocumentState.findAllFiscalDocumentF24Response.page : 0}
                    count={fiscalDocumentState.findAllFiscalDocumentF24Response !== undefined ? fiscalDocumentState.findAllFiscalDocumentF24Response.totalPage : 0}
                    onChange={(page) => dispatch(setFiscalDocumentF24FilterPage(page))}
                />
            }
            {
                fiscalDocumentState.findAllFiscalDocumentF24Response !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentF24Response.total === 0 &&
                <div style={{ width: '100%', height: '50vh' }}>
                    <EmptyList />
                </div>
            }
        </div>
    )
}