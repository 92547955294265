import { MenuItem, TableBody } from "@mui/material";
import { compareAsc, format } from "date-fns";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { CheckboxComponent } from "../checkbox";
import { CheckboxSize, CheckboxStyle } from "../checkbox/dto";
import { PeriodicityEnum } from "../companyQuotationWizard/dto";
import { EmptyList } from "../emptyList/emptyList";
import { ErrorPopup } from "../errorPopup";
import { HeadingComponent } from "../heading";
import { HeadingSize } from "../heading/dto";
import { DownloadIcon } from "../icons/download";
import { InfoTextPopup } from "../infoTextPopup";
import { NewObjectService } from "../objects/service";
import { findFileById, findMultipleFileById, setFindMultipleFileById, setSaveDocumentStatus } from "../objects/slice";
import { PaginationComponent } from "../pagination";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { SuccessPopup } from "../successPopup";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import { downloadZip } from "../utils";
import colors from '../utils/index.module.scss';
import { AccountingAreaDocumentsModal } from "./accountingAreaDocumentsModal";
import { CompanyMenuDocumentsComponent } from "./menuDocuments";
import { findAllSentDocuments, findDocumentById, findDocumentsById, setDocumentSentFilterPage, setDocumentSentFilterPeriod, setDocumentSentFilterYear, setDownloadDocuments, setFindDocumentStatus, setOpenDocumentModal } from "./slice";

export function DocumentSent() {
    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)
    const objectState = useAppSelector(state => state.object)

    const objectService = NewObjectService()

    useEffect(() => {
        dispatch(setDownloadDocuments([]))
    }, [])

    useEffect(() => {
        if (companyState.findCompanyResponse)
            dispatch(findAllSentDocuments({ companyId: companyState.findCompanyResponse.id, period: companyState.documentSentFilters.period, year: companyState.documentSentFilters.year, page: companyState.documentSentFilters.page }))
    }, [companyState.documentSentFilters.page])

    useEffect(() => {
        if (companyState.findAllSentDocumentsStatus === 'successfully' && companyState.findAllSentDocumentsResponse !== undefined) {
            if (companyState.findAllSentDocumentsResponse.page >= companyState.findAllSentDocumentsResponse.totalPage) {
                dispatch(setDocumentSentFilterPage(0))
                dispatch(findAllSentDocuments({ companyId: companyState.findCompanyResponse?.id || 0, period: companyState.documentSentFilters.period, year: companyState.documentSentFilters.year, page: 0 }))
            }
        }
    }, [companyState.findAllSentDocumentsStatus])

    useEffect(() => {
        if (
            companyState.findCompanyStatus === 'successfully' &&
            companyState.findCompanyResponse !== undefined &&
            companyState.operationsTabValue === 1 &&
            companyState.documentTabValue === 1
        ) {
            dispatch(findAllSentDocuments({ companyId: companyState.findCompanyResponse.id, period: companyState.documentSentFilters.period, year: companyState.documentSentFilters.year, page: companyState.documentSentFilters.page }))
        }
    }, [companyState.findCompanyStatus, companyState.operationsTabValue, companyState.documentTabValue, companyState.documentSentFilters.year])

    if (
        companyState.findAllSentDocumentsStatus === 'loading' ||
        authState.findMeStatus === 'loading' ||
        companyState.findCompanyStatus === 'loading'
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    let sentRows: ReactNode[] = []

    const monthlyMap = new Map<number, string>([
        [0, '01'],
        [1, '02'],
        [2, '03'],
        [3, '04'],
        [4, '05'],
        [5, '06'],
        [6, '07'],
        [7, '08'],
        [8, '09'],
        [9, '10'],
        [10, '11'],
        [11, '12'],
    ])

    const quarterlyMap = new Map<number, string>([
        [0, 'I trimestre'],
        [1, 'II trimestre'],
        [2, 'III trimestre'],
        [3, 'IV trimestre'],
    ])

    if (
        companyState.findAllSentDocumentsStatus === 'successfully' &&
        authState.findMeStatus === 'successfully' &&
        companyState.findAllSentDocumentsResponse !== undefined &&
        companyState.findAllSentDocumentsResponse.data !== undefined &&
        companyState.findAllSentDocumentsResponse.data.length > 0 &&
        authState.findMeResponse !== undefined &&
        companyState.operationsTabValue === 1 &&
        companyState.documentTabValue === 1
    ) {
        companyState.findAllSentDocumentsResponse.data
            .forEach(data => {
                const operatorsReceiverDate = data.operatorsDownloadDate
                const companiesReceiverDate = data.companiesDownloadDate
                const accountantsReceiverDate = data.accountantsDownloadDate

                let lastDownloadDate: Date | null = null

                if (data.receiverGroup.length > 1) {
                    if (operatorsReceiverDate !== null)
                        lastDownloadDate = operatorsReceiverDate
                    if (companiesReceiverDate !== null) {
                        if (lastDownloadDate === null) {
                            lastDownloadDate = companiesReceiverDate
                        } else if (compareAsc(new Date(lastDownloadDate), new Date(companiesReceiverDate)) === -1) {
                            lastDownloadDate = companiesReceiverDate
                        }
                    }
                    if (accountantsReceiverDate !== null) {
                        if (lastDownloadDate === null) {
                            lastDownloadDate = accountantsReceiverDate
                        } else if (compareAsc(new Date(lastDownloadDate), new Date(accountantsReceiverDate)) === -1) {
                            lastDownloadDate = accountantsReceiverDate
                        }
                    }
                } else {
                    if (data.receiverGroup[0] === 'operators')
                        lastDownloadDate = operatorsReceiverDate
                    if (data.receiverGroup[0] === 'accountants') {
                        lastDownloadDate = accountantsReceiverDate
                    }
                    if (data.receiverGroup[0] === 'companies') {
                        lastDownloadDate = companiesReceiverDate
                    }
                }
                sentRows.push(
                    <TableRowComponent key={'sent-documents-' + data.id}>
                        <TableCellComponent
                            content={
                                <CheckboxComponent
                                    size={CheckboxSize.SM}
                                    style={CheckboxStyle.SQUARE}
                                    checked={companyState.downloadDocuments.find(doc => doc.objectId.toString() === data.objectId.toString()) !== undefined}
                                    onChange={(_, checked) => {
                                        if (checked) {
                                            dispatch(setDownloadDocuments([...companyState.downloadDocuments, { id: data.id, name: data.fileName, objectId: data.objectId.toString() }]))
                                        } else {
                                            const remove = companyState.downloadDocuments.filter(_doc => _doc.objectId.toString() !== data.objectId.toString())
                                            dispatch(setDownloadDocuments(remove))
                                        }
                                    }}
                                />
                            }
                            cellType={"row-regular"}
                            alignLabel={"right"}
                        />
                        <TableCellComponent label={data.fileName} cellType={"row-medium"} alignLabel={"left"} />
                        <TableCellComponent label={data.note.length <= 40 ? data.note : data.note.substring(0, 40).concat("...")} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent content={<PillComponent label={data.activity.activityType.name} size={PillsSize.SM} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent label={(companyState.findCompanyResponse?.periodicity === PeriodicityEnum.Monthly ? monthlyMap.get(data.period) : quarterlyMap.get(data.period)) + '/' + data.year.toString()} cellType={"row-regular"} alignLabel={"right"} />
                        <TableCellComponent label={data.date && format(new Date(data.date), 'dd/MM/yyyy')} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent label={lastDownloadDate !== null ? '' : '-'} cellType={"row-regular"} content={lastDownloadDate !== null ? <PillComponent label={format(new Date(lastDownloadDate), 'dd/MM/yyyy')} size={PillsSize.SM} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} /> : ''} alignLabel={"left"} />
                        <TableCellComponent
                            content={
                                <div className="d-flex justify-content-end align-items-center w-100">
                                    <InfoTextPopup
                                        label={"Scarica"}
                                        position="bottom"
                                        children={
                                            <ButtonComponent
                                                icon={<DownloadIcon colorBase={colors.neutral600} />}
                                                onClick={() => {
                                                    dispatch(findFileById(data.objectId.toString())).then((e) => {
                                                        //@ts-ignore
                                                        objectService.downloadBase64WithExtension(data.fileName, e.payload !== null ? e.payload : '')
                                                        dispatch(findDocumentById(data.id.toString()))
                                                            .then(() => {
                                                                dispatch(setFindDocumentStatus('idle'))
                                                                dispatch(findAllSentDocuments({ companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id : 0, period: companyState.documentSentFilters.period, year: companyState.documentSentFilters.year, page: companyState.documentSentFilters.page }))
                                                            })
                                                    });
                                                }}
                                                color={Colors.NEUTRAL}
                                                label=""
                                                variant={Variant.LINK}
                                                size={Size.SM}
                                                iconStyle={IconStyle.ONLY}
                                            />
                                        }
                                    />
                                    <CompanyMenuDocumentsComponent document={data} />
                                </div>
                            }
                            cellType={"row-regular"}
                            alignLabel={"right"}
                        />
                    </TableRowComponent>
                )
            })
    }

    const startYear = 2022
    let yearsMenuItems: ReactNode[] = []

    for (let year = startYear; year <= new Date().getFullYear() + 1; year++) {
        yearsMenuItems.push(<MenuItem key={year} value={year}>{year}</MenuItem>)
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: 'flex-start',
            gap: '40px',
            width: '100%'
        }}>
            <ErrorPopup
                active={objectState.saveDocumentStatus === 'failed'}
                close={() => dispatch(setSaveDocumentStatus('idle'))}
                message="Si è verificato un errore durante il salvataggio del documento"
            />
            <SuccessPopup
                active={objectState.saveDocumentStatus === 'successfully'}
                close={() => dispatch(setSaveDocumentStatus('idle'))}
                message="Documento salvato"
            />
            <div style={{ width: '100%', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                <HeadingComponent
                    label={"Documenti inviati"}
                    buttons={[
                        <>
                            {
                                companyState.downloadDocuments.length !== 0 &&
                                <ButtonComponent
                                    key={'accounting-area-documents-sent-button'}
                                    label={"Scarica"}
                                    onClick={() => {
                                        if (companyState.downloadDocuments.length === 1) {
                                            dispatch(findFileById(companyState.downloadDocuments.find(id => id.objectId)?.objectId!)).then((e) => {
                                                //@ts-ignore
                                                objectService.downloadBase64WithExtension(companyState.downloadDocuments.find(id => id.name)?.name, e.payload !== null ? e.payload : '')
                                                dispatch(findDocumentById(companyState.downloadDocuments.find(id => id.id)?.id!))
                                                    .then(() => {
                                                        dispatch(setFindDocumentStatus('idle'))
                                                        dispatch(findAllSentDocuments({ companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id : 0, period: companyState.documentSentFilters.period, year: companyState.documentSentFilters.year, page: companyState.documentSentFilters.page }))
                                                    })
                                            });
                                        } else {
                                            dispatch(findMultipleFileById(companyState.downloadDocuments.map(doc => doc.objectId))).then((e) => {
                                                //@ts-ignore
                                                downloadZip(e.payload !== '' ? e.payload : '', companyState.downloadDocuments.map(name => name.name), companyState.findCompanyResponse?.businessName + "-DocumentiContabili")
                                                dispatch(findDocumentsById(companyState.downloadDocuments.map(id => id.id)))
                                                    .then(() => {
                                                        dispatch(setFindMultipleFileById("idle"))
                                                        dispatch(findAllSentDocuments({ companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id : 0, period: companyState.documentSentFilters.period, year: companyState.documentSentFilters.year, page: companyState.documentSentFilters.page }))
                                                    })
                                            })
                                        }
                                    }}
                                    color={Colors.PRIMARY}
                                    variant={Variant.OUTLINE}
                                    size={Size.SM}
                                    iconStyle={IconStyle.OFF}
                                />
                            }
                            <ButtonComponent
                                key={'accounting-area-documents-sent-button'}
                                label={"Aggiungi file"}
                                onClick={() => dispatch(setOpenDocumentModal(true))}
                                color={Colors.PRIMARY}
                                variant={Variant.SOLID}
                                size={Size.SM}
                                iconStyle={IconStyle.OFF}
                            />
                        </>
                    ]}
                    size={HeadingSize.MD}
                />
                <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={companyState.documentSentFilters.period.toString()}
                            onChange={(e) => {
                                dispatch(setDocumentSentFilterPeriod(e.target.value))
                                if (companyState.findCompanyResponse)
                                    dispatch(findAllSentDocuments({
                                        companyId: companyState.findCompanyResponse.id,
                                        period: e.target.value as '' | number,
                                        year: companyState.documentSentFilters.year,
                                        page: companyState.documentSentFilters.page
                                    }))
                            }}
                            id={"sent-document-period"}
                            menuItems={
                                [
                                    <MenuItem key='document-sent-period-filter' value=''>Seleziona mese</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-0' value={0}>Gennaio</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-1' value={1}>Febbraio</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-2' value={2}>Marzo</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-3' value={3}>Aprile</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-4' value={4}>Maggio</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-5' value={5}>Giugno</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-6' value={6}>Luglio</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-7' value={7}>Agosto</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-8' value={8}>Settembre</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-9' value={9}>Ottobre</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-10' value={10}>Novembre</MenuItem>,
                                    <MenuItem key='document-sent-period-filter-11' value={11}>Dicembre</MenuItem>,
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            id={"sent-document-year"}
                            value={companyState.documentSentFilters.year.toString()}
                            onChange={(e) => {
                                dispatch(setDocumentSentFilterYear(e.target.value))
                                if (companyState.findCompanyResponse)
                                    dispatch(findAllSentDocuments({
                                        companyId: companyState.findCompanyResponse.id,
                                        period: companyState.documentSentFilters.period,
                                        year: Number(e.target.value),
                                        page: companyState.documentSentFilters.page
                                    }))
                            }}
                            menuItems={yearsMenuItems}
                        />
                    </div>
                    <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                        <div className="row p-0 m-0" style={{ gap: '8px' }}>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Pulisci"}
                                    onClick={() => {
                                        dispatch(setDocumentSentFilterPeriod(''))
                                        dispatch(setDocumentSentFilterYear(new Date().getFullYear()))
                                        if (companyState.findCompanyResponse)
                                            dispatch(findAllSentDocuments({ companyId: companyState.findCompanyResponse.id, period: '', year: new Date().getFullYear(), page: companyState.documentSentFilters.page }))
                                    }}
                                    color={Colors.NEUTRAL}
                                    variant={Variant.OUTLINE}
                                    size={Size.MD}
                                    iconStyle={IconStyle.OFF}
                                />
                            </div>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Applica"}
                                    onClick={() => {
                                        if (companyState.findCompanyResponse)
                                            dispatch(findAllSentDocuments({ companyId: companyState.findCompanyResponse.id, period: companyState.documentSentFilters.period, year: companyState.documentSentFilters.year, page: companyState.documentSentFilters.page }))
                                    }}
                                    color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                            </div>
                        </div>
                    </div>
                </div>
                <TableComponent>
                    <TableHeadComponent>
                        <TableCellComponent
                            cellType={"head"}
                            alignLabel={"right"}
                            size="context-menu"
                            content={
                                <CheckboxComponent
                                    size={CheckboxSize.SM}
                                    checked={companyState.downloadDocuments.length !== 0 && companyState.downloadDocuments.length === companyState.findAllSentDocumentsResponse?.total}
                                    indeterminate={companyState.downloadDocuments.length < (companyState.findAllSentDocumentsResponse?.total || 0) && companyState.downloadDocuments.length > 0}
                                    style={CheckboxStyle.SQUARE}
                                    onChange={(_, checked) => {
                                        if (checked) {
                                            const total = companyState.findAllSentDocumentsResponse?.data.map(doc => { return ({ id: doc.id, name: doc.fileName, objectId: doc.objectId }) })
                                            dispatch(setDownloadDocuments(total))
                                        } else {
                                            dispatch(setDownloadDocuments([]))
                                        }
                                    }}
                                />
                            }
                        />
                        <TableCellComponent label={"Nome del file"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Descrizione del file"} size="large" cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"tipologia"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={companyState.findCompanyResponse?.periodicity === PeriodicityEnum.Monthly ? "mese/anno" : "trimestre/anno"} cellType={"head"} alignLabel={"right"} />
                        <TableCellComponent label={"inviato"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"ultimo download"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
                    </TableHeadComponent>
                    <TableBody>
                        {sentRows}
                    </TableBody>
                </TableComponent>
            </div>
            <AccountingAreaDocumentsModal />
            {
                companyState.findAllSentDocumentsResponse !== undefined &&
                companyState.findAllSentDocumentsResponse.total > 0 &&
                <PaginationComponent
                    page={companyState.findAllSentDocumentsResponse !== undefined ? companyState.findAllSentDocumentsResponse.page : 0}
                    count={companyState.findAllSentDocumentsResponse !== undefined ? companyState.findAllSentDocumentsResponse.totalPage : 0}
                    onChange={(page) => dispatch(setDocumentSentFilterPage(page))}
                />
            }
            {
                companyState.findAllSentDocumentsResponse !== undefined &&
                companyState.findAllSentDocumentsResponse.total === 0 &&
                <div style={{ width: '100%', height: '50vh' }}>
                    <EmptyList />
                </div>
            }
        </div>
    )
}