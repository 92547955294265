//TODO traduzione
export enum MenuItems {
    DASHBOARD = 'DASHBOARD',
    COMPANY = 'COMPANY',
    FINANCIAL_ADVISOR = 'COMMERCIALISTI',
    ACCOUNTANT = 'ACCOUNTANT',
    ACTIVITY = 'ACTIVITY',
    SUPPORTO = 'SUPPORTO',
    COMPANY_TO_ADD = 'COMPANY_TO_ADD',
    OPERATOR = 'OPERATOR',
    REQUEST = 'REQUEST'
}