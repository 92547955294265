import { AccountingSchemeEnum, SpecialVatSchemeEnum } from "../companyQuotationWizard/dto";

export interface FindAllUpdateCompanySettings {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: UpdateCompanySettingsDTO[]
}

export interface FindAllUpdateCompanySettingsFilters {
    companyName: string
    itemsPerPage: number
    page: number
    status: UpdateApprovationEnum | string
    financialAdvisor: string
}

export interface UpdateCompanySettingsDTO {
    id: string
    userId: string
    companyId: string
    requestUserId: string
    price: number
    accountingScheme: string
    atecoCodesNumber: number
    annualForeignInvoices: number
    specialVatSchemes: boolean
    specialVatScheme: SpecialVatSchemeEnum
    banksNumber: number
    journal: number
    activityCategoryIds: number[]
    ivaStartMonth: string
    cogeStartMonth: string
    ivaStartYear : string
    cogeStartYear : string
    updateStatus: UpdateApprovationEnum
}

export interface UpdateCompanySettingsCreationDTO {
    companyId: string
    price: number
    accountingScheme: AccountingSchemeEnum | null
    atecoCodesNumber: number | null
    annualForeignInvoices: number | null
    specialVatSchemes: boolean | null
    specialVatScheme: SpecialVatSchemeEnum | null
    banksNumber: number | null
    journal: number | null
    activityCategoryIds: number[]
    ivaStartMonth: string
    cogeStartMonth: string
    ivaStartYear : string
    cogeStartYear : string
    updateStatus: UpdateApprovationEnum | null
}

export interface UpdateCompanySettingsConfirmDTO {
    companyId: string
    price?: number
    accountantId?: string
    updateStatus: UpdateApprovationEnum
}

export enum UpdateApprovationEnum {
    IDLE = "IDLE",
    RESOLVED = "RESOLVED",
    CANCELED = "CANCELED"
}