import { MenuItem, TableBody } from "@mui/material";
import { format } from "date-fns";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { CheckboxComponent } from "../../checkbox";
import { CheckboxSize, CheckboxStyle } from "../../checkbox/dto";
import { EmptyList } from "../../emptyList/emptyList";
import { ErrorPopup } from "../../errorPopup";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { DownloadIcon } from "../../icons/download";
import { SearchIcon } from "../../icons/search";
import { InfoTextPopup } from "../../infoTextPopup";
import { NewObjectService } from "../../objects/service";
import { findFileById, findMultipleFileById, setFindMultipleFileById } from "../../objects/slice";
import { PaginationComponent } from "../../pagination";
import { PillComponent } from "../../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../../pills/dto";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SelectComponent } from "../../select";
import { SpinnerComponent } from "../../spinner";
import { SuccessPopup } from "../../successPopup";
import { TableComponent } from "../../table";
import { TableCellComponent } from "../../table/tableCell";
import { TableHeadComponent } from "../../table/tableHead";
import { TableRowComponent } from "../../table/tableRow";
import { TextInput } from "../../textInput";
import { downloadZip } from "../../utils";
import colors from '../../utils/index.module.scss';
import { setFindDocumentStatus } from "../slice";
import { EmployeeMap, EmployeeTypology, FiscalDocumentStatus, FiscalDocumentType, MonthMap, StatusMap } from "./dtoDocument";
import { FiscalDocumentAreaModal } from "./fiscalDocumentAreaModal";
import { MenuFiscalDocument } from "./menuFiscalDocument";
import { findAllFiscalDocumentsEmployee, findByIdFiscalDocument, findByIdFiscalDocuments, setDeleteFiscalDocumentStatus, setDownloadFiscalDocuments, setFileNameEmployeeFilter, setFiscalDocumentEmployeeFilterPage, setMonthEmployeeFilter, setOpenFiscalDocumentAreaModal, setSaveFiscalDocumentStatus, setStatusEmployeeFilter, setTypologyEmployeeFilter, setYearEmployeeFilter } from "./sliceDocument";

export function EmployeeTab() {
    const dispatch = useAppDispatch()

    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)
    const companyState = useAppSelector(state => state.company)

    const objectService = NewObjectService()

    const startYear = 2022
    let yearsMenuItems: ReactNode[] = []

    for (let year = startYear; year <= new Date().getFullYear() + 1; year++) {
        yearsMenuItems.push(<MenuItem key={year} value={year}>{year}</MenuItem>)
    }

    useEffect(() => {
        if (companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsEmployee({ ...fiscalDocumentState.fiscalDocumentEmployeeFilters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [companyState.findCompanyResponse])

    useEffect(() => {
        if (fiscalDocumentState.findAllFiscalDocumentEmployeeStatus === 'successfully' && fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined) {
            if (fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.page >= fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.totalPage) {
                dispatch(setFiscalDocumentEmployeeFilterPage(0))
                dispatch(findAllFiscalDocumentsEmployee({ ...fiscalDocumentState.fiscalDocumentEmployeeFilters, page: 0 }))
            }
        }
    }, [fiscalDocumentState.findAllFiscalDocumentEmployeeStatus])

    useEffect(() => {
        if (fiscalDocumentState.createFiscalDocumentsStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsEmployee({ ...fiscalDocumentState.fiscalDocumentEmployeeFilters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [fiscalDocumentState.createFiscalDocumentsStatus, companyState.findCompanyResponse])

    useEffect(() => {
        if (fiscalDocumentState.deleteFiscalDocumentStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsEmployee({ ...fiscalDocumentState.fiscalDocumentEmployeeFilters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [fiscalDocumentState.deleteFiscalDocumentStatus, companyState.findCompanyResponse])

    if (
        fiscalDocumentState.findAllFiscalDocumentEmployeeStatus === 'loading' ||
        fiscalDocumentState.createFiscalDocumentsStatus === 'loading' ||
        fiscalDocumentState.deleteFiscalDocumentStatus === 'loading' ||
        companyState.findCompanyStatus === 'loading'
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    let fiscalDocumentEmployeeRows: ReactNode[] = []

    if (fiscalDocumentState.findAllFiscalDocumentEmployeeStatus === 'successfully' &&
        fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined &&
        fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.data !== undefined &&
        fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.data.length > 0 &&
        companyState.taxAreaTabValue === 2
    ) {
        fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.data.forEach(data => {
            let typology = EmployeeMap.get(data.typology as EmployeeTypology)
            let status = StatusMap.get(data.status)
            let month = MonthMap.get(data.referenceMonth)
            fiscalDocumentEmployeeRows.push(
                <TableRowComponent key={'fiscal-documents-employee-' + data.id}>
                    <TableCellComponent
                        content={
                            <CheckboxComponent
                                size={CheckboxSize.SM}
                                style={CheckboxStyle.SQUARE}
                                checked={fiscalDocumentState.downloadFiscalDocuments.find(doc => doc.objectId.toString() === data.objectId.toString()) !== undefined}
                                onChange={(_, checked) => {
                                    if (checked) {
                                        dispatch(setDownloadFiscalDocuments([...fiscalDocumentState.downloadFiscalDocuments, { id: data.id, name: data.fileName, objectId: data.objectId.toString() }]))
                                    } else {
                                        const remove = fiscalDocumentState.downloadFiscalDocuments.filter(_doc => _doc.objectId.toString() !== data.objectId.toString())
                                        dispatch(setDownloadFiscalDocuments(remove))
                                    }
                                }}
                            />
                        }
                        cellType={"row-regular"}
                        alignLabel={"right"}
                    />
                    <TableCellComponent label={data.fileName} cellType={"row-medium"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={typology !== undefined ? typology : ''} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={status !== undefined ? status : ''} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={month} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.referenceYear.toString()} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.date && format(new Date(data.date), 'dd/MM/yyyy')} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.lastDownloadDate !== null ? '' : '-'} cellType={"row-regular"} content={data.lastDownloadDate !== null ? <PillComponent label={format(new Date(data.lastDownloadDate), 'dd/MM/yyyy')} size={PillsSize.SM} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} /> : ''} alignLabel={"left"} />
                    <TableCellComponent
                        content={
                            <div className="d-flex justify-content-end align-items-center w-100">
                                <InfoTextPopup
                                    label={"Scarica"}
                                    position="bottom"
                                    children={
                                        <ButtonComponent
                                            icon={<DownloadIcon colorBase={colors.neutral600} />}
                                            onClick={() => {
                                                dispatch(findFileById(data.objectId.toString())).then((e) => {
                                                    //@ts-ignore
                                                    objectService.downloadBase64WithExtension(data.fileName, e.payload !== null ? e.payload : '')
                                                    dispatch(findByIdFiscalDocument(data.id))
                                                        .then(() => {
                                                            dispatch(setFindDocumentStatus('idle'))
                                                            dispatch(findAllFiscalDocumentsEmployee({ ...fiscalDocumentState.fiscalDocumentEmployeeFilters, companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '' }))
                                                        })
                                                });
                                            }}
                                            color={Colors.NEUTRAL}
                                            label=""
                                            variant={Variant.LINK}
                                            size={Size.SM}
                                            iconStyle={IconStyle.ONLY}
                                        />
                                    }
                                />
                                <MenuFiscalDocument fiscalDocument={data} />
                            </div>
                        }
                        cellType={"row-regular"}
                        alignLabel={"right"}
                    />
                </TableRowComponent>
            )
        })
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: 'flex-start',
            gap: '40px',
            width: '100%'
        }}>
            <ErrorPopup
                active={fiscalDocumentState.deleteFiscalDocumentStatus === 'failed'}
                close={() => dispatch(setDeleteFiscalDocumentStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione del documento"
            />
            <ErrorPopup
                active={fiscalDocumentState.createFiscalDocumentsStatus === 'failed'}
                close={() => dispatch(setSaveFiscalDocumentStatus('idle'))}
                message="Si è verificato un errore durante il salvataggio del documento"
            />
            <SuccessPopup
                active={fiscalDocumentState.deleteFiscalDocumentStatus === 'successfully'}
                close={() => dispatch(setDeleteFiscalDocumentStatus('idle'))}
                message="Documento eliminato correttamente"
            />
            <SuccessPopup
                active={fiscalDocumentState.createFiscalDocumentsStatus === 'successfully'}
                close={() => dispatch(setSaveFiscalDocumentStatus('idle'))}
                message="Documento salvato correttamente"
            />
            <div style={{ width: '100%', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                <HeadingComponent
                    label={"Gestione Dipendenti"}
                    buttons={[
                        <>
                            {
                                fiscalDocumentState.downloadFiscalDocuments.length !== 0 &&
                                <ButtonComponent
                                    key={'accounting-area-documents-sent-button'}
                                    label={"Scarica"}
                                    onClick={() => {
                                        if (fiscalDocumentState.downloadFiscalDocuments.length === 1) {
                                            dispatch(findFileById(fiscalDocumentState.downloadFiscalDocuments.find(id => id.objectId)?.objectId!)).then((e) => {
                                                //@ts-ignore
                                                objectService.downloadBase64WithExtension(fiscalDocumentState.downloadFiscalDocuments.find(id => id.name)?.name, e.payload !== null ? e.payload : '')
                                                dispatch(findByIdFiscalDocument(fiscalDocumentState.downloadFiscalDocuments.find(id => id.id)?.id!))
                                                    .then(() => {
                                                        dispatch(setFindDocumentStatus('idle'))
                                                        dispatch(findAllFiscalDocumentsEmployee({ ...fiscalDocumentState.fiscalDocumentEmployeeFilters, companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '' }))
                                                    })
                                            });
                                        } else {
                                            dispatch(findMultipleFileById(fiscalDocumentState.downloadFiscalDocuments.map(doc => doc.objectId))).then((e) => {
                                                //@ts-ignore
                                                downloadZip(e.payload !== '' ? e.payload : '', fiscalDocumentState.downloadFiscalDocuments.map(name => name.name), companyState.findCompanyResponse?.businessName + "-DocumentiFiscaliDipendenti")
                                                dispatch(findByIdFiscalDocuments(fiscalDocumentState.downloadFiscalDocuments.map(id => id.id)))
                                                    .then(() => {
                                                        dispatch(setFindMultipleFileById("idle"))
                                                        dispatch(findAllFiscalDocumentsEmployee({ ...fiscalDocumentState.fiscalDocumentEmployeeFilters, companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '' }))
                                                    })
                                            })
                                        }
                                    }}
                                    color={Colors.PRIMARY}
                                    variant={Variant.OUTLINE}
                                    size={Size.SM}
                                    iconStyle={IconStyle.OFF}
                                />
                            }
                            <ButtonComponent
                                key={'employee-button'}
                                label={"Aggiungi file"}
                                onClick={() => dispatch(setOpenFiscalDocumentAreaModal(true))}
                                color={Colors.PRIMARY}
                                variant={Variant.SOLID}
                                size={Size.SM}
                                iconStyle={IconStyle.OFF}
                            />
                        </>
                    ]}
                    size={HeadingSize.MD}
                />
                <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
                    <div className="col-12 col-lg p-0 m-0">
                        <TextInput value={fiscalDocumentState.fiscalDocumentEmployeeFilters.fileName} onChange={(e) => dispatch(setFileNameEmployeeFilter(e.target.value))} id="employee-name" type={"text"} placeholder={"Cerca nome del file"} startIcon={<SearchIcon colorBase={""} />} />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={fiscalDocumentState.fiscalDocumentEmployeeFilters.typology}
                            onChange={(e) => {
                                dispatch(setTypologyEmployeeFilter(e.target.value))
                                if (companyState.findCompanyResponse !== undefined) {
                                    dispatch(findAllFiscalDocumentsEmployee({
                                        typology: e.target.value as EmployeeTypology,
                                        fileName: fiscalDocumentState.fiscalDocumentEmployeeFilters.fileName,
                                        itemsPerPage: fiscalDocumentState.fiscalDocumentEmployeeFilters.itemsPerPage,
                                        page: fiscalDocumentState.fiscalDocumentEmployeeFilters.page,
                                        month: fiscalDocumentState.fiscalDocumentEmployeeFilters.month,
                                        year: fiscalDocumentState.fiscalDocumentEmployeeFilters.year,
                                        status: fiscalDocumentState.fiscalDocumentEmployeeFilters.status,
                                        type: fiscalDocumentState.fiscalDocumentEmployeeFilters.type,
                                        companyId: companyState.findCompanyResponse.id.toString()
                                    }))
                                }
                            }}
                            id={"employee-typology"}
                            menuItems={
                                [
                                    <MenuItem key='employee-typology-filter' value=''>Seleziona tipologia</MenuItem>,
                                    <MenuItem key={EmployeeTypology.paySlip} value={EmployeeTypology.paySlip}>Busta Paga</MenuItem>,
                                    <MenuItem key={EmployeeTypology.attendance} value={EmployeeTypology.attendance}>Presenze</MenuItem>,
                                    <MenuItem key={EmployeeTypology.cu} value={EmployeeTypology.cu}>CU</MenuItem>,
                                    <MenuItem key={EmployeeTypology.other} value={EmployeeTypology.other}>Altro</MenuItem>
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={fiscalDocumentState.fiscalDocumentEmployeeFilters.status}
                            onChange={(e) => {
                                dispatch(setStatusEmployeeFilter(e.target.value))
                                if (companyState.findCompanyResponse !== undefined) {
                                    dispatch(findAllFiscalDocumentsEmployee({
                                        typology: fiscalDocumentState.fiscalDocumentEmployeeFilters.typology,
                                        fileName: fiscalDocumentState.fiscalDocumentEmployeeFilters.fileName,
                                        itemsPerPage: fiscalDocumentState.fiscalDocumentEmployeeFilters.itemsPerPage,
                                        page: fiscalDocumentState.fiscalDocumentEmployeeFilters.page,
                                        month: fiscalDocumentState.fiscalDocumentEmployeeFilters.month,
                                        year: fiscalDocumentState.fiscalDocumentEmployeeFilters.year,
                                        status: e.target.value,
                                        type: fiscalDocumentState.fiscalDocumentEmployeeFilters.type,
                                        companyId: companyState.findCompanyResponse.id.toString()
                                    }))
                                }
                            }}
                            id={"financial-statements-status"}
                            menuItems={
                                [
                                    <MenuItem key='financial-statements-status-filter' value=''>Seleziona stato</MenuItem>,
                                    <MenuItem key={FiscalDocumentStatus.toRead} value={FiscalDocumentStatus.toRead}>Da leggere</MenuItem>,
                                    <MenuItem key={FiscalDocumentStatus.read} value={FiscalDocumentStatus.read}>Letto</MenuItem>,
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={fiscalDocumentState.fiscalDocumentEmployeeFilters.month?.toString()}
                            onChange={(e) => {
                                dispatch(setMonthEmployeeFilter(e.target.value))
                                if (companyState.findCompanyResponse !== undefined) {
                                    dispatch(findAllFiscalDocumentsEmployee({
                                        typology: fiscalDocumentState.fiscalDocumentEmployeeFilters.typology,
                                        fileName: fiscalDocumentState.fiscalDocumentEmployeeFilters.fileName,
                                        itemsPerPage: fiscalDocumentState.fiscalDocumentEmployeeFilters.itemsPerPage,
                                        page: fiscalDocumentState.fiscalDocumentEmployeeFilters.page,
                                        month: Number(e.target.value),
                                        year: fiscalDocumentState.fiscalDocumentEmployeeFilters.year,
                                        status: fiscalDocumentState.fiscalDocumentEmployeeFilters.status,
                                        type: fiscalDocumentState.fiscalDocumentEmployeeFilters.type,
                                        companyId: companyState.findCompanyResponse.id.toString()
                                    }))
                                }
                            }}
                            id={"employee-document-period"}
                            menuItems={
                                [
                                    <MenuItem key='employee-document-period-filter' value=''>Seleziona mese</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-0' value={0}>Gennaio</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-1' value={1}>Febbraio</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-2' value={2}>Marzo</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-3' value={3}>Aprile</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-4' value={4}>Maggio</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-5' value={5}>Giugno</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-6' value={6}>Luglio</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-7' value={7}>Agosto</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-8' value={8}>Settembre</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-9' value={9}>Ottobre</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-10' value={10}>Novembre</MenuItem>,
                                    <MenuItem key='employee-document-period-filter-11' value={11}>Dicembre</MenuItem>,
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            id={"emplyee-document-year"}
                            value={fiscalDocumentState.fiscalDocumentEmployeeFilters.year?.toString()}
                            onChange={(e) => {
                                dispatch(setYearEmployeeFilter(e.target.value))
                                if (companyState.findCompanyResponse !== undefined) {
                                    dispatch(findAllFiscalDocumentsEmployee({
                                        typology: fiscalDocumentState.fiscalDocumentEmployeeFilters.typology,
                                        fileName: fiscalDocumentState.fiscalDocumentEmployeeFilters.fileName,
                                        itemsPerPage: fiscalDocumentState.fiscalDocumentEmployeeFilters.itemsPerPage,
                                        page: fiscalDocumentState.fiscalDocumentEmployeeFilters.page,
                                        month: fiscalDocumentState.fiscalDocumentEmployeeFilters.month,
                                        year: Number(e.target.value),
                                        status: fiscalDocumentState.fiscalDocumentEmployeeFilters.status,
                                        type: fiscalDocumentState.fiscalDocumentEmployeeFilters.type,
                                        companyId: companyState.findCompanyResponse.id.toString()
                                    }))
                                }
                            }}
                            menuItems={yearsMenuItems}
                        />
                    </div>
                    <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                        <div className="row p-0 m-0" style={{ gap: '8px' }}>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Pulisci"}
                                    onClick={() => {
                                        dispatch(setFileNameEmployeeFilter(''))
                                        dispatch(setTypologyEmployeeFilter('' as EmployeeTypology))
                                        dispatch(setYearEmployeeFilter(new Date().getFullYear()))
                                        dispatch(setStatusEmployeeFilter(''))
                                        dispatch(setMonthEmployeeFilter(''))
                                        if (companyState.findCompanyResponse !== undefined) {
                                            dispatch(findAllFiscalDocumentsEmployee({
                                                typology: '' as EmployeeTypology,
                                                fileName: '',
                                                itemsPerPage: fiscalDocumentState.fiscalDocumentEmployeeFilters.itemsPerPage,
                                                page: fiscalDocumentState.fiscalDocumentEmployeeFilters.page,
                                                month: '',
                                                year: new Date().getFullYear(),
                                                status: '',
                                                type: fiscalDocumentState.fiscalDocumentEmployeeFilters.type,
                                                companyId: companyState.findCompanyResponse.id.toString()
                                            }))
                                        }
                                    }}
                                    color={Colors.NEUTRAL}
                                    variant={Variant.OUTLINE}
                                    size={Size.MD}
                                    iconStyle={IconStyle.OFF}
                                />
                            </div>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Applica"}
                                    onClick={() => {
                                        if (companyState.findCompanyResponse !== undefined)
                                            dispatch(findAllFiscalDocumentsEmployee({ ...fiscalDocumentState.fiscalDocumentEmployeeFilters, companyId: companyState.findCompanyResponse.id.toString() }))
                                    }}
                                    color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                            </div>
                        </div>
                    </div>
                </div>
                <TableComponent>
                    <TableHeadComponent>
                        <TableCellComponent
                            cellType={"head"}
                            alignLabel={"right"}
                            size="context-menu"
                            content={
                                <CheckboxComponent
                                    size={CheckboxSize.SM}
                                    checked={fiscalDocumentState.downloadFiscalDocuments.length !== 0 && fiscalDocumentState.downloadFiscalDocuments.length === fiscalDocumentState.findAllFiscalDocumentEmployeeResponse?.total}
                                    indeterminate={fiscalDocumentState.downloadFiscalDocuments.length < (fiscalDocumentState.findAllFiscalDocumentEmployeeResponse?.total || 0) && fiscalDocumentState.downloadFiscalDocuments.length > 0}
                                    style={CheckboxStyle.SQUARE}
                                    onChange={(_, checked) => {
                                        if (checked) {
                                            const total = fiscalDocumentState.findAllFiscalDocumentEmployeeResponse?.data.map(doc => { return ({ id: doc.id, name: doc.fileName, objectId: doc.objectId }) })
                                            dispatch(setDownloadFiscalDocuments(total))
                                        } else {
                                            dispatch(setDownloadFiscalDocuments([]))
                                        }
                                    }}
                                />
                            }
                        />
                        <TableCellComponent label={"Nome del file"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Tipologia"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Stato"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Mese"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Anno"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Data invio"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Ultimo download"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
                    </TableHeadComponent>
                    <TableBody>
                        {fiscalDocumentEmployeeRows}
                    </TableBody>
                </TableComponent>
            </div>
            <FiscalDocumentAreaModal type={FiscalDocumentType.employees} />
            {
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.total > 0 &&
                <PaginationComponent
                    page={fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.page : 0}
                    count={fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.totalPage : 0}
                    onChange={(page) => dispatch(setFiscalDocumentEmployeeFilterPage(page))}
                />
            }
            {
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.total === 0 &&
                <div style={{ width: '100%', height: '50vh' }}>
                    <EmptyList />
                </div>
            }
        </div>
    )
}