import { useEffect } from 'react';
import './App.css';
import { findMe } from './auth/slice';
import { getCompanyUnseenInfoDocuments, setActivityCompleteStatus, setActivityOpenStatus, setCompleteGroupStatus, setFinancialAdvisorFilter, setFindAllOperatorsFinancialAdvisorFilter } from './company/slice';
import { setCompaniesToAddFinancialAdvisorFilter } from './companyToConfirm/slice';
import { setFinancialAdvisorFilter as setDashboardFinancialAdvisor } from './dashboard/slice';
import { keycloak } from './keycloak';
import { setKeycloakInitStatus, setRoles, setSocket, urlMap } from './keycloak/slice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { KeycloakRouter } from './router';
import { SpinnerComponent } from './spinner';

function App() {
  const dispatch = useAppDispatch()

  const keycloakState = useAppSelector(state => state.secured)
  const authState = useAppSelector(state => state.auth)
  const companyState = useAppSelector(state => state.company)

  keycloak.onAuthSuccess = () => {
    if (keycloak.hasRealmRole('company')) {
      keycloak.logout({ redirectUri: window.location.origin })
    }
    dispatch(setSocket(new WebSocket(urlMap.get(window.location.origin) + "/chat", ["access_token", keycloak.token || ''])))
    dispatch(setRoles(keycloak.tokenParsed?.realm_access?.roles))
    dispatch(setKeycloakInitStatus('successfully'))
    dispatch(findMe())
    dispatch(getCompanyUnseenInfoDocuments())
  }

  keycloak.onAuthRefreshError = () => {
    keycloak.logout()
  }

  keycloak.onAuthRefreshSuccess = () => {
    keycloakState.socket?.close()
    dispatch(setSocket(new WebSocket(urlMap.get(window.location.origin) + "/chat", ["access_token", keycloak.token || ''])))
  }

  useEffect(() => {
    if (window.location.pathname === '/') {
      dispatch(setCompleteGroupStatus('idle'))
      dispatch(setActivityCompleteStatus('idle'))
      dispatch(setActivityOpenStatus('idle'))
    }
  }, [window.location.pathname])

  useEffect(() => {
    if (authState.findMeStatus === 'successfully' && authState.findMeResponse !== undefined) {
      if (keycloak.hasRealmRole('financial-advisor')) {
        dispatch(setFinancialAdvisorFilter(authState.findMeResponse.id))
        dispatch(setDashboardFinancialAdvisor(authState.findMeResponse.id))
        dispatch(setCompaniesToAddFinancialAdvisorFilter(authState.findMeResponse.id))
        dispatch(setFindAllOperatorsFinancialAdvisorFilter(authState.findMeResponse.id))
      }
      //TODO
      // if (keycloak.hasRealmRole('accountant')) {
      //   dispatch(setAccountantFilter(authState.findMeResponse.id))
      //   dispatch(setDashboardAccountantFilter(authState.findMeResponse.id))
      // }
    }
  }, [authState.findMeStatus])

  if (
    keycloakState.keycloakInitStatus === 'idle' ||
    authState.findMeStatus === 'loading' ||
    companyState.companiesUnseenDocumentsStatus === 'loading'
  ) {
    return (<SpinnerComponent fullScreen size={'small'} />)
  }

  return (
    <div className="App">
      <KeycloakRouter />
    </div>
  );
}

export default App;
