import { TableBody } from "@mui/material";
import { ReactElement, useEffect } from "react";
import { findAllCompanyWithoutPagination } from "../company/slice";
import { AccountingSchemeEnum } from "../companyQuotationWizard/dto";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import { AccountantMenuComponent } from "./menu";
import { findAllAccountant } from "./slice";

export function AccountantListComponent() {
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const accountantState = useAppSelector(state => state.accountant)
    const companyState = useAppSelector(state => state.company)
    let accountantsList: ReactElement[] = []

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllAccountant({...accountantState.findAllAccountantFilters, accountant: '', financialAdvisor: ''}))
        dispatch(findAllCompanyWithoutPagination())
        dispatch(findAllFinancialAdvisorWithoutPagination())
    }, [])

    useEffect(() => {
        if (accountantState.deleteAccountantStatus === 'successfully') {
            dispatch(findAllAccountant(accountantState.findAllAccountantFilters))
            dispatch(findAllCompanyWithoutPagination())
            dispatch(findAllFinancialAdvisorWithoutPagination())
        }
    }, [accountantState.deleteAccountantStatus])

    if (companyState.findAllCompaniesWithoutPaginationStatus === 'loading' || financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading') {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <SpinnerComponent size={"small"} />
            </div>
        )
    }

    if (companyState.findAllCompaniesWithoutPaginationStatus === 'failed' || financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed') {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    if (
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined &&
        companyState.findAllCompaniesWithoutPaginationStatus === 'successfully' &&
        companyState.findAllCompaniesWithoutPaginationResponse !== undefined &&
        companyState.findAllCompaniesWithoutPaginationResponse.data !== undefined &&
        accountantState.findAllAccountantStatus === 'successfully' &&
        accountantState.findAllAccountantResponse !== undefined &&
        accountantState.findAllAccountantResponse.data !== undefined &&
        accountantState.findAllAccountantResponse.data.length > 0
    ) {
        accountantState.findAllAccountantResponse.data.forEach(accountant => {
            const financialAdvisor = financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse?.data.filter(financialAdvisor => financialAdvisor.accountantId === accountant.id)
            const company = companyState.findAllCompaniesWithoutPaginationResponse?.data.filter(company => company.accountantId === accountant.id || company.contabileId === accountant.id)
            const ordinaryCompany = company?.filter(_company => _company.accountingScheme === AccountingSchemeEnum.Ordinary)
            const simplifiedCompany = company?.filter(_company => _company.accountingScheme === AccountingSchemeEnum.Simplified)
            let financialAdvisorsLabel: string = '-'
            if (financialAdvisor !== undefined) {
                financialAdvisorsLabel = financialAdvisor.length.toString()
            }
            accountantsList.push(
                <TableRowComponent key={'accountant-row-user-' + accountant.id}>
                    <TableCellComponent label={accountant.name + ' ' + accountant.surname} cellType={"row-medium"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={String(company?.length)} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"center"} />
                    <TableCellComponent content={<PillComponent label={String(simplifiedCompany?.length)} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"center"} />
                    <TableCellComponent content={<PillComponent label={String(ordinaryCompany?.length)} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"center"} />
                    <TableCellComponent content={<PillComponent label={financialAdvisorsLabel} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"center"} />
                    <TableCellComponent content={<AccountantMenuComponent id={accountant.id} />} cellType={"row-regular"} alignLabel={"right"} />
                </TableRowComponent>
            )
        })
    }

    return (
        <TableComponent>
            <TableHeadComponent>
                <TableCellComponent label={"Contabile"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent size='small' label={"aziende"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent size='small' label={"in semplificata"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent size='small' label={"in ordinaria"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent size='large' label={"Commercialisti"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
            </TableHeadComponent>
            <TableBody>
                {accountantsList}
            </TableBody>
        </TableComponent>
    )
}