import { format, isValid } from "date-fns"
import { useEffect } from "react"
import { ButtonComponent } from "../../button"
import { Colors, IconStyle, Size, Variant } from "../../button/dto"
import { FormGroup } from "../../formGroup"
import { ModalComponent } from "../../modal"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { TextInput } from "../../textInput"
import colors from '../../utils/index.module.scss'
import { createEvent, editEvent, findEvents, resetEditEventRequest, resetNewEventRequest, setEditEventDate, setEditEventName, setEditEventNote, setNewEventCompanyId, setNewEventDate, setNewEventName, setNewEventNote, setOpenNewEventsModal } from "./sliceEvents"
import { CloseIcon } from "../../icons/close"

export function NewEventModal() {
    const eventsState = useAppSelector(state => state.events)
    const companyState = useAppSelector(state => state.company)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (companyState.findCompanyResponse !== undefined) {
            dispatch(setNewEventCompanyId(companyState.findCompanyResponse.id))
        }
    }, [companyState.findCompanyResponse])

    useEffect(() => {
        if (eventsState.newEventState === 'successfully') {
            dispatch(findEvents(eventsState.findEventsFilters))
        }
    }, [eventsState.newEventState])

    return (
        <ModalComponent
            open={eventsState.openNewEventsModal}
            handleClose={() => {
                dispatch(setOpenNewEventsModal(false))
                dispatch(resetNewEventRequest())
                dispatch(resetEditEventRequest())
            }}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <span className="text text--lg typography--semibold typography--black">Aggiungi Evento</span>
                    <ButtonComponent label={""} onClick={() => {
                        dispatch(setOpenNewEventsModal(false))
                        dispatch(resetNewEventRequest())
                        dispatch(resetEditEventRequest())
                    }} color={Colors.NEUTRAL} variant={Variant.LINK} size={Size.SM} iconStyle={IconStyle.ONLY} icon={<CloseIcon colorBase={""} />} />
                </div>
                <div className="d-flex flex-column align-items-center justify-content-between" style={{ gap: '16px', width: '100%' }}>
                    <FormGroup
                        label={"Nome evento"}
                        required
                        inputs={[
                            <TextInput
                                defaultValue={eventsState.modalEventType === 'save' ? eventsState.newEventRequest.name : eventsState.editEventRequest.name}
                                key={"new-event-modal-input-name"}
                                onChange={e => {
                                    if (eventsState.modalEventType === 'save')
                                        dispatch(setNewEventName(e.target.value))
                                    else {
                                        dispatch(setEditEventName(e.target.value))
                                    }
                                }}
                                id={"new-event-modal-input-name"}
                                type={"text"}
                                placeholder={"es. Busta paga, Preferenze, CU, Altro"}
                            />]}
                        style={"column"}
                    />
                    <FormGroup
                        label={"Scadenza evento"}
                        required
                        inputs={[
                            <TextInput
                                key={"new-event-modal-input-expiration"}
                                defaultValue={eventsState.modalEventType === 'save' ? eventsState.newEventRequest.date : eventsState.editEventRequest.date}
                                onChange={(e) => {
                                    if (isValid(new Date(e.target.value)))
                                        if (eventsState.modalEventType === 'save')
                                            dispatch(setNewEventDate(format(new Date(e.target.value), 'yyyy-MM-dd')))
                                        else {
                                            dispatch(setEditEventDate(format(new Date(e.target.value), 'yyyy-MM-dd')))
                                        }
                                    else {
                                        if (eventsState.modalEventType === 'save')
                                            dispatch(setNewEventDate(format(new Date(), 'yyyy-MM-dd')))
                                        else {
                                            dispatch(setEditEventDate(format(new Date(), 'yyyy-MM-dd')))
                                        }
                                    }
                                }}
                                id={"new-event-modal-input-expiration"}
                                type={"date"}
                                placeholder="Seleziona data"
                            />]}
                        style={"column"}
                    />
                    <FormGroup
                        label={"Note"}
                        inputs={[<TextInput key={"new-event-modal-input-notes"}
                            onChange={e => {
                                if (eventsState.modalEventType === 'save')
                                    dispatch(setNewEventNote(e.target.value))
                                else
                                    dispatch(setEditEventNote(e.target.value))
                            }}
                            id={"new-event-modal-input-notes"}
                            defaultValue={eventsState.modalEventType === 'save' ? eventsState.newEventRequest.note : eventsState.editEventRequest.note}
                            type={"text"}
                            placeholder={"Aggiungi qui una descrizione"}
                            multiline
                        />]}
                        style={"column"} />
                </div>
                <div style={{ margin: 0, padding: 0, gap: '12px' }} className="row">
                    <div className="col" style={{ margin: 0, padding: 0 }}>
                        <ButtonComponent
                            label={"Annulla"}
                            onClick={() => {
                                dispatch(setOpenNewEventsModal(false))
                                dispatch(resetNewEventRequest())
                                dispatch(resetEditEventRequest())
                            }}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.FIT}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div className="col" style={{ margin: 0, padding: 0 }}>
                        <ButtonComponent
                            label={eventsState.modalEventType === 'save' ? "Aggiungi Evento" : 'Modifica Evento'}
                            onClick={() => {
                                if (eventsState.modalEventType === 'save') {
                                    dispatch(createEvent(eventsState.newEventRequest))
                                    dispatch(setOpenNewEventsModal(false))
                                    dispatch(resetNewEventRequest())
                                } else {
                                    dispatch(editEvent({ id: eventsState.editEventId, data: eventsState.editEventRequest }))
                                    dispatch(setOpenNewEventsModal(false))
                                    dispatch(resetEditEventRequest())
                                }
                            }}
                            color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.FIT} iconStyle={IconStyle.OFF}
                            disabled={
                                eventsState.modalEventType === 'save' && (
                                    eventsState.newEventRequest.name === undefined ||
                                    eventsState.newEventRequest.date === undefined
                                ) ||
                                eventsState.modalEventType === 'edit' && (
                                    eventsState.editEventRequest.name === undefined ||
                                    eventsState.editEventRequest.date === undefined
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </ModalComponent >
    )
}