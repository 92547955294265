import { useState } from "react";
import { ContextMenuComponent } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { CheckCircleIcon } from "../icons/checkCircle";
import { useAppDispatch } from "../redux/hooks";
import colors from '../utils/index.module.scss';
import { setCompanyToRequestModal, setCompanyToRequestSettedId } from "./slice";

interface Props {
    id: number
}

export function RequestToConfirmMenuComponent(props: Props) {
    const { id } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const dispatch = useAppDispatch()

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setCompanyToRequestSettedId(id))
                    dispatch(setCompanyToRequestModal(true))
                }}>
                <CheckCircleIcon colorBase={colors.neutral600} />
                Controlla richiesta
            </MenuItemComponent>
        </ContextMenuComponent>
    )
}