import { Tab, TableBody } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { findAllActivityCategory } from "../activity/slice";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { SpecialVatSchemeEnum } from "../companyQuotationWizard/dto";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { ErrorPopup } from "../errorPopup";
import { HeadingComponent } from "../heading";
import { HeadingSize } from "../heading/dto";
import { HorizontalTabPrimaryComponent } from "../horizontalTabPrimary";
import { TabPanelComponent } from "../horizontalTabPrimary/tabPanel";
import { HorizontalTabSecondaryComponent } from "../horizontalTabSecondary";
import { ArrowLeftIcon } from "../icons/arrowLeft";
import { keycloak } from "../keycloak";
import { LayoutComponent } from "../layout";
import { NewObjectService } from "../objects/service";
import { findAllOperators } from "../operator/slice";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { updateCompanySettings } from "../requestToConfirm/slice";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import { OnButtonClick } from "../utils";
import { ActivityManagementModal } from "./activityManagementModal";
import { CompanyProfileComponent } from "./companyProfile";
import { CriticityModal } from "./criticityModal";
import { CompanyStatusEnum } from "./dto";
import { Financing } from "./registers/financing";
import { PartnersAndDirectors } from "./registers/partnersAndDirectors";
import { UpdateRegistry } from "./registers/updateRegistry";
import { VehiclesAndCars } from "./registers/vehiclesAndCars";
import { RolesCompanyComponent } from "./roles";
import { companyEditPremium, companyRegistryEmail, companyUpdateRegistry, editCompanyActivityTypes, editRegistry, editTodos, findAtecosById, findCompanyActivityCategories, findCompanyAvatar, findCompanyById, getCompanyRegistryPdf, resetAtecosByIdResponse, resetAtecosByIdStatus, resetCompanyRegistryDTO, resetErrorRegistryEmail, resetErrorUpdateRegistryEmail, resetErrorUpdateRegistryVat, resetFundingsResponse, resetLeasingsResponse, resetLoansResponse, resetUpdateCompanyDTO, saveRoles, setCompanyEditPremium, setCompanyEditPremiumStatus, setCompanyUpdateRegistryStatus, setCriticityOperation, setEditBankStatus, setEditCompanyActivityTypesStatus, setEditRegistryStatus, setEditTodosStatus, setGetCompanyRegistryPdf, setOpenCriticityModal, setOpenDeleteTodoModal, setRegistryEmail, setSaveAtecosStatus, setSaveRolesStatus, setSettingsTabValue, setTaxAreaTabValue, setUpdateCompanyStatus, setUpdateRegistryTabValue, updateCompany } from "./slice";
import { setCompanyIdFilter } from "./taxArea/sliceEvents";
import { TodoMenu } from "./todoMenu";

export function SettingsView() {
    const companyState = useAppSelector(state => state.company)
    const activityState = useAppSelector(state => state.activity)
    const requestToConfirmState = useAppSelector(state => state.requestToConfirm)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    let companyProfileFormData = new FormData();
    let companyUpdateFormData = new FormData();

    const [companyProfileAvatar, setCompanyProfileAvatar] = useState<File | null>(null)
    const [selectedActivities, setSelectedActivities] = useState<number[]>([])

    function isEqual(array1: number[], array2: number[]): boolean {
        if (array1.length !== array2.length) {
            return true
        }

        let doVar = true

        array1.forEach((element, index) => {
            if (array2.includes(element)) {
                doVar = false
            } else {
                doVar = true
            }
        })

        return doVar
    }

    const actionMap = new Map<number, OnButtonClick>([
        [2,
            () => {
                if (companyState.findCompanyResponse !== undefined) {
                    dispatch(saveRoles({
                        companyId: companyState.findCompanyResponse.id.toString(),
                        accountant: keycloak.hasRealmRole('admin') ? companyState.accountantToAdd : undefined,
                        operatorsToAdd: keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator') ? companyState.operatorsToAddList : undefined,
                        contabili: keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('accountant') ? companyState.contabiliToAdd : undefined
                    }))
                }
            }
        ],
        [0,
            () => {
                if (companyState.findCompanyResponse !== undefined) {
                    let requestToConfirm = requestToConfirmState.updateCompanySettingsCreationRequest
                    let company = companyState.findCompanyResponse
                    if (companyState.findCompanyResponse.state === CompanyStatusEnum.Active) {
                        if (companyProfileAvatar !== null) {
                            companyUpdateFormData.append('file', companyProfileAvatar)
                        }
                        companyUpdateFormData.append('companyUpdateDTO', JSON.stringify(companyState.companyUpdateDTO))
                        dispatch(updateCompany({ id: companyState.findCompanyResponse.id.toString(), data: companyUpdateFormData }))
                        companyUpdateFormData.delete('companyUpdateDTO')
                        if (companyState.companyEditPremium.premium !== null)
                            dispatch(companyEditPremium({ data: companyState.companyEditPremium, id: companyState.findCompanyResponse.id }))
                        if (companyState.companyRegistryEmailRequest.email !== undefined && companyState.companyRegistryEmailRequest.email !== null && companyState.companyRegistryEmailRequest.email !== '') {
                            dispatch(companyRegistryEmail({ id: companyState.findCompanyResponse.id, data: companyState.companyRegistryEmailRequest }))
                        }
                        if (
                            (requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme !== null && requestToConfirmState.updateCompanySettingsCreationRequest.accountingScheme !== company.accountingScheme  )||
                            (requestToConfirmState.updateCompanySettingsCreationRequest.annualForeignInvoices !== null && requestToConfirmState.updateCompanySettingsCreationRequest.annualForeignInvoices !== company.annualForeignInvoices  ) ||
                            (requestToConfirmState.updateCompanySettingsCreationRequest.atecoCodesNumber !== null && requestToConfirmState.updateCompanySettingsCreationRequest.atecoCodesNumber !== company.atecoCodesNumber   )||
                            (requestToConfirmState.updateCompanySettingsCreationRequest.banksNumber !== null && requestToConfirm.accountingScheme === 'Ordinary' && requestToConfirmState.updateCompanySettingsCreationRequest.banksNumber !== company.banksNumber  ) ||
                            (requestToConfirmState.updateCompanySettingsCreationRequest.journal !== null && requestToConfirm.accountingScheme === 'Ordinary' && requestToConfirmState.updateCompanySettingsCreationRequest.journal !== company.journal  )||
                            (requestToConfirmState.updateCompanySettingsCreationRequest.specialVatScheme !== null && requestToConfirmState.updateCompanySettingsCreationRequest.specialVatScheme !== company.specialVatScheme   )||
                            (requestToConfirmState.updateCompanySettingsCreationRequest.specialVatSchemes !== null && requestToConfirmState.updateCompanySettingsCreationRequest.specialVatSchemes !== company.specialVatSchemes   )||
                            ( requestToConfirmState.updateCompanySettingsCreationRequest.ivaStartMonth && Number(requestToConfirm.ivaStartMonth )!== company.ivaStartMonth) ||
                            (requestToConfirmState.updateCompanySettingsCreationRequest.ivaStartYear &&  Number(requestToConfirm.ivaStartYear )!== company.ivaStartYear)||
                            (requestToConfirmState.updateCompanySettingsCreationRequest.cogeStartMonth &&  Number(requestToConfirm.cogeStartMonth)!== company.cogeStartMonth) ||
                            (requestToConfirmState.updateCompanySettingsCreationRequest.cogeStartYear &&  Number(requestToConfirm.cogeStartYear )!== company.cogeStartYear) ||
                            //isEqual(requestToConfirmState.updateCompanySettingsCreationRequest.activityCategoryIds, companyState.findCompanyResponse?.activityCategoryIds ?? [])
                            requestToConfirm.activityCategoryIds.length !== companyState.findCompanyResponse.activityCategoryIds?.length
                        ) {
                            dispatch(updateCompanySettings(requestToConfirm )) 
                        }
                    } else {
                        if (companyProfileAvatar !== null) {
                            companyProfileFormData.append('file', companyProfileAvatar)
                        }
                        companyProfileFormData.append('companyUpdateRegistryDTO', JSON.stringify(companyState.companyUpdateRegistryRequest))
                        dispatch(companyUpdateRegistry({ id: companyState.findCompanyResponse.id.toString(), data: companyProfileFormData }))
                    }
                }
            }
        ],
        [1,
            () => {
                let formData = new FormData();
                companyState.leasings
                    .filter(leasing => leasing !== null)
                    .forEach((leasing, index) => {
                        if (leasing !== null)
                            formData.append('leasing' + (index + 1), leasing)
                    })
                companyState.fundings
                    .filter(funding => funding !== null)
                    .forEach((funding, index) => {
                        if (funding !== null)
                            formData.append('funding' + (index + 1), funding)
                    })
                companyState.loans
                    .filter(loan => loan !== null)
                    .forEach((loan, index) => {
                        if (loan !== null)
                            formData.append('loan' + (index + 1), loan)
                    })
                formData.append('companyEditRegistryDTO', JSON.stringify(companyState.companyRegistryDTO))
                dispatch(editRegistry({ data: formData, id: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '' }))
                dispatch(resetLoansResponse())
                dispatch(resetLeasingsResponse())
                dispatch(resetFundingsResponse())
            }],
        [
            3,
            () => {
                dispatch(editCompanyActivityTypes({
                    companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '',
                    data: {
                        activityTypeIds: selectedActivities
                    }
                }))
            }
        ]
    ])

    useEffect(() => {
        dispatch(findCompanyById(''))
        dispatch(resetCompanyRegistryDTO())
        dispatch(setRegistryEmail(null))
        dispatch(setCompanyUpdateRegistryStatus('idle'))
        dispatch(setSaveRolesStatus('idle'))
        dispatch(setEditBankStatus('idle'))
        dispatch(setUpdateCompanyStatus('idle'))
        dispatch(setEditCompanyActivityTypesStatus('idle'))
        dispatch(setEditTodosStatus('idle'))
        dispatch(setGetCompanyRegistryPdf('idle'))
        dispatch(setSaveAtecosStatus('idle'))
        dispatch(setEditRegistryStatus('idle'))
        dispatch(resetAtecosByIdResponse())
        dispatch(resetAtecosByIdStatus())
        dispatch(setCompanyEditPremiumStatus('idle'))
        dispatch(findAllActivityCategory())

        if (window.location.pathname.includes('profile'))
            dispatch(setSettingsTabValue(0))
        if (window.location.pathname.includes('update-registry'))
            dispatch(setSettingsTabValue(1))
        if (window.location.pathname.includes('roles'))
            dispatch(setSettingsTabValue(2))
        if (window.location.pathname.includes('manage-activities'))
            dispatch(setSettingsTabValue(3))
    }, [])

    useEffect(() => {
        if (companyState.settingsTabValue === 0)
            navigate('/company/settings/' + window.location.pathname.split('/')[3] + '/profile')
        if (companyState.settingsTabValue === 1)
            navigate('/company/settings/' + window.location.pathname.split('/')[3] + '/update-registry')
        if (companyState.settingsTabValue === 2)
            navigate('/company/settings/' + window.location.pathname.split('/')[3] + '/roles')
        if (companyState.settingsTabValue === 3)
            navigate('/company/settings/' + window.location.pathname.split('/')[3] + '/manage-activities')
    }, [companyState.settingsTabValue])

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully') {
            resetErrorUpdateRegistryEmail()
            resetErrorUpdateRegistryVat()
            resetErrorRegistryEmail()
            dispatch(setCompanyIdFilter(companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id : 0))
            dispatch(setCompanyEditPremium(companyState.findCompanyResponse?.premium))

            if (companyState.findCompanyResponse !== undefined) {
                dispatch(findAtecosById(companyState.findCompanyResponse.atecoCodeIds))
                dispatch(findCompanyAvatar(companyState.findCompanyResponse?.avatarObjectId))
                dispatch(findCompanyActivityCategories(companyState.findCompanyResponse.activityCategoryIds ?? []))
            }
        }
    }, [companyState.findCompanyStatus])

    useEffect(() => {
        if (companyState.companyUpdateRegistryStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.companyUpdateRegistryStatus])

    useEffect(() => {
        if (companyState.saveRolesStatus === 'successfully') {
            if (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor'))
                dispatch(findAllOperators({ financialAdvisor: companyState.findAllOperatorsFinancialAdvisorFilter, operator: '', itemsPerPage: 0, page: 0 }))
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.saveRolesStatus])

    useEffect(() => {
        if (companyState.editBankStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.editBankStatus])

    useEffect(() => {
        if (companyState.companyUpdateStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.companyUpdateStatus])

    useEffect(() => {
        if (companyState.editCompanyActivityTypesStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.editCompanyActivityTypesStatus])

    useEffect(() => {
        if (companyState.editTodosStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.editTodosStatus])
    useEffect(() => {
        if (companyState.companyRegistryPdfStatus === 'successfully' && companyState.companyRegistryPdfResponse !== undefined && companyState.companyRegistryPdfResponse !== '' && companyState.findCompanyResponse?.businessName !== undefined && companyState.findCompanyResponse?.businessName !== null) {
            const objectService = NewObjectService()
            objectService.downloadBase64WithoutExtension(companyState.findCompanyResponse.businessName, companyState.companyRegistryPdfResponse, 'application/pdf')
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
            dispatch(setGetCompanyRegistryPdf('idle'))
        }
    }, [companyState.companyRegistryPdfStatus])

    useEffect(() => {
        if (companyState.saveAtecosStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.saveAtecosStatus])

    useEffect(() => {
        if (companyState.editRegistryStaus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.editRegistryStaus])

    useEffect(() => {
        if (companyState.companyEditPremiumStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.companyEditPremiumStatus])

    useEffect(() => {
        if (companyState.companyRegistryEmailStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
            dispatch(setRegistryEmail(undefined))
        }
    }, [companyState.companyRegistryEmailStatus])

    let criticityList: ReactNode[] = []

    if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined && companyState.settingsTabValue === 1) {
        const company = companyState.findCompanyResponse
        if (company.todos !== null)
            company.todos.forEach((todo, _index) => {
                const criticityCategory = activityState.findAllActivityCategoryResponse?.data.find(category => category.id.toString() === todo.split('::')[1].toString())?.name
                criticityList.push(
                    <TableRowComponent key={'criticity-table-row-' + _index}>
                        <TableCellComponent label={todo.split('::')[0]} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent content={<PillComponent label={criticityCategory !== undefined ? criticityCategory : ''} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"center"} />
                        {
                            (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor')) &&
                            <TableCellComponent content={
                                <div style={{ margin: '0 !important' }}>
                                    <TodoMenu index={_index} todo={todo} />
                                </div>
                            } cellType={"row-regular"} alignLabel={"right"} />
                        }
                    </TableRowComponent>
                )
            })
    }

    const handleChangeUpdateRegistry = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setUpdateRegistryTabValue(newValue))
    };

    const handleChangeTaxArea = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setTaxAreaTabValue(newValue))
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setSettingsTabValue(newValue))
    };

    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    return (
        <LayoutComponent
            headingSize={HeadingSize.LG}
            menuItem={MenuItems.COMPANY}
            showSpinner={
                companyState.findCompanyStatus === 'loading' ||
                companyState.saveRolesStatus === 'loading' ||
                companyState.editBankStatus === 'loading' ||
                companyState.editCompanyActivityTypesStatus === 'loading' ||
                companyState.editTodosStatus === 'loading' ||
                companyState.companyEditPremiumStatus === 'loading' ||
                companyState.companyRegistryEmailStatus === 'loading'
            }
            headingLabel={companyState.findCompanyResponse?.businessName !== null && companyState.findCompanyResponse?.businessName !== undefined ? companyState.findCompanyResponse?.businessName : ('Azienda ' + companyState.findCompanyResponse?.id)}
            headingButtons={[
                companyState.findCompanyResponse?.state === CompanyStatusEnum.Active ?
                    <ButtonComponent
                        key='heading-button-company-settings-cancel'
                        label={"Torna alle Attività"}
                        onClick={() => navigate('/company/operation/' + window.location.pathname.split('/')[3] + '/activity')}
                        color={Colors.NEUTRAL}
                        variant={Variant.OUTLINE}
                        size={Size.MD}
                        iconStyle={IconStyle.LEFT}
                        icon={<ArrowLeftIcon colorBase={""} />}
                    /> : <></>,
                (companyState.settingsTabValue === 0 || companyState.settingsTabValue === 1) && companyState.findCompanyResponse?.state === CompanyStatusEnum.Active ? (
                    <ButtonComponent
                        key='heading-button-company-settings-download-pdf'
                        label={"Scarica PDF"}
                        onClick={() => dispatch(getCompanyRegistryPdf(companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))}
                        color={Colors.NEUTRAL}
                        variant={Variant.OUTLINE}
                        size={Size.MD}
                        iconStyle={IconStyle.OFF}
                    />
                ) : <></>,
                (
                    (
                        companyState.settingsTabValue === 0 ||
                        companyState.settingsTabValue === 1 ||
                        companyState.settingsTabValue === 3
                    ) && (
                        keycloak.hasRealmRole('admin') ||
                        keycloak.hasRealmRole('financial-advisor') ||
                        keycloak.hasRealmRole('accountant') ||
                        keycloak.hasRealmRole('operator')
                    )) || (
                        companyState.settingsTabValue === 2
                    ) ? (
                    <ButtonComponent
                        key='heading-button-company-settings-save'
                        label={"Salva"}
                        disabled={
                            companyState.settingsTabValue === 0 && ((
                                companyState.findCompanyResponse?.state === CompanyStatusEnum.RegistryPending && (
                                    companyState.companyUpdateRegistryRequest.businessName === '' ||
                                    companyState.companyUpdateRegistryRequest.businessName === undefined ||
                                    companyState.companyUpdateRegistryRequest.vatNumber === '' ||
                                    companyState.companyUpdateRegistryRequest.vatNumber === undefined ||
                                    companyState.companyUpdateRegistryRequest.fiscalCode === '' ||
                                    companyState.companyUpdateRegistryRequest.fiscalCode === undefined ||
                                    companyState.companyUpdateRegistryRequest.fiscalCode.length > 16 ||
                                    companyState.companyUpdateRegistryRequest.fiscalCode.length < 11 ||
                                    (
                                        companyState.companyUpdateRegistryRequest.email !== undefined &&
                                        companyState.companyUpdateRegistryRequest.email !== '' &&
                                        !regexp.test(companyState.companyUpdateRegistryRequest.email)
                                    ) ||
                                    companyState.companyUpdateRegistryRequest.vatNumber === '' ||
                                    companyState.companyUpdateRegistryRequest.vatNumber === undefined ||
                                    companyState.companyUpdateRegistryRequest.vatNumber.length < 11 ||
                                    companyState.companyUpdateRegistryRequest.vatNumber.length > 16 || (
                                        companyState.companyUpdateRegistryRequest.phoneNumber !== '' &&
                                        companyState.companyUpdateRegistryRequest.phoneNumber !== null &&
                                        companyState.companyUpdateRegistryRequest.phoneNumber !== undefined && (
                                            companyState.companyUpdateRegistryRequest.phoneNumber.length < 9 ||
                                            companyState.companyUpdateRegistryRequest.phoneNumber.length > 10)
                                    )
                                )) ||
                                (
                                    companyState.findCompanyResponse?.state === CompanyStatusEnum.Active && (
                                        (
                                            companyState.companyUpdateDTO.vatNumber !== undefined && (
                                                companyState.companyUpdateDTO.vatNumber === '' ||
                                                companyState.companyUpdateDTO.vatNumber.length < 11 ||
                                                companyState.companyUpdateDTO.vatNumber.length > 16
                                            )
                                        ) || (
                                            companyState.companyUpdateDTO.fiscalCode !== undefined && (
                                                companyState.companyUpdateDTO.fiscalCode === '' ||
                                                companyState.companyUpdateDTO.fiscalCode.length > 16 ||
                                                companyState.companyUpdateDTO.fiscalCode.length < 11
                                            )
                                        ) || (
                                            companyState.companyUpdateDTO.phoneNumber !== undefined &&
                                            companyState.companyUpdateDTO.phoneNumber !== null &&
                                            companyState.companyUpdateDTO.phoneNumber !== '' && (
                                                companyState.companyUpdateDTO.phoneNumber.length < 9 ||
                                                companyState.companyUpdateDTO.phoneNumber.length > 10
                                            )
                                        ) ||
                                        (
                                            companyState.companyRegistryEmailRequest.email !== undefined &&
                                            companyState.companyRegistryEmailRequest.email !== null &&
                                            companyState.companyRegistryEmailRequest.email !== '' &&
                                            !regexp.test(companyState.companyRegistryEmailRequest.email)
                                        ) ||
                                        ((requestToConfirmState.updateCompanySettingsCreationRequest.specialVatSchemes! &&
                                            (requestToConfirmState.updateCompanySettingsCreationRequest.specialVatScheme === null || requestToConfirmState.updateCompanySettingsCreationRequest.specialVatScheme === '' as SpecialVatSchemeEnum)) ||
                                            requestToConfirmState.updateCompanySettingsCreationRequest.activityCategoryIds.length === 0
                                        )
                                    ))
                            )
                        }
                        onClick={actionMap.get(companyState.settingsTabValue)}
                        color={Colors.PRIMARY}
                        variant={Variant.SOLID}
                        size={Size.MD}
                        iconStyle={IconStyle.OFF}
                    />
                ) : <></>
            ]}
            breadcrumbItems={['Aziende', 'Impostazioni Azienda', (companyState.settingsTabValue === 0 ? 'Profilo' : companyState.settingsTabValue === 1 ? 'Dettagli Contabili' : 'Ruoli')]}
        >
            <ErrorPopup
                active={companyState.editRegistryStaus === 'failed'}
                close={() => dispatch(setEditRegistryStatus('idle'))}
                message="Si è verificato un errore durante la modifica dei dettagli contabili"
            />
            <ErrorPopup
                active={companyState.editTodosStatus === 'failed'}
                close={() => dispatch(setEditTodosStatus('idle'))}
                message="Si è verificato un errore durante la modifica della lista delle criticità"
            />
            <SuccessPopup
                active={companyState.editTodosStatus === 'successfully'}
                close={() => dispatch(setEditTodosStatus('idle'))}
                message="Lista criticità aggiornata"
            />
            <SuccessPopup
                active={companyState.editRegistryStaus === 'successfully'}
                close={() => dispatch(setEditRegistryStatus('idle'))}
                message="Dettagli aggiornati"
            />
            <ErrorPopup
                active={companyState.editCompanyActivityTypesStatus === 'failed'}
                close={() => dispatch(setEditCompanyActivityTypesStatus('idle'))}
                message="Si è verificato un errore durante la modifica delle attività"
            />
            <SuccessPopup
                active={companyState.editCompanyActivityTypesStatus === 'successfully'}
                close={() => dispatch(setEditCompanyActivityTypesStatus('idle'))}
                message="Attività aggiornate"
            />
            <HorizontalTabPrimaryComponent
                negativeMargin={36}
                value={companyState.settingsTabValue}
                handleChange={handleChange}
            >
                <Tab label="Profilo" value={0} />
                <Tab label="Dettagli contabili" value={1} disabled={companyState.findCompanyResponse?.state !== CompanyStatusEnum.Active} />
                {
                    !keycloak.hasRealmRole('operator') && (
                        <Tab label="Ruoli" value={2} disabled={companyState.findCompanyResponse?.state !== CompanyStatusEnum.Active} />
                    )
                }
                {
                    (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('accountant')) && (
                        <Tab label="Gestisci Attività" value={3} disabled={companyState.findCompanyResponse?.state !== CompanyStatusEnum.Active} />
                    )
                }

            </HorizontalTabPrimaryComponent>
            {
                companyState.settingsTabValue === 1 &&
                <HorizontalTabSecondaryComponent
                    negativeMargin={36}
                    value={companyState.updateRegistryTabValue}
                    handleChange={handleChangeUpdateRegistry}
                >
                    <Tab label="Registri" value={0} />
                    <Tab label="Soci e Amministratori" value={1} />
                    <Tab label="Finanziamenti" value={2} />
                    <Tab label="Automezzi e Autovetture" value={3} />
                    <Tab label="Criticità" value={4} />

                </HorizontalTabSecondaryComponent>
            }
            <TabPanelComponent value={companyState.settingsTabValue} index={0}>
                <div style={{ marginTop: '16px', width: '100%' }}>
                    <CompanyProfileComponent setFile={setCompanyProfileAvatar} />
                </div>
            </TabPanelComponent>
            <TabPanelComponent value={companyState.settingsTabValue} index={1}>
                <TabPanelComponent value={companyState.updateRegistryTabValue} index={0}>
                    <UpdateRegistry />
                </TabPanelComponent>
                <TabPanelComponent value={companyState.updateRegistryTabValue} index={1}>
                    <PartnersAndDirectors />
                </TabPanelComponent>
                <TabPanelComponent value={companyState.updateRegistryTabValue} index={2}>
                    <Financing />
                </TabPanelComponent>
                <TabPanelComponent value={companyState.updateRegistryTabValue} index={3}>
                    <VehiclesAndCars />
                </TabPanelComponent>
                <TabPanelComponent value={companyState.updateRegistryTabValue} index={4}>
                    <div className="col-12 m-0 p-0">
                        <div className="w-100 d-flex flex-column align-items-start mb-5" style={{ gap: '16px' }}>
                            <HeadingComponent size={HeadingSize.SM} label={"Criticità"} buttons={keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('accountant') ? [<ButtonComponent disabled={companyState.findCompanyResponse?.state !== CompanyStatusEnum.Active} label={"Aggiungi criticità"} onClick={() => { dispatch(setOpenCriticityModal(true)); dispatch(setCriticityOperation('save')) }} color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.SM} iconStyle={IconStyle.OFF} />] : []} />
                            <TableComponent>
                                <TableHeadComponent>
                                    <TableCellComponent label={"descrizione"} cellType={"head"} alignLabel={"left"} />
                                    <TableCellComponent label={"tipologia"} size="small" cellType={"head"} alignLabel={"center"} />
                                    {
                                        (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor')) &&
                                        <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
                                    }
                                </TableHeadComponent>
                                <TableBody>
                                    {criticityList}
                                </TableBody>
                            </TableComponent>
                        </div>
                    </div>
                </TabPanelComponent>
            </TabPanelComponent>
            {
                !keycloak.hasRealmRole('operator') && (
                    <TabPanelComponent value={companyState.settingsTabValue} index={2}>
                        <RolesCompanyComponent />
                    </TabPanelComponent>
                )
            }
            {
                (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('accountant')) && (
                    <TabPanelComponent value={companyState.settingsTabValue} index={3}>
                        <div style={{ width: '100%' }}>
                            <ActivityManagementModal selectedActivities={selectedActivities} setSelectedActivities={setSelectedActivities} />
                        </div>
                    </TabPanelComponent>
                )
            }
            <CriticityModal />
            <ConfirmOrDenyModal
                open={companyState.openDeleteTodoModal}
                handleClose={() => dispatch(setOpenDeleteTodoModal(false))}
                title={"Elimina criticità"}
                description={"Sei sicuro di voler eliminare la criticità?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    if (companyState.findCompanyResponse !== undefined) {
                        let todoList = companyState.findCompanyResponse.todos !== null ? [...companyState.findCompanyResponse.todos] : []
                        dispatch(editTodos({ companyId: companyState.findCompanyResponse.id.toString(), data: { todos: todoList.filter(todo => todoList.indexOf(todo) !== companyState.todoIndexToDelete) } }))
                        dispatch(setOpenDeleteTodoModal(false))
                    }
                }}
            />
        </LayoutComponent>
    )
}