import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReactElement } from "react";
import { NewObjectService } from "../../objects/service";
import { PromiseStatuses } from "../../utils";
import { ChatDTO, ChatFilters, GroupChatDTO, GroupChatFilters, MessageFilterDTO, MessageResponse, TopicUnseenInfoDTO, UserInfoDTO } from "./dto";
import { NewChatService } from "./service";

interface ChatsState {
    findChatsStatus: PromiseStatuses
    findChatResponse: ChatDTO[]
    findChatUserStatus: PromiseStatuses
    findChatUserResponse: UserInfoDTO[]
    findGroupChatUserStatus: PromiseStatuses
    findGroupChatUserResponse: UserInfoDTO[]
    findChatAvatarStatus: PromiseStatuses
    findChatAvatarResponse: { userId: string, objectId: string | null }[]
    findMessagesStatus: PromiseStatuses
    findMessagesResponse?: MessageResponse
    selectedChat: number
    avatarInfos?: { first: string, second: string, third: string, userId: string }
    usersListAfterSend: string[]
    groupUsersListAfterSend: string[]
    setChatSeenStatus: PromiseStatuses
    deleteChatStatus: PromiseStatuses
    findGroupChatStatus: PromiseStatuses
    findGroupChatResponse: GroupChatDTO[]
    findGroupMessagesStatus: PromiseStatuses
    findGroupMessagesResponse?: MessageResponse
    groupMessageFilter: MessageFilterDTO
    setGroupChatSeenStatus: PromiseStatuses
    unSeenMessagesStatus: PromiseStatuses
    unSeenMessagesResponse?: TopicUnseenInfoDTO[]
    groupList?: { chat: ChatDTO, name: string, icon: ReactElement, members: number }
    groupTrueOrFalse: boolean
}

const initialState: ChatsState = {
    findChatsStatus: 'idle',
    findChatResponse: [],
    findChatUserStatus: 'idle',
    findChatUserResponse: [],
    findChatAvatarStatus: 'idle',
    findChatAvatarResponse: [],
    findMessagesStatus: 'idle',
    selectedChat: 0,
    usersListAfterSend: [],
    setChatSeenStatus: 'idle',
    deleteChatStatus: 'idle',
    findGroupChatStatus: "idle",
    findGroupChatResponse: [],
    unSeenMessagesStatus: "idle",
    setGroupChatSeenStatus: "idle",
    findGroupMessagesStatus: "idle",
    groupMessageFilter: {
        page: 0,
        itemsPerPage: 0,
        order: false,
    },
    groupTrueOrFalse: false,
    groupUsersListAfterSend: [],
    findGroupChatUserStatus: "idle",
    findGroupChatUserResponse: []
}

export const findChats = createAsyncThunk(
    'chat/findChats',
    async (filters: ChatFilters): Promise<ChatDTO[]> => {
        const chatsService = NewChatService()

        return chatsService.findChats(filters)
    },
)

export const setChatSeen = createAsyncThunk(
    'chat/chatSeen',
    async (id: number): Promise<void> => {
        const chatsService = NewChatService()

        return chatsService.setChatSeen(id)
    },
)

export const findChatUsers = createAsyncThunk(
    'chat/findChatUsers',
    async (userIds: string[]): Promise<UserInfoDTO[]> => {
        let promises: Promise<UserInfoDTO>[] = []
        promises = userIds.map(async (user) => {
            if (user === '') {
                return (Promise.resolve({
                    name: '',
                    surname: '',
                    role: '',
                    mail: '',
                    avatarObjectId: null,
                    userId: 'utente non disponibile',
                    businessName: ''
                }))
            }
            const chatsService = NewChatService()

            return chatsService.findChatUser(user)
                .catch(err => {
                    return (Promise.resolve({
                        name: '',
                        surname: '',
                        role: '',
                        mail: '',
                        avatarObjectId: null,
                        userId: 'utente non disponibile',
                        businessName: ''
                    }))
                })
        })
        return Promise.all(promises)
    },
)

export const findGroupChatUsers = createAsyncThunk(
    'chat/findGroupChatUsers',
    async (userIds: string[]): Promise<UserInfoDTO[]> => {
        let promises: Promise<UserInfoDTO>[] = []
        promises = userIds.map(async (user) => {
            if (user === '') {
                return (Promise.resolve({
                    name: '',
                    surname: '',
                    role: '',
                    mail: '',
                    avatarObjectId: null,
                    userId: 'utente non disponibile',
                    businessName: ''
                }))
            }
            const chatsService = NewChatService()

            return chatsService.findChatUser(user)
                .catch(err => {
                    return (Promise.resolve({
                        name: '',
                        surname: '',
                        role: '',
                        mail: '',
                        avatarObjectId: null,
                        userId: 'utente non disponibile',
                        businessName: ''
                    }))
                })
        })
        return Promise.all(promises)
    },
)

export const findChatImages = createAsyncThunk(
    'chat/findChatImages',
    async (request: { userId: string, objectId: string | null }[]): Promise<{ userId: string, objectId: string | null }[]> => {
        let promises: Promise<{ userId: string, objectId: string | null }>[] = []
        promises = request.map(async (user) => {
            if (user.objectId !== null) {
                const objectService = NewObjectService()
                return objectService.findFileByIdWithUserId(user.objectId, user.userId)
            } else {
                return Promise.resolve({ userId: user.userId, objectId: '' })
            }
        })
        return Promise.all(promises)
    },
)

export const findMessages = createAsyncThunk(
    'chat/findMessages',
    async (request: { chatId: number, page: number, itemsPerPage: number }): Promise<MessageResponse> => {
        const chatsService = NewChatService()

        return chatsService.findMessages(request.chatId, request.page, request.itemsPerPage)
    },
)

export const deleteChat = createAsyncThunk(
    'chat/deleteChat',
    async (chatId: number): Promise<void> => {
        const chatsService = NewChatService()

        return chatsService.deleteChat(chatId)
    },
)

export const findGroupChats = createAsyncThunk(
    'chat/findGroupChats',
    async (filters: GroupChatFilters): Promise<GroupChatDTO[]> => {
        const chatsService = NewChatService()

        return chatsService.findGroupChats(filters)
    },
)

export const findGroupMessages = createAsyncThunk(
    'chat/findGroupMessages',
    async (request: { chatId: number, filter: MessageFilterDTO }): Promise<MessageResponse> => {
        const chatsService = NewChatService()

        return chatsService.findGroupMessages(request.chatId, request.filter)
    },
)

export const setGroupChatSeen = createAsyncThunk(
    'chat/groupChatSeen',
    async (id: number): Promise<void> => {
        const chatsService = NewChatService()

        return chatsService.setGroupChatSeen(id)
    },
)

export const unSeenMessages = createAsyncThunk(
    'chat/unSeen',
    async (topics: string[]): Promise<TopicUnseenInfoDTO[]> => {
        const chatsService = NewChatService()

        return chatsService.unSeenMessages(topics)
    },
)

const chatSlice = createSlice({
    name: 'chat/slice',
    initialState,
    reducers: {
        setSelectedChat: (state, action) => {
            state.selectedChat = action.payload
        },
        setAvatarInfos: (state, action) => {
            state.avatarInfos = action.payload
        },
        setUsersListAfterSend: (state, action) => {
            state.usersListAfterSend = action.payload
        },
        setChatSeenStatus: (state, action) => {
            state.setChatSeenStatus = action.payload
        },
        setGroupChatSeenStatus: (state, action) => {
            state.setGroupChatSeenStatus = action.payload
        },
        setGroupList: (state, action) => {
            state.groupList = action.payload
        },
        setGroupTrueOrFalse: (state, action) => {
            state.groupTrueOrFalse = action.payload
        },
        setGroupUsersListAfterSend: (state, action) => {
            state.groupUsersListAfterSend = action.payload
        },
        setFindChatUsersStatus: (state, action) => {
            state.findChatUserStatus = action.payload
        },
        setUnseenMessagesStatus: (state, action) => {
            state.unSeenMessagesStatus = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findChats.pending, (state) => {
                state.findChatsStatus = 'loading'
            })
            .addCase(findChats.fulfilled, (state, action) => {
                state.findChatsStatus = 'successfully'
                state.findChatResponse = action.payload
            })
            .addCase(findChats.rejected, (state) => {
                state.findChatsStatus = 'failed'
            })
            .addCase(findChatUsers.pending, (state) => {
                state.findChatUserStatus = 'loading'
            })
            .addCase(findChatUsers.fulfilled, (state, action) => {
                state.findChatUserStatus = 'successfully'
                state.findChatUserResponse = action.payload
            })
            .addCase(findChatUsers.rejected, (state) => {
                state.findChatUserStatus = 'failed'
            })
            .addCase(findChatImages.pending, (state) => {
                state.findChatAvatarStatus = 'loading'
            })
            .addCase(findChatImages.fulfilled, (state, action) => {
                state.findChatAvatarStatus = 'successfully'
                state.findChatAvatarResponse = action.payload
            })
            .addCase(findChatImages.rejected, (state) => {
                state.findChatAvatarStatus = 'failed'
            })
            .addCase(findMessages.pending, (state) => {
                state.findMessagesStatus = 'loading'
            })
            .addCase(findMessages.fulfilled, (state, action) => {
                state.findMessagesStatus = 'successfully'
                state.findMessagesResponse = action.payload
            })
            .addCase(findMessages.rejected, (state) => {
                state.findMessagesStatus = 'failed'
            })
            .addCase(setChatSeen.pending, (state) => {
                state.setChatSeenStatus = 'loading'
            })
            .addCase(setChatSeen.fulfilled, (state, action) => {
                state.setChatSeenStatus = 'successfully'
            })
            .addCase(setChatSeen.rejected, (state) => {
                state.setChatSeenStatus = 'failed'
            })
            .addCase(deleteChat.pending, (state) => {
                state.deleteChatStatus = 'loading'
            })
            .addCase(deleteChat.fulfilled, (state, action) => {
                state.deleteChatStatus = 'successfully'
            })
            .addCase(deleteChat.rejected, (state) => {
                state.deleteChatStatus = 'failed'
            })
            .addCase(findGroupChats.pending, (state) => {
                state.findGroupChatStatus = 'loading'
            })
            .addCase(findGroupChats.fulfilled, (state, action) => {
                state.findGroupChatStatus = 'successfully'
                state.findGroupChatResponse = action.payload
            })
            .addCase(findGroupChats.rejected, (state) => {
                state.findGroupChatStatus = 'failed'
            })
            .addCase(findGroupChatUsers.pending, (state) => {
                state.findGroupChatUserStatus = 'loading'
            })
            .addCase(findGroupChatUsers.fulfilled, (state, action) => {
                state.findGroupChatUserStatus = 'successfully'
                state.findGroupChatUserResponse = action.payload
            })
            .addCase(findGroupChatUsers.rejected, (state) => {
                state.findGroupChatUserStatus = 'failed'
            })
            .addCase(unSeenMessages.pending, (state) => {
                state.unSeenMessagesStatus = 'loading'
            })
            .addCase(unSeenMessages.fulfilled, (state, action) => {
                state.unSeenMessagesStatus = 'successfully'
                state.unSeenMessagesResponse = action.payload
            })
            .addCase(unSeenMessages.rejected, (state) => {
                state.unSeenMessagesStatus = 'failed'
            })
            .addCase(setGroupChatSeen.pending, (state) => {
                state.setGroupChatSeenStatus = 'loading'
            })
            .addCase(setGroupChatSeen.fulfilled, (state, action) => {
                state.setGroupChatSeenStatus = 'successfully'
            })
            .addCase(setGroupChatSeen.rejected, (state) => {
                state.setGroupChatSeenStatus = 'failed'
            })
            .addCase(findGroupMessages.pending, (state) => {
                state.findGroupMessagesStatus = 'loading'
            })
            .addCase(findGroupMessages.fulfilled, (state, action) => {
                state.findGroupMessagesStatus = 'successfully'
                state.findGroupMessagesResponse = action.payload
            })
            .addCase(findGroupMessages.rejected, (state) => {
                state.findGroupMessagesStatus = 'failed'
            })
    },
})

export const {
    setSelectedChat,
    setAvatarInfos,
    setUsersListAfterSend,
    setChatSeenStatus,
    setGroupList,
    setGroupTrueOrFalse,
    setGroupChatSeenStatus,
    setGroupUsersListAfterSend,
    setFindChatUsersStatus,
    setUnseenMessagesStatus
} = chatSlice.actions

export default chatSlice.reducer
