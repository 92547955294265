import { Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setFindAllAdminAccountantsAvatarStatus } from "../accountant/slice";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { findActivitiesDashboard } from "../dashboard/slice";
import { HeadingSize } from "../heading/dto";
import { HorizontalTabPrimaryComponent } from "../horizontalTabPrimary";
import { TabPanelComponent } from "../horizontalTabPrimary/tabPanel";
import { HorizontalTabSecondaryComponent } from "../horizontalTabSecondary";
import { ArchiveIcon } from "../icons/archive";
import { BalanceIcon } from "../icons/balance";
import { CalendarIcon } from "../icons/calendar";
import { EmployeeIcon } from "../icons/employee";
import { F24Icon } from "../icons/f24";
import { SettingsIcon } from "../icons/settings";
import { LayoutComponent } from "../layout";
import { setSaveDocumentStatus } from "../objects/slice";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import colors from "../utils/index.module.scss";
import { OrdinaryAccounting } from "./accounting/ordinaryAccounting";
import { BankAccountComponent } from "./bankAccount";
import { ChatViewV2 } from "./chatV2";
import { setUnseenMessagesStatus, setUsersListAfterSend, unSeenMessages } from "./chatV2/slice";
import { DocumentReceived } from "./documentReceived";
import { DocumentSent } from "./documentSent";
import { CompanyStatusEnum } from "./dto";
import { findAllCompanies, findCompanyById, getCompanyUnseenInfoDocuments, getCompanyUnseenInfoMessages, setActivityCompleteStatus, setActivityOpenStatus, setCompaniesUnseenMessagesStatus, setCompleteGroupStatus, setDeleteDocumentStatus, setDocumentTabValue, setFindDocumentStatus, setOperationsTabValue, setTaxAreaTabValue } from "./slice";
import { CalendarTab } from "./taxArea/calendar";
import { EmployeeTab } from "./taxArea/employee";
import { F24Tab } from "./taxArea/f24";
import { FinancialStatementsTab } from "./taxArea/financialStatements";
import { OthersTab } from "./taxArea/other";
import { setCompanyIdFilter } from "./taxArea/sliceEvents";

export function OperationsView() {
    const companyState = useAppSelector(state => state.company)
    const objectState = useAppSelector(state => state.object)
    const chatState = useAppSelector(state => state.chatV2)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [unseenDocuments, setUnseenDocuments] = useState<number>(0)
    const [totalUnseenMessages, setTotalUnseenMessages] = useState<number>(0)
    const [balanceColor, setBalanceColor] = useState<string>(colors.neutral400)
    const [f24Color, setF24Color] = useState<string>(colors.neutral400)
    const [employeeColor, setEmployeeColor] = useState<string>(colors.neutral400)
    const [calendarColor, setCalendarColor] = useState<string>(colors.neutral400)
    const [otherColor, setOtherColor] = useState<string>(colors.neutral400)

    useEffect(() => {
        dispatch(findCompanyById(''))
        dispatch(setActivityCompleteStatus('idle'))
        dispatch(setActivityOpenStatus('idle'))
        dispatch(setSaveDocumentStatus('idle'))
        dispatch(setFindDocumentStatus('idle'))
        dispatch(setDeleteDocumentStatus('idle'))
        dispatch(setCompleteGroupStatus('idle'))
        dispatch(setFindAllAdminAccountantsAvatarStatus('idle'))
        dispatch(findAllCompanies(companyState.companyFilters))
        if (window.location.pathname.includes('activity'))
            dispatch(setOperationsTabValue(0))
        if (window.location.pathname.includes('documents'))
            dispatch(setOperationsTabValue(1))
        if (window.location.pathname.includes('messages'))
            dispatch(setOperationsTabValue(2))
        if (window.location.pathname.includes('banks'))
            dispatch(setOperationsTabValue(3))
        if (window.location.pathname.includes('tax-area'))
            dispatch(setOperationsTabValue(4))
    }, [])

    useEffect(() => {
        if (companyState.findAllCompaniesStatus === "successfully" && companyState.findAllCompaniesResponse !== undefined && companyState.operationsTabValue === 0) {
            const companiesId = companyState.findAllCompaniesResponse?.data.map(company => ({ companyId: company.id, year: new Date().getFullYear() }))
            //dispatch(findActivitiesDashboard({ companyIds: [...companiesId?.map(company => company.companyId), Number(window.location.pathname.split('/')[3] || [])], year: companyState.ordinaryAccountingYearFilter }))
            dispatch(getCompanyUnseenInfoMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]]))
            dispatch(unSeenMessages([...companiesId?.map(company => company.companyId.toString()) || []]))
        }
    }, [companyState.findAllCompaniesStatus, companyState.operationsTabValue === 0])

    useEffect(() => {
        if (companyState.operationsTabValue === 0)
            navigate('/company/operation/' + window.location.pathname.split('/')[3] + '/activity')
        if (companyState.operationsTabValue === 1)
            navigate('/company/operation/' + window.location.pathname.split('/')[3] + '/documents')
        if (companyState.operationsTabValue === 2)
            navigate('/company/operation/' + window.location.pathname.split('/')[3] + '/messages')
        if (companyState.operationsTabValue === 3)
            navigate('/company/operation/' + window.location.pathname.split('/')[3] + '/banks')
        if (companyState.operationsTabValue === 4)
            navigate('/company/operation/' + window.location.pathname.split('/')[3] + '/tax-area')

    }, [companyState.operationsTabValue])

    useEffect(() => {
        const companiesId = companyState.findAllCompaniesResponse?.data.map(company => ({ companyId: company.id, year: new Date().getFullYear() }))
        if (companyState.operationsTabValue === 0) {
            dispatch(getCompanyUnseenInfoMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]] || []))
            dispatch(unSeenMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]] || []))
        }
        if (companyState.operationsTabValue === 1) {
            dispatch(getCompanyUnseenInfoMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]] || []))
            dispatch(unSeenMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]] || []))
        }
        if (companyState.operationsTabValue === 2) {
            dispatch(getCompanyUnseenInfoMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]] || []))
            dispatch(unSeenMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]] || []))
        }
        if (companyState.operationsTabValue === 3) {
            dispatch(getCompanyUnseenInfoMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]] || []))
            dispatch(unSeenMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]] || []))
        }
        if (companyState.operationsTabValue === 4) {
            dispatch(getCompanyUnseenInfoMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]] || []))
            dispatch(unSeenMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]] || []))
        }
    }, [companyState.operationsTabValue])

    useEffect(() => {
        if (companyState.companiesUnseenMessagesStatus === "successfully")
            dispatch(setCompaniesUnseenMessagesStatus("idle"))
    }, [companyState.companiesUnseenMessagesStatus])

    useEffect(() => {
        if (chatState.unSeenMessagesStatus === "successfully")
            dispatch(setUnseenMessagesStatus("idle"))
    }, [chatState.unSeenMessagesStatus])

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully') {
            dispatch(setUsersListAfterSend([]))
            dispatch(setCompanyIdFilter(companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id : 0))
            const companyUnseenDocuments = companyState.companiesUnseenDocumentsResponse.find(document => document.companyId === companyState.findCompanyResponse?.id)?.unseenRecived
            const companyUnseenMessages = companyState.companiesUnseenMessagesResponse.find(document => document.topic.toString() === companyState.findCompanyResponse?.id.toString())?.unseen
            const groupMessagesUnseen = chatState.unSeenMessagesResponse?.find(mess => mess.topic.toString() === companyState.findCompanyResponse?.id.toString())?.unseen
            setUnseenDocuments(companyUnseenDocuments !== undefined ? companyUnseenDocuments : 0)
            setTotalUnseenMessages(companyUnseenMessages! + groupMessagesUnseen!)
        }
    }, [companyState.findCompanyStatus])

    useEffect(() => {
        const companyUnseenMessages = companyState.companiesUnseenMessagesResponse.find(document => document.topic.toString() === companyState.findCompanyResponse?.id.toString())?.unseen
        const companyGroupUnseenMessages = chatState.unSeenMessagesResponse?.find(mess => mess.topic.toString() === companyState.findCompanyResponse?.id.toString())?.unseen

        setTotalUnseenMessages(companyUnseenMessages! + companyGroupUnseenMessages!)
    }, [companyState.companiesUnseenMessagesResponse, chatState.unSeenMessagesResponse])

    useEffect(() => {
        if (companyState.companyUpdateRegistryStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.companyUpdateRegistryStatus])

    useEffect(() => {
        if (companyState.setActivityCompleteStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.setActivityCompleteStatus])

    useEffect(() => {
        if (companyState.setActivityOpenStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.setActivityOpenStatus])

    useEffect(() => {
        if (objectState.saveDocumentStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [objectState.saveDocumentStatus])

    useEffect(() => {
        if (companyState.findDocumentByIdStatus === 'successfully') {
            dispatch(getCompanyUnseenInfoDocuments())
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.findDocumentByIdStatus])

    useEffect(() => {
        if (companyState.deleteDocumentStatus === 'successfully') {
            dispatch(getCompanyUnseenInfoDocuments())
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.deleteDocumentStatus])

    useEffect(() => {
        if (companyState.completeGroupStatus === 'successfully') {
            dispatch(findCompanyById(companyState.findCompanyResponse?.id !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))
        }
    }, [companyState.completeGroupStatus])

    const handleChangeTaxArea = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setTaxAreaTabValue(newValue))
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setOperationsTabValue(newValue))
    };

    const handleChangeDocuments = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setDocumentTabValue(newValue))
    };

    const tabMap = new Map<number, string>([
        [0, 'Attività'],
        [1, 'Documenti'],
        [2, 'Messaggi'],
        [3, 'Conto corrente'],
        [4, 'Area fiscale'],
    ])

    return (
        <LayoutComponent
            headingSize={HeadingSize.LG}
            menuItem={MenuItems.COMPANY}
            showSpinner={
                companyState.findCompanyStatus === 'loading' ||
                companyState.setActivityOpenStatus === 'loading' ||
                companyState.setActivityCompleteStatus === 'loading' ||
                objectState.saveDocumentStatus === 'loading' ||
                companyState.deleteDocumentStatus === 'loading' ||
                companyState.companiesUnseenDocumentsStatus === 'loading' ||
                companyState.companiesUnseenMessagesStatus === "loading" ||
                chatState.unSeenMessagesStatus === "loading"
            }
            headingLabel={companyState.findCompanyResponse?.businessName !== null && companyState.findCompanyResponse?.businessName !== undefined ? companyState.findCompanyResponse?.businessName : ('Azienda ' + companyState.findCompanyResponse?.id)}
            headingButtons={[
                <ButtonComponent
                    key='heading-button-company-operation-cancel'
                    label={"Impostazioni Azienda"}
                    onClick={() => navigate('/company/settings/' + window.location.pathname.split('/')[3] + '/profile')}
                    color={Colors.NEUTRAL}
                    variant={Variant.OUTLINE}
                    size={Size.MD}
                    iconStyle={IconStyle.LEFT}
                    icon={<SettingsIcon colorBase={""} />}
                />
            ]}
            breadcrumbItems={['Aziende', 'Attività Azienda', tabMap.get(companyState.operationsTabValue) || '']}
        >
            <HorizontalTabPrimaryComponent
                negativeMargin={36}
                value={companyState.operationsTabValue}
                handleChange={handleChange}
            >
                <Tab label="Attività" value={0} disabled={companyState.findCompanyResponse?.state !== CompanyStatusEnum.Active} />
                <Tab label={
                    <div className="d-flex flex-row align-items-center gap-2">
                        <span>Documenti</span>
                        {
                            unseenDocuments > 0 &&
                            <PillComponent label={unseenDocuments.toString()} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.TRUE} />
                        }
                    </div>
                } value={1} disabled={companyState.findCompanyResponse?.state !== CompanyStatusEnum.Active} />
                <Tab label={
                    <div className="d-flex flex-row align-items-center gap-2">
                        <span>Messaggi</span>
                        {
                            (totalUnseenMessages > 0) &&
                            <PillComponent label={totalUnseenMessages.toString()} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.TRUE} />
                        }
                    </div>
                } value={2} disabled={companyState.findCompanyResponse?.state !== CompanyStatusEnum.Active} />
                <Tab label="Conto Corrente" value={3} disabled={companyState.findCompanyResponse?.state !== CompanyStatusEnum.Active} />
                <Tab label="Area Fiscale" value={4} disabled={companyState.findCompanyResponse?.state !== CompanyStatusEnum.Active || !companyState.findCompanyResponse.premium} />
            </HorizontalTabPrimaryComponent>
            {
                companyState.operationsTabValue === 1 &&
                <HorizontalTabSecondaryComponent
                    negativeMargin={36}
                    value={companyState.documentTabValue}
                    handleChange={handleChangeDocuments}
                >
                    <Tab label="Ricevuti" value={0} />
                    <Tab label="Inviati" value={1} />
                </HorizontalTabSecondaryComponent>
            }
            {
                companyState.operationsTabValue === 4 &&
                <HorizontalTabSecondaryComponent
                    negativeMargin={36}
                    value={companyState.taxAreaTabValue}
                    handleChange={handleChangeTaxArea}
                >
                    <Tab
                        onMouseOver={() => setBalanceColor(colors.primary500)}
                        onMouseOut={() => setBalanceColor(colors.neutral400)}
                        icon={<BalanceIcon colorBase={companyState.taxAreaTabValue === 0 ? colors.primary500 : balanceColor} />}
                        iconPosition={"start"}
                        label="Bilanci e Dichiarativi"
                        value={0}
                    />
                    <Tab
                        onMouseOver={() => setF24Color(colors.primary500)}
                        onMouseOut={() => setF24Color(colors.neutral400)}
                        icon={<F24Icon colorBase={companyState.taxAreaTabValue === 1 ? colors.primary500 : f24Color} />}
                        iconPosition={"start"}
                        label="F24"
                        value={1}
                    />
                    <Tab
                        onMouseOver={() => setEmployeeColor(colors.primary500)}
                        onMouseOut={() => setEmployeeColor(colors.neutral400)}
                        icon={<EmployeeIcon colorBase={companyState.taxAreaTabValue === 2 ? colors.primary500 : employeeColor} />}
                        iconPosition={"start"}
                        label="Dipendenti"
                        value={2}
                    />
                    <Tab
                        onMouseOver={() => setCalendarColor(colors.primary500)}
                        onMouseOut={() => setCalendarColor(colors.neutral400)}
                        icon={<CalendarIcon colorBase={companyState.taxAreaTabValue === 3 ? colors.primary500 : calendarColor} />}
                        iconPosition={"start"}
                        label="Calendario"
                        value={3}
                    />
                    <Tab
                        onMouseOver={() => setOtherColor(colors.primary500)}
                        onMouseOut={() => setOtherColor(colors.neutral400)}
                        icon={<ArchiveIcon colorBase={companyState.taxAreaTabValue === 4 ? colors.primary500 : otherColor} />}
                        iconPosition={"start"}
                        label="Altro"
                        value={4}
                    />
                </HorizontalTabSecondaryComponent>
            }
            <TabPanelComponent value={companyState.operationsTabValue} index={0}>
                <div style={{ marginTop: '16px', width: '100%' }}>
                    <OrdinaryAccounting />
                </div>
            </TabPanelComponent>
            <TabPanelComponent value={companyState.operationsTabValue} index={1}>
                <TabPanelComponent value={companyState.documentTabValue} index={0}>
                    <DocumentReceived />
                </TabPanelComponent>
                <TabPanelComponent value={companyState.documentTabValue} index={1}>
                    <DocumentSent />
                </TabPanelComponent>
            </TabPanelComponent>
            <TabPanelComponent value={companyState.operationsTabValue} index={2}>
                <div style={{ marginTop: '16px', flexGrow: 1, display: 'flex', width: '100%' }}>
                    <ChatViewV2 />
                    {/* <ChatsListComponent /> */}
                </div>
            </TabPanelComponent>
            <TabPanelComponent value={companyState.operationsTabValue} index={3}>
                <div style={{ marginTop: '16px', width: '100%' }}>
                    <BankAccountComponent />
                </div>
            </TabPanelComponent>
            <TabPanelComponent value={companyState.operationsTabValue} index={4}>
                <TabPanelComponent value={companyState.taxAreaTabValue} index={0}>
                    <FinancialStatementsTab />
                </TabPanelComponent>
                <TabPanelComponent value={companyState.taxAreaTabValue} index={1}>
                    <F24Tab />
                </TabPanelComponent>
                <TabPanelComponent value={companyState.taxAreaTabValue} index={2}>
                    <EmployeeTab />
                </TabPanelComponent>
                <TabPanelComponent value={companyState.taxAreaTabValue} index={3}>
                    <CalendarTab />
                </TabPanelComponent>
                <TabPanelComponent value={companyState.taxAreaTabValue} index={4}>
                    <OthersTab />
                </TabPanelComponent>
            </TabPanelComponent>
        </LayoutComponent>
    )
}