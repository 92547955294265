import axios from "axios";
import { keycloak } from "../../keycloak";
import { ChatDTO, ChatFilters, GroupChatDTO, GroupChatFilters, MessageFilterDTO, MessageResponse, TopicUnseenInfoDTO, UserInfoDTO } from "./dto";
import { ChatService } from "./service";

export class ChatServiceImpl implements ChatService {
    findChats(filters: ChatFilters): Promise<ChatDTO[]> {
        let url: string = "/api/chats?topic=" + filters.topic
            + (filters.firstUser !== '' ? '&firstUser=' + filters.firstUser : '')
            + (filters.secondUser !== '' ? '&secondUser=' + filters.secondUser : '')
            + (keycloak.hasRealmRole('accountant') ? '&observerUser=true' : '&observerUser=false')
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    findChatUser(userId: string): Promise<UserInfoDTO> {
        let url: string = "/api/users/" + userId
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    findMessages(chatId: number, page: number, itemsPerPage: number): Promise<MessageResponse> {
        let url: string = "/api/chats/" + chatId + '/messages?itemsPerPage=' + itemsPerPage + '&page=' + page
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    setChatSeen(chatId: number): Promise<void> {
        let url: string = "/api/chats/" + chatId + '/seen'
        return axios({
            url: url,
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    deleteChat(chatId: number): Promise<void> {
        let url: string = "/api/chats/" + chatId
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    findGroupChats(filters: GroupChatFilters): Promise<GroupChatDTO[]> {
        let url: string = "/api/chats/group?topic=" + filters.topic
            + (filters.user.length !== 0 ? '&user=' + filters.user : '')
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    findGroupMessages(chatId: number, filter: MessageFilterDTO): Promise<MessageResponse> {
        let url: string = "/api/chats/group/" + chatId + '/messages?itemsPerPage=' + filter.itemsPerPage + '&page=' + filter.page
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    setGroupChatSeen(chatId: number): Promise<void> {
        let url: string = "/api/chats/group/" + chatId + '/seen'
        return axios({
            url: url,
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    unSeenMessages(topics: string[]): Promise<TopicUnseenInfoDTO[]> {
        let url: string = "/api/chats/group/unseenMessages?"

        const queryParams = topics.map(topic => `topics=${encodeURIComponent(topic)}`).join('&');

        return axios({
            url: url + queryParams,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

} 