import { useEffect, useState } from "react"
import { IconsProps } from "./dto"

export function XIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width={props.size} height={props.size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 3L3 9M3 3L9 9" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}