import { Tab } from "@mui/material"
import { ReactNode, useEffect, useState } from "react"
import { ButtonComponent } from "../../button"
import { Colors, IconStyle, Size, Variant } from "../../button/dto"
import { ActivityKind } from "../../dashboard/dto"
import { TabPanelComponent } from "../../horizontalTabPrimary/tabPanel"
import { HorizontalTabSecondaryComponent } from "../../horizontalTabSecondary"
import { keycloak } from "../../keycloak"
import { ProgressBarComponent } from "../../progressBar"
import { ProgressBarColor } from "../../progressBar/dto"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { TextInput } from "../../textInput"
import colors from '../../utils/index.module.scss'
import { OrdinaryAccountingRow } from "../accounting/ordinaryAccountingRow"
import { ActivityGroupStatus, ActivityStatus } from "../dto"
import { completeGroup, setActivityCompleted, setActivityOpen, setOpenOrdinaryAccountingModal, setOrdinaryAccountingActivityCategories, setOrdinaryAccountingActivityGroups, setOrdinaryAccountingCompany, setOrdinaryAccountingInitialTab, setOrdinaryAccountingPeriod, setOrdinaryAccountingYear } from "../slice"
import { setControlsInitialTab, setOrdinaryControlsActivityGroups } from "./slice"

export function ControlsPanel() {
    const companyState = useAppSelector(state => state.company)
    const dashboardState = useAppSelector(state => state.dashboard)
    const controlState = useAppSelector(state => state.controls)

    const company = companyState.ordinaryAccountingUser
    const activityGroups = controlState.ordinaryControlsActivityGroups.filter(group => group.activityKind === ActivityKind.Controls)
    const initialTab = controlState.controlsInitialTab
    const categories = controlState.ordinaryControlsActivityCategories

    const dispatch = useAppDispatch()

    const [count, setCount] = useState<number>(0)
    const [selectedActivities, setSelectedActivities] = useState<number[]>([])
    const [activitiesToOpen, setActivitiesToOpen] = useState<{ id: number, note: string | null }[]>([])
    const [activitiesToComplete, setActivitiesToComplete] = useState<{ activityGroup: number, activityId: number, note: string | null }[]>([])

    const handleChangeActivity = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setControlsInitialTab(newValue));
    };

    useEffect(() => {
        if (dashboardState.findActivitiesDashboardStatus === 'successfully' && companyState.initialTabAccountingActivities === 2 && companyState.openOrdinaryAccountingModal && dashboardState.findActivitiesDashboardResponse.length > 0) {
            const activities = dashboardState.findActivitiesDashboardResponse.find(company => company.id === companyState.ordinaryAccountingUser?.id)?.activityGroups.filter(group => group.period === companyState.ordinaryAccountingPeriod && group.activityKind === ActivityKind.Controls)
            dispatch(setOrdinaryControlsActivityGroups(activities))
        }
    }, [dashboardState.findActivitiesDashboardStatus, dashboardState.findActivitiesDashboardResponse, companyState.openOrdinaryAccountingModal, dispatch])

    useEffect(() => {
        if (
            companyState.setActivityOpenStatus === 'successfully' ||
            companyState.setActivityCompleteStatus === 'successfully'
        ) {
            setActivitiesToComplete([])
            setActivitiesToOpen([])
        }
    }, [companyState.setActivityOpenStatus, companyState.setActivityCompleteStatus, companyState.completeGroupStatus])

    useEffect(() => {
        if (companyState.openOrdinaryAccountingModal) {
            let selectedIndexes: number[] = []
            activityGroups.flatMap(group => group.activities).forEach(
                activity => {
                    if (activity.state === ActivityStatus.Completed) {
                        selectedIndexes.push(activity.id)
                    }
                }
            )
            setSelectedActivities([...selectedIndexes])
        } else {
            dispatch(setOrdinaryAccountingCompany(undefined))
            dispatch(setOrdinaryAccountingActivityCategories([]))
            dispatch(setOrdinaryAccountingActivityGroups([]))
            dispatch(setOrdinaryControlsActivityGroups([]))
            dispatch(setOrdinaryAccountingPeriod(0))
            dispatch(setOrdinaryAccountingYear(new Date().getFullYear()))
            dispatch(setOrdinaryAccountingInitialTab(0));
        }
    }, [companyState.setActivityCompleteStatus])

    if (company === undefined) {
        return (
            <></>
        )
    }

    let groupTab: ReactNode[] = []
    let groupPanel: ReactNode[] = []

    if (
        companyState.openOrdinaryAccountingModal &&
        company !== undefined
    ) {
        categories.forEach(category => {
            const group = activityGroups.find(_group => _group.activityCategory.id === category.id)
            let selectedForPanel = 0
            group?.activities.forEach(activity => {
                selectedActivities.forEach(selected => {
                    if (selected === activity.id)
                        selectedForPanel++
                })
            })

            groupTab.push(<Tab label={category.name} value={category.id} key={category.id + '-tab'} />)
            groupPanel.push(
                <TabPanelComponent value={initialTab} index={category.id} key={category.id + '-panel'}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: '24px 0px',
                            gap: '4px',
                            width: '100%',
                            background: colors.white,
                            maxHeight: '45vh',
                            overflow: 'auto'
                        }}
                    >
                        {
                            group === undefined || group.activities.length === 0 ? (
                                <div className="text text--md typography--regular typography-neutral-500">
                                    {"L'azienda non ha ancora nessuna attività registrata."}
                                </div>
                            ) :
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <div style={{
                                            padding: '8px 20px', width: '100%'
                                        }}>
                                            <ProgressBarComponent value={(selectedForPanel / group.activities.length) * 100} color={ProgressBarColor.PRIMARY} />
                                        </div>
                                    </div>
                                    {
                                        group.activities.map((activity) => (
                                            <div key={activity.id} style={{ display: 'flex', alignItems: 'center', width: '100%', gap: "5px" }}>
                                                <div className="col">
                                                    <OrdinaryAccountingRow
                                                        hasDocument={activity.hasDocument}
                                                        key={activity.id}
                                                        disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('accountant')}
                                                        checked={selectedActivities.includes(activity.id)}
                                                        label={activity.activityType.name}
                                                        count={count}
                                                        setCount={(e) => setCount(e)}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (event.target.checked) {
                                                                if (activity.state === ActivityStatus.Pending) {
                                                                    setActivitiesToComplete([...activitiesToComplete, { activityGroup: group.id, activityId: activity.id, note: activitiesToComplete.find(_activity => _activity.activityId === activity.id)?.note || '' }])
                                                                } else {
                                                                    setActivitiesToOpen([...activitiesToOpen.filter(_activity => _activity.toString() !== activity.id.toString())])
                                                                }
                                                                setSelectedActivities([...selectedActivities, activity.id])
                                                            }
                                                            else {
                                                                if (activity.state === ActivityStatus.Pending) {
                                                                    setActivitiesToComplete([...activitiesToComplete.filter(_activity => _activity.activityId.toString() !== activity.id.toString())])
                                                                } else {
                                                                    setActivitiesToOpen([...activitiesToOpen, { id: activity.id, note: activitiesToComplete.find(_activity => _activity.activityId === activity.id)?.note || '' }])
                                                                }
                                                                setSelectedActivities([...selectedActivities.filter(_activity => _activity.toString() !== activity?.id.toString())])
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <TextInput
                                                        id={"create-accountant-note"}
                                                        type={"text"}
                                                        defaultValue={activity.notes ?? ""}
                                                        placeholder={"Note (max 255 caratteri)"}
                                                        onChange={(e) => {
                                                            let index = activitiesToComplete.findIndex(_activity => _activity.activityId === activity.id)
                                                            const newActivities = [...activitiesToComplete]
                                                            newActivities[index].note = e.target.value
                                                            setActivitiesToComplete([...newActivities])
                                                        }}
                                                        disabled={activitiesToComplete.findIndex(_activity => _activity.activityId === activity.id) === -1}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                        }
                    </div>
                </TabPanelComponent>
            )
        })
    }

    return (
        <div>
            <HorizontalTabSecondaryComponent
                value={initialTab}
                handleChange={handleChangeActivity}
                negativeMargin={20}
            >
                {groupTab}
            </HorizontalTabSecondaryComponent>
            {groupPanel}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '12px',
                    width: '100%',
                    height: '80px',
                    borderTop: '1px solid ' + colors.neutral80
                }}
            >
                {
                    (
                        keycloak.hasRealmRole('admin') ||
                        keycloak.hasRealmRole('accountant')
                    ) &&
                    <div style={{ margin: 0, padding: 0, gap: '12px', width: '100%' }} className="row">
                        <div className="col" style={{ margin: 0, padding: 0 }}>
                            <ButtonComponent
                                key={'modal-button-1'}
                                label={"Annulla"}
                                onClick={() => dispatch(setOpenOrdinaryAccountingModal(false))}
                                color={Colors.NEUTRAL}
                                variant={Variant.OUTLINE}
                                size={Size.FIT}
                                iconStyle={IconStyle.OFF}
                            />
                        </div>
                        <div className="col" style={{ margin: 0, padding: 0 }}>
                            <ButtonComponent
                                disabled={
                                    (activityGroups.find(group => group.activityCategory.id === initialTab)?.state === ActivityGroupStatus.Completed && activitiesToOpen.length === 0) ||
                                    (activityGroups.find(group => group.activityCategory.id === initialTab)?.activities.length === 0)
                                }
                                key={'modal-button-2'}
                                label={(activityGroups.find(group => group.activityCategory.id === initialTab)?.activities.every(activity => activity.state === ActivityStatus.Completed) && activitiesToComplete.length === 0 && activitiesToOpen.length === 0) ? ("Chiudi " + activityGroups.find(group => group.activityCategory.id === initialTab)?.activityCategory.name) : "Aggiorna"}
                                onClick={() => {
                                    if ((activityGroups.find(group => group.activityCategory.id === initialTab)?.activities.every(activity => activity.state === ActivityStatus.Completed) && activitiesToComplete.length === 0 && activitiesToOpen.length === 0)) {
                                        if (activitiesToComplete.filter(activity => activity.activityGroup === activityGroups.find(group => group.activityCategory.id === initialTab)?.id).length > 0) {
                                            dispatch(setOpenOrdinaryAccountingModal(true))
                                            dispatch(setActivityCompleted({ ids: activitiesToComplete.map(activity => activity.activityId), notes: activitiesToComplete.map(activity => activity.note) }))
                                        } else {
                                            dispatch(completeGroup(activityGroups.find(group => group.activityCategory.id === initialTab)?.id.toString() || ''))
                                            dispatch(setOpenOrdinaryAccountingModal(false))
                                        }
                                    } else {
                                        if (activitiesToOpen.length > 0) {
                                            setActivitiesToComplete([])
                                            setActivitiesToOpen([])
                                            dispatch(setActivityOpen({ ids: activitiesToOpen.map(activity => activity.id), notes: activitiesToOpen.map(activity => activity.note) }))
                                            dispatch(setOpenOrdinaryAccountingModal(false))
                                        }
                                        if (activitiesToComplete.filter(activity => activity.activityGroup === activityGroups.find(group => group.activityCategory.id === initialTab)?.id).length > 0) {
                                            setActivitiesToComplete([])
                                            setActivitiesToOpen([])
                                            dispatch(setActivityCompleted({ ids: activitiesToComplete.map(activity => activity.activityId), notes: activitiesToComplete.map(activity => activity.note) }))
                                            dispatch(setOpenOrdinaryAccountingModal(false))
                                        }
                                    }
                                }}
                                color={Colors.PRIMARY}
                                variant={Variant.SOLID}
                                size={Size.FIT}
                                iconStyle={IconStyle.OFF}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}