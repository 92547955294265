import { keycloak } from "../../keycloak";
import { Fundings } from "./fundings";
import { Leasings } from "./leasings";
import { Loans } from "./loans";

export function Financing() {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24, width: '100%' }}>
            <div className="row m-0 p-0" style={{ gap: 24, width: '100%' }}>
                <div className="col-12 col-xl p-0 m-0">
                    <Loans disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
                </div>
                <div className="col-12 col-xl p-0 m-0">
                    <Fundings disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
                </div>
                <div className="col-12 col-xl p-0 m-0">
                    <Leasings disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
                </div>
            </div>
        </div>
    )
}