import { MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { findAllActivityCategory } from "../activity/slice";
import { FormGroup } from "../formGroup";
import { InfoIcon } from "../icons/info";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { SwitchComponent } from "../switch";
import colors from '../utils/index.module.scss';
import { AccountingSchemeEnum, BankEnum, JournalEnum, bankToNumberMap, journalToNumberMap, numberToBankMap, numberToJournalMap } from "./dto";
import { setCompanyQuotationWizardCreationDTO } from "./slice";

export function JournalPeriodicityBanks() {
    const activityState = useAppSelector(state => state.activity)
    const companyQuotationWizardState = useAppSelector(state => state.companyQuotation)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (companyQuotationWizardState.openCompanyQuotationWizardModal) {
            dispatch(findAllActivityCategory())
        }
    }, [companyQuotationWizardState.openCompanyQuotationWizardModal])

    if (
        activityState.findAllActivityCategoryStatus === 'loading'
    ) {
        return (<div style={{ display: 'flex', justifyContent: 'center' }}><SpinnerComponent size={"small"} /></div>)
    }

    if (activityState.findAllActivityCategoryStatus === 'failed') {
        return (<></>)
    }

    let activityCategoryList: ReactNode[] = []

    if (
        activityState.findAllActivityCategoryStatus === 'successfully' &&
        activityState.findAllActivityCategoryResponse !== undefined &&
        activityState.findAllActivityCategoryResponse.data !== undefined &&
        activityState.findAllActivityCategoryResponse.data.length > 0
    ) {
        activityState.findAllActivityCategoryResponse.data.forEach(activity => {
            activityCategoryList.push(
                <SwitchComponent
                    key={"company-quotation-wizard-serviceType-" + activity.id}
                    id={"company-quotation-wizard-serviceType-" + activity.name}
                    label={activity.name}
                    onChange={(e, checked) => {
                        const newCompany = [...companyQuotationWizardState.companiesToAdd]
                        let newActivityCategoryList = [...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].activityCategoryIds]
                        if (checked) {
                            newActivityCategoryList.push(activity.id)
                        }
                        else {
                            let indexToRemove = newActivityCategoryList.indexOf(activity.id)
                            if (indexToRemove !== -1) {
                                const firstPart = newActivityCategoryList.slice(0, indexToRemove)
                                const secondPart = newActivityCategoryList.slice(indexToRemove + 1)
                                newActivityCategoryList = firstPart.concat(secondPart)
                            }
                        }

                        newCompany[companyQuotationWizardState.index] = {
                            ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                            activityCategoryIds: [...newActivityCategoryList]
                        };

                        dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                    }
                    }
                />
            )
        })
    }

    return (
        <div className="d-flex flex-column w-100 " style={{ gap: 18 }}>
            {
                (companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].accountingScheme === AccountingSchemeEnum.Ordinary) &&
                <SelectComponent
                    id={"company-quotation-wizard-journal"}
                    label="Movimenti Libro Giornale"
                    value={numberToJournalMap(companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].journal)}
                    onChange={(e) => {
                        const journalValue = journalToNumberMap.get(e.target.value as JournalEnum) !== undefined ? journalToNumberMap.get(e.target.value as JournalEnum) : 0
                        const newCompany = [...companyQuotationWizardState.companiesToAdd]

                        newCompany[companyQuotationWizardState.index] = {
                            ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                            journal: journalValue !== undefined ? journalValue : 0
                        };

                        dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                    }
                    }
                    menuItems={[
                        <MenuItem key={JournalEnum.JOURNAL_0} value={JournalEnum.JOURNAL_0}>Fino a 5.000</MenuItem>,
                        <MenuItem key={JournalEnum.JOURNAL_1} value={JournalEnum.JOURNAL_1}>Da 5.000 a 10.000</MenuItem>,
                        <MenuItem key={JournalEnum.JOURNAL_2} value={JournalEnum.JOURNAL_2}>Da 10.000 a 15.000</MenuItem>,
                        <MenuItem key={JournalEnum.JOURNAL_3} value={JournalEnum.JOURNAL_3}>Da 15.000 a 20.000</MenuItem>,
                        <MenuItem key={JournalEnum.JOURNAL_4} value={JournalEnum.JOURNAL_4}>Da 20.000 a 30.000</MenuItem>,
                        <MenuItem key={JournalEnum.JOURNAL_5} value={JournalEnum.JOURNAL_5}>Da 30.000 a 50.000</MenuItem>,
                        <MenuItem key={JournalEnum.JOURNAL_6} value={JournalEnum.JOURNAL_6}>Oltre 50.000</MenuItem>
                    ]}
                />}
            {companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].accountingScheme === AccountingSchemeEnum.Ordinary &&
                <SelectComponent
                    id={"company-quotation-wizard-banks"}
                    label="Numero estratti conto"
                    tooltipIcon={<InfoIcon colorBase={colors.primary500} />}
                    tooltipLabel="Include anche estratti conto per carte di credito, note spese, sistemi di pagamento digitali, ecc."
                    value={numberToBankMap(companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].banksNumber)}
                    onChange={(e) => {
                        const bankValue = bankToNumberMap.get(e.target.value as BankEnum) !== undefined ? bankToNumberMap.get(e.target.value as BankEnum) : 0
                        const newCompany = [...companyQuotationWizardState.companiesToAdd]

                        newCompany[companyQuotationWizardState.index] = {
                            ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                            banksNumber: bankValue !== undefined ? bankValue : 0
                        };

                        dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                    }
                    }
                    menuItems={[
                        <MenuItem key={BankEnum.BANK_0} value={BankEnum.BANK_0}>Fino a 2</MenuItem>,
                        <MenuItem key={BankEnum.BANK_1} value={BankEnum.BANK_1}>Da 2 a 5</MenuItem>,
                        <MenuItem key={BankEnum.BANK_2} value={BankEnum.BANK_2}>Oltre 5</MenuItem>
                    ]}
                />}
            {
                companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].accountingScheme === AccountingSchemeEnum.Ordinary &&
                <FormGroup label={"Tipologia servizio"} inputs={[
                    <div style={{ gap: 24 }} className="d-flex align-items-center">
                        {activityCategoryList}
                    </div>
                ]} style={"column"} />
            }
            {
                companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].activityCategoryIds.includes(1) &&
                <div className=" d-flex align-items-center gap-2">
                    <SelectComponent
                        id={"company-quotation-wizard-iva-month"}
                        label="Mese di partenza IVA"
                        value={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].ivaStartMonth.toString()}
                        onChange={(e) => {
                            const newCompany = [...companyQuotationWizardState.companiesToAdd]

                            newCompany[companyQuotationWizardState.index] = {
                                ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                                ivaStartMonth: e.target.value
                            };

                            dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                        }
                        }
                        menuItems={
                            [
                                <MenuItem value='' key={'select-a-month'}>Seleziona mese</MenuItem>,
                                <MenuItem value='0' key={'0'}>Gennaio</MenuItem>,
                                <MenuItem value='1' key={'1'}>Febbraio</MenuItem>,
                                <MenuItem value='2' key={'2'}>Marzo</MenuItem>,
                                <MenuItem value='3' key={'3'}>Aprile</MenuItem>,
                                <MenuItem value='4' key={'4'}>Maggio</MenuItem>,
                                <MenuItem value='5' key={'5'}>Giugno</MenuItem>,
                                <MenuItem value='6' key={'6'}>Luglio</MenuItem>,
                                <MenuItem value='7' key={'7'}>Agosto</MenuItem>,
                                <MenuItem value='8' key={'8'}>Settembre</MenuItem>,
                                <MenuItem value='9' key={'9'}>Ottobre</MenuItem>,
                                <MenuItem value='10' key={'10'}>Novembre</MenuItem>,
                                <MenuItem value='11' key={'11'}>Dicembre</MenuItem>,
                            ]
                        }
                    />
                    <SelectComponent
                        id={"company-quotation-wizard-iva-year"}
                        label="Anno di partenza IVA"
                        value={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].ivaStartYear.toString()}
                        onChange={(e) => {
                            const newCompany = [...companyQuotationWizardState.companiesToAdd]

                            newCompany[companyQuotationWizardState.index] = {
                                ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                                ivaStartYear: e.target.value
                            };

                            dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                        }
                        }
                        menuItems={
                            [
                                <MenuItem value='' key={'select-a-year'}>Seleziona anno</MenuItem>,
                                <MenuItem value={new Date().getFullYear() -1} key={new Date().getFullYear().toString()}>{new Date().getFullYear() -1}</MenuItem>,
                                <MenuItem value={new Date().getFullYear().toString()} key={new Date().getFullYear().toString()}>{new Date().getFullYear().toString()}</MenuItem>,
                                <MenuItem value={new Date().getFullYear() + 1} key={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</MenuItem>,
                            ]
                        }
                        disabled = {companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].ivaStartMonth.length === 0}
                    />
                </div>
            }
            {
                companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].activityCategoryIds.includes(2) &&
                <div className=" d-flex align-items-center gap-2">
                    <SelectComponent
                        id={"company-quotation-wizard-coge-month"}
                        label="Mese di partenza Co.Ge."
                        value={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].cogeStartMonth.toString()}
                        onChange={(e) => {
                            const newCompany = [...companyQuotationWizardState.companiesToAdd]

                            newCompany[companyQuotationWizardState.index] = {
                                ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                                cogeStartMonth: e.target.value
                            };

                            dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                        }
                        }
                        menuItems={
                            [
                                <MenuItem value='' key={'select-a-month'}>Seleziona mese</MenuItem>,
                                <MenuItem value='0' key={'0'}>Gennaio</MenuItem>,
                                <MenuItem value='1' key={'1'}>Febbraio</MenuItem>,
                                <MenuItem value='2' key={'2'}>Marzo</MenuItem>,
                                <MenuItem value='3' key={'3'}>Aprile</MenuItem>,
                                <MenuItem value='4' key={'4'}>Maggio</MenuItem>,
                                <MenuItem value='5' key={'5'}>Giugno</MenuItem>,
                                <MenuItem value='6' key={'6'}>Luglio</MenuItem>,
                                <MenuItem value='7' key={'7'}>Agosto</MenuItem>,
                                <MenuItem value='8' key={'8'}>Settembre</MenuItem>,
                                <MenuItem value='9' key={'9'}>Ottobre</MenuItem>,
                                <MenuItem value='10' key={'10'}>Novembre</MenuItem>,
                                <MenuItem value='11' key={'11'}>Dicembre</MenuItem>,
                            ]
                        }
                    />
                    <SelectComponent
                        id={"company-quotation-wizard-iva-year"}
                        label="Anno di partenza Co.Ge."
                        value={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].cogeStartYear.toString()}
                        onChange={(e) => {
                            const newCompany = [...companyQuotationWizardState.companiesToAdd]

                            newCompany[companyQuotationWizardState.index] = {
                                ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                                cogeStartYear: e.target.value
                            };

                            dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                        }
                        }
                        menuItems={
                            [
                                <MenuItem value='' key={'select-a-year'}>Seleziona anno</MenuItem>,
                                <MenuItem value={new Date().getFullYear() -1} key={new Date().getFullYear().toString()}>{new Date().getFullYear() -1}</MenuItem>,
                                <MenuItem value={new Date().getFullYear().toString()} key={new Date().getFullYear().toString()}>{new Date().getFullYear().toString()}</MenuItem>,
                                <MenuItem value={new Date().getFullYear() + 1} key={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</MenuItem>,
                            ]
                        }
                        disabled = {companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].cogeStartMonth.length === 0}
                    />
                </div>
            }
        </div>
    )
}