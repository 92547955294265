import { DocumentsResponseDTO, FindAllDocumentsResponse } from "../objects/dto";
import { ActivityCanBeDeletedResponse, ActivityGroupResponse, ActivityResponse, ActivityTypeDTO, ActivityTypesRequestDTO, AcubeAccountDTO, AcubeReconnectResponseDTO, AddAccountantIdDTO, AddContabileIdDTO, AddOperatorDTO, AtecoResponseDTO, BankRequestDTO, CompanyEditPremiumDTO, CompanyResponseDTO, CompanyUnseenInfoDocumentsDTO, CompanyUnseenInfoMessagesDTO, FindAllAtecos, FindAllCompanies, FindAllCompaniesFilters, RegistryEmailDTO, TodosDTO } from "./dto";
import { CompanyServiceImpl } from "./serviceImpl";

export interface CompanyService {
    findAllCompanies(filters: FindAllCompaniesFilters): Promise<FindAllCompanies>
    findCompany(id: string): Promise<CompanyResponseDTO>
    updateRegistry(id: string, data: FormData): Promise<void>
    addOperators(companyId: string, data: AddOperatorDTO): Promise<void>
    findAllAtecos(page: number, itemsPerPage: number): Promise<FindAllAtecos>
    findAteco(id: number): Promise<AtecoResponseDTO>
    addAccountant(companyId: string, data: AddAccountantIdDTO): Promise<void>
    addContabili(companyId: string, data: AddContabileIdDTO): Promise<void>
    editBankAccounts(companyId: string, data: BankRequestDTO): Promise<void>
    activityCanBeDeleted(companyId: string): Promise<ActivityCanBeDeletedResponse[]>
    editCompanyActivityType(companyId: string, data: ActivityTypesRequestDTO): Promise<void>
    editTodos(companyId: string, data: TodosDTO): Promise<void>
    findActivityGroup(year: number, companyId: string): Promise<ActivityGroupResponse>
    findActivityByCompanyId(companyId: number, year: number): Promise<ActivityResponse>
    findActivityTypeById(id: number): Promise<ActivityTypeDTO>
    findActivityByGroup(groupId: number): Promise<ActivityResponse>
    setActivityCompleted(ids: number[], notes: (string | null)[]): Promise<void>
    setActivityOpen(ids: number[], notes: (string | null)[]): Promise<void>
    findAllDocuments(companyId: number, year: number, period: number | '', page: number, received: 'true' | 'false'): Promise<FindAllDocumentsResponse>
    deleteCompany(id: string): Promise<void>
    findDocumentById(id: string): Promise<DocumentsResponseDTO>
    deleteDocument(id: string): Promise<void>
    completeGroup(id: string): Promise<void>
    getCompanyRegistryPdf(id: string): Promise<string>
    setAtecos(atecos: number[], id: string): Promise<void>
    editRegistry(id: string, data: FormData): Promise<void>
    findAcubeConnectRequest(id: string): Promise<string>
    findByIdAcubeGetAccount(id: string, uuid: string): Promise<AcubeAccountDTO>
    editCompany(id: string, data: FormData): Promise<void>
    acubeGetTransaction(fiscalId: string, uuid: string, madeOnAfter: string, madeOnBefore: string): Promise<string>
    acubeReconnectRequest(id: string, uuid: string): Promise<AcubeReconnectResponseDTO>
    getCompanyUnseenInfoDocuments(): Promise<CompanyUnseenInfoDocumentsDTO[]>
    getCompanyUnseenInfoMessages(topics: string[]): Promise<CompanyUnseenInfoMessagesDTO[]>
    setCompanyEditPremium(request: CompanyEditPremiumDTO, id: number): Promise<void>
    registryEmail(request: RegistryEmailDTO, id: number): Promise<void>
    unsetContabile(id: number): Promise<void>
}

export function NewCompanyService(): CompanyService {
    return new CompanyServiceImpl();
}
