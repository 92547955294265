export enum FinancialStatementsTypology {
    unique = 'unique',
    iva = 'iva',
    document770 = 'document770',
    balanceDocument = 'balanceDocument',
}

export enum OtherTypology {
    inspection = 'inspection',
    durc = 'durc',
}

export enum F24Typology {
    other = 'other',
    contributions = 'contributions',
    directTax = 'directTax',
    iva = 'iva'
}

export enum EmployeeTypology {
    other = 'other',
    paySlip = 'paySlip',
    cu = 'cu',
    attendance = 'attendance'
}

export enum FiscalDocumentType {
    balance = "balance",
    f24 = "f24",
    employees = "employees",
    other = 'other'
}

export interface FindAllFiscalDocumentDTO {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: FiscalDocumentResponseDTO[]
}

export interface FiscalDocumentResponseDTO {
    id: string,
    userId: string,
    objectId: string,
    date: Date,
    lastDownloadDate: Date,
    type: FiscalDocumentType,
    typology: FinancialStatementsTypology | F24Typology | EmployeeTypology | OtherTypology,
    note: string,
    fileName: string,
    referenceYear: number,
    referenceMonth: number,
    companyId: string,
    eventId: string,
    status: FiscalDocumentStatus,
    expirationDate: Date,
    referenceDate: Date,
}

export interface FiscalDocumentFilters {
    fileName?: string,
    typology?: FinancialStatementsTypology | F24Typology | EmployeeTypology | OtherTypology,
    year?: number,
    page?: number,
    month?: number | '',
    itemsPerPage?: number,
    status?: string,
    type?: FiscalDocumentType,
    companyId?: string,
}

export interface FiscalDocumentCreationDTO {
    expirationDate: Date | null,
    referenceDate: Date | null,
    note: string,
    fileName: string,
    referenceYear: number,
    referenceMonth: number,
    companyId: string,
    status: FiscalDocumentStatus
    type: FiscalDocumentType,
    typology?: FinancialStatementsTypology | F24Typology | EmployeeTypology | OtherTypology,
}

export enum FiscalDocumentStatus {
    toRead = "toRead",
    read = "read"
}

export const FinancialStatementsMap = new Map<FinancialStatementsTypology, string>([
    [FinancialStatementsTypology.unique, 'Unico'],
    [FinancialStatementsTypology.iva, 'IVA'],
    [FinancialStatementsTypology.document770, '770'],
    [FinancialStatementsTypology.balanceDocument, 'Bilancio'],
])

export const OthersMap = new Map<OtherTypology, string>([
    [OtherTypology.inspection, 'Visura'],
    [OtherTypology.durc, 'Durc'],
])

export const F24Map = new Map<F24Typology, string>([
    [F24Typology.other, 'Altro'],
    [F24Typology.iva, 'IVA'],
    [F24Typology.contributions, 'Contributi'],
    [F24Typology.directTax, 'Imposte dirette']
])

export const EmployeeMap = new Map<EmployeeTypology, string>([
    [EmployeeTypology.other, 'Altro'],
    [EmployeeTypology.paySlip, 'Busta paga'],
    [EmployeeTypology.attendance, 'Presenze'],
    [EmployeeTypology.cu, 'CU']
])

export const StatusMap = new Map<FiscalDocumentStatus, string>([
    [FiscalDocumentStatus.read, "Letto"],
    [FiscalDocumentStatus.toRead, "Da leggere"]
])

export const MonthMap = new Map<number, string>([
    [0, 'Gennaio'],
    [1, 'Febbraio'],
    [2, 'Marzo'],
    [3, 'Aprile'],
    [4, 'Maggio'],
    [5, 'Giugno'],
    [6, 'Luglio'],
    [7, 'Agosto'],
    [8, 'Settembre'],
    [9, 'Ottobre'],
    [10, 'Novembre'],
    [11, 'Dicembre']
])