import { format } from 'date-fns'
import it from 'date-fns/locale/it'
import { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { AvatarComponent } from '../../avatar'
import { AvatarSize, AvatarType } from '../../avatar/dto'
import { ButtonComponent } from '../../button'
import { Colors, IconStyle, Size, Variant } from '../../button/dto'
import { ConfirmOrDenyModal } from '../../confirmOrDenyModal.tsx'
import { SendIcon } from '../../icons/send'
import { keycloak } from '../../keycloak'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { SpinnerComponent } from '../../spinner'
import { TextInput } from '../../textInput'
import colors from '../../utils/index.module.scss'
import { MessageDTO } from './dto'
import { MessageComponent } from './message'
import { deleteChat, findChatUsers, findChats, findGroupChatUsers, findGroupChats, findGroupMessages, findMessages, setSelectedChat } from './slice'
import { InfoTextPopup } from '../../infoTextPopup'

export function ChatComponent() {
    const [page, setPage] = useState<number>(0)
    const [messages, setMessages] = useState<MessageDTO[]>([])
    const [textMessage, setTextMessage] = useState<string>('')

    const [deleteModal, setDeleteModal] = useState<boolean>(false)

    const messagesRef = useRef(null)

    const chatState = useAppSelector(state => state.chatV2)
    const keycloakState = useAppSelector(state => state.secured)
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (chatState.deleteChatStatus === 'successfully') {
            dispatch(findChats({ firstUser: '', secondUser: '', topic: companyState.findCompanyResponse?.id || 0 }))
            setDeleteModal(false)
            dispatch(setSelectedChat(0))
        }
    }, [chatState.deleteChatStatus])

    useEffect(() => {
        setPage(0)
        setMessages([])
    }, [chatState.selectedChat])

    useEffect(() => {
        if (
            chatState.findMessagesStatus === 'successfully' &&
            chatState.findMessagesResponse !== undefined &&
            chatState.findMessagesResponse.messages !== undefined &&
            chatState.findMessagesResponse.messages.length > 0
        ) {
            let messagesList = [...messages, ...chatState.findMessagesResponse.messages]
            chatState.findMessagesResponse.messages.forEach((message, index) => {
                messagesList.concat(
                    message
                )
            })
            setMessages(messagesList)
            if (chatState.findMessagesResponse.page > 0) {
                //@ts-ignore
                messagesRef.current?.lastElementChild?.lastElementChild?.scrollIntoView({ block: 'start' })
            }
        }
    }, [chatState.findMessagesStatus])

    useEffect(() => {
        if (
            chatState.findGroupMessagesStatus === 'successfully' &&
            chatState.findGroupMessagesResponse !== undefined &&
            chatState.findGroupMessagesResponse.messages !== undefined &&
            chatState.findGroupMessagesResponse.messages.length > 0
        ) {
            let messagesList = [...messages, ...chatState.findGroupMessagesResponse.messages]
            chatState.findGroupMessagesResponse.messages.forEach((message, index) => {
                messagesList.concat(
                    message
                )
            })
            setMessages(messagesList)
            if (chatState.findGroupMessagesResponse.page > 0) {
                //@ts-ignore
                messagesRef.current?.lastElementChild?.lastElementChild?.scrollIntoView({ block: 'start' })
            }
        }
    }, [chatState.findGroupMessagesStatus])

    if (keycloakState.socket === undefined) {
        return <></>
    }

    keycloakState.socket.onmessage = () => {
        if (!chatState.groupTrueOrFalse) {
            if (chatState.selectedChat !== 0) {
                const chat = chatState.findChatResponse.find(chat => chat.id === chatState.selectedChat)
                setMessages([])
                dispatch(findMessages({ chatId: chatState.selectedChat, page: 0, itemsPerPage: (chat && chat.notSeenMessages > 0 ? (chat.notSeenMessages + 25) : 25) }))
            }
            dispatch(findChats({ firstUser: '', secondUser: '', topic: companyState.findCompanyResponse?.id || 0 }))
        } else {
            if (chatState.selectedChat !== 0) {
                const groupChat = chatState.findGroupChatResponse.find(chat => chat.id === chatState.selectedChat)
                setMessages([])
                dispatch(findGroupMessages({ chatId: chatState.selectedChat, filter: { page: 0, itemsPerPage: (groupChat && groupChat.notSeenMessages > 0 ? (groupChat.notSeenMessages + 25) : 25), order: false } }))
            }
            dispatch(findGroupChats({ topic: companyState.findCompanyResponse?.id ?? 0, user: [] }))
        }
    }

    const chat = chatState.findChatResponse.find(chat => chat.id === chatState.selectedChat)
    const groupChat = chatState.findGroupChatResponse.find(chat => chat.id === chatState.selectedChat)

    return (
        <div style={{
            height: '100%',
            backgroundColor: colors.neutral20,
            width: '100%',
            borderRadius: 12,
            border: '1px solid ' + colors.neutral80,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            {
                chatState.selectedChat === 0 ? (
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='text text--md typography--regular typography-neutral--700'>
                        Seleziona una chat per mandare messaggi.
                    </div>
                ) : (
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        {
                            !chatState.groupTrueOrFalse ?
                                <div style={{ padding: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ flexGrow: 1 }}>
                                        <AvatarComponent
                                            type={AvatarType.USER}
                                            size={AvatarSize.MD}
                                            src={chatState.findChatAvatarResponse.find(avatar => avatar.userId === chatState.avatarInfos?.userId)?.objectId || ''}
                                            name={chatState.avatarInfos?.first}
                                            phoneNumber={chatState.avatarInfos?.third}
                                        />
                                    </div>
                                    {
                                        !chat?.active &&
                                        <ButtonComponent
                                            label={'Elimina chat'}
                                            onClick={() => setDeleteModal(true)}
                                            color={Colors.DESTRUCTIVE}
                                            variant={Variant.OUTLINE}
                                            size={Size.SM}
                                            iconStyle={IconStyle.OFF}
                                        />
                                    }
                                </div>
                                :
                                <div style={{ padding: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 12, flexGrow: 1 }}>
                                        <div style={{ width: "45px", height: "45px", borderRadius: "248px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: colors.primary500 }}>
                                            {chatState.groupList?.icon}
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                            <span className="text-overflow text text--lg typography--medium typography-neutral--800">
                                                {chatState.groupList?.name}
                                            </span>
                                            <InfoTextPopup position='bottom' label={chatState.findGroupChatUserResponse.filter(elem =>
                                                chatState.findGroupChatResponse.find(member =>
                                                    member.id === groupChat?.id)?.users.includes(elem.userId)).map(item =>
                                                      item.role === 'companies'? item.businessName : ( item.name? (item.name + " " + item.surname) : item.businessName)).join(', ')}
                                                children={<span className="text-overflow text text--sm typography--regular typography-neutral--500">
                                                    {chatState.groupList?.members + " membri"}</span>} />
                                        </div>
                                    </div>
                                </div>
                        }
                        <div style={{ backgroundColor: colors.white, height: 'calc(100% - 147.98px)' }}>
                            {
                                chatState.findMessagesStatus === 'loading' || chatState.findGroupMessagesStatus === 'loading' ?
                                    <SpinnerComponent size={'small'} /> :
                                    <div id='scrollableDivMessages' ref={messagesRef} style={{ display: 'flex', flexDirection: "column-reverse", overflow: "scroll", height: '100%' }}>
                                        <InfiniteScroll
                                            key={chatState.selectedChat + '-chat-list-message'}
                                            inverse
                                            style={{ display: 'flex', flexDirection: 'column-reverse', overflow: 'scroll' }}
                                            scrollableTarget="scrollableDivMessages"
                                            dataLength={messages.length}
                                            next={() => {
                                                if (chatState.findMessagesStatus !== 'loading') {
                                                    setPage(page + 1)
                                                    dispatch(findMessages({ page: page + 1, itemsPerPage: (chat && chat.notSeenMessages > 0 ? (chat.notSeenMessages + 25) : 25), chatId: chatState.selectedChat }))
                                                }
                                                if (chatState.findGroupMessagesStatus !== 'loading') {
                                                    setPage(page + 1)
                                                    dispatch(findGroupMessages({ chatId: chatState.selectedChat, filter: { page: page + 1, itemsPerPage: (groupChat && groupChat.notSeenMessages > 0 ? (groupChat.notSeenMessages + 25) : 25), order: false } }))
                                                }
                                            }}
                                            hasMore={!chatState.groupTrueOrFalse ? chatState.findMessagesResponse?.next! : chatState.findGroupMessagesResponse?.next!}
                                            endMessage={
                                                <span className='text text--md typography--regular typography-neutral--700 text-center p-4'>
                                                    Non sono presenti altri messaggi
                                                </span>
                                            }
                                            loader={
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        padding: 10,
                                                        margin: 0,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <SpinnerComponent size={"small"} />
                                                </div>
                                            }
                                        >
                                            {
                                                messages.map((message, index) => (
                                                    <div
                                                        key={message.id}
                                                        style={{
                                                            display: 'flex',
                                                            gap: '8px',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            alignSelf: 'stretch',
                                                            borderRadius: '6px',
                                                            width: '100%',
                                                            padding: (
                                                                (
                                                                    messages[index + 1] &&
                                                                    messages[index + 1].sender === message.sender &&
                                                                    format(new Date(message.timestamp), 'dd/MM/yyyy') === format(new Date(messages[index + 1].timestamp), 'dd/MM/yyyy')
                                                                ) || (
                                                                        messages[index - 1] &&
                                                                        messages[index - 1].sender === message.sender &&
                                                                        format(new Date(message.timestamp), 'dd/MM/yyyy') === format(new Date(messages[index - 1].timestamp), 'dd/MM/yyyy')
                                                                    ) ? '2px 12px' : '8px 12px')
                                                        }}>
                                                        {
                                                            (!messages[index + 1] || (messages[index + 1] && format(new Date(message.timestamp), 'dd/MM/yyyy') !== format(new Date(messages[index + 1].timestamp), 'dd/MM/yyyy'))) &&
                                                            <span className='text text--md typography--medium typography-neutral--500'>
                                                                {new Date().getFullYear() === new Date(message.timestamp).getFullYear() && (format(new Date(message.timestamp), 'eee ', { locale: it }))}
                                                                {(format(new Date(message.timestamp), 'dd MMM', { locale: it }))}
                                                                {new Date().getFullYear() !== new Date(message.timestamp).getFullYear() && format(new Date(message.timestamp), ' yyyy')}
                                                            </span>
                                                        }
                                                        <div className="text text--lg typography--medium typography-neutral--800"
                                                            style={{ flexGrow: 1, flexBasis: '45%', maxWidth: '45%', alignSelf: (message.sender === keycloak.subject ? 'flex-end' : 'flex-start') }}>
                                                            <MessageComponent
                                                                sameSanderAsPrevious={
                                                                    messages[index + 1] &&
                                                                    messages[index + 1].sender === message.sender &&
                                                                    format(new Date(message.timestamp), 'dd/MM/yyyy') === format(new Date(messages[index + 1].timestamp), 'dd/MM/yyyy')
                                                                }
                                                                isBroadcast={
                                                                    chat?.isBroadcast ||
                                                                    (keycloak.subject !== chat?.firstUser && keycloak.subject !== chat?.secondUser)
                                                                }
                                                                message={message.message}
                                                                sender={!chatState.groupTrueOrFalse ? 
                                                                    chatState.findChatUserResponse.find(user => user.userId === message.sender)?  chatState.findChatUserResponse.find(user => user.userId === message.sender) : undefined : 
                                                                    chatState.findGroupChatUserResponse.find(user => user.userId === message.sender)}
                                                                sent={message.sender === keycloak.subject}
                                                                date={message.timestamp}
                                                                businessName= {message.businessName}
                                                                name={message.name}
                                                                surname={message.surname}
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </InfiniteScroll>
                                    </div>
                            }
                        </div>
                        <div style={{ width: '100%', padding: '16px', gap: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', height: '72px' }}>
                            <TextInput
                                key={chatState.selectedChat + '-chat-input-message'}
                                autofocus={true}
                                value={textMessage}
                                onChange={e => setTextMessage(e.target.value)}
                                id={'chat'}
                                type={'text'}
                                placeholder={'Inserisci un messaggio'}
                                disabled={
                                    (!chat?.active && !groupChat?.active) || keycloak.hasRealmRole("admin")
                                }
                            />
                            <ButtonComponent
                                key={chatState.selectedChat + '-chat-button-message'}
                                label={''}
                                onClick={() => {
                                    if (keycloak.tokenParsed !== undefined && keycloak.tokenParsed.exp !== undefined && textMessage !== '') {
                                        if (keycloakState.socket && keycloakState.socket.readyState === keycloakState.socket.OPEN) {
                                            keycloakState.socket.send(chatState.selectedChat + ':' + textMessage + ':' + authState.findMeResponse?.name + ':' + authState.findMeResponse?.surname + ':' + authState.findMeResponse?.businessName )
                                            setTextMessage('')
                                            setTimeout(() => {
                                                setMessages([])
                                                if (!chatState.groupTrueOrFalse) {
                                                    dispatch(findMessages({ chatId: chatState.selectedChat, page: 0, itemsPerPage: (chat && chat.notSeenMessages > 0 ? (chat.notSeenMessages + 25) : 25) }))
                                                    dispatch(findChatUsers(chatState.usersListAfterSend))
                                                    dispatch(findChats({ topic: companyState.findCompanyResponse?.id || 0, firstUser: '', secondUser: '' }))
                                                } else {
                                                    dispatch(findGroupMessages({ chatId: chatState.selectedChat, filter: { page: 0, itemsPerPage: (groupChat && groupChat.notSeenMessages > 0 ? (groupChat.notSeenMessages + 25) : 25), order: false } }))
                                                    dispatch(findGroupChatUsers(chatState.groupUsersListAfterSend))
                                                    dispatch(findGroupChats({ topic: companyState.findCompanyResponse?.id ?? 0, user: [] }))
                                                }
                                            }, 200)
                                        }
                                    }
                                }}
                                color={Colors.PRIMARY}
                                variant={Variant.SOLID}
                                size={Size.MD}
                                iconStyle={IconStyle.ONLY}
                                icon={<SendIcon colorBase={''} />}
                                disabled={
                                    (!chat?.active && !groupChat?.active) || keycloak.hasRealmRole("admin")
                                }
                            />
                        </div>
                    </div>
                )
            }
            <ConfirmOrDenyModal
                open={deleteModal}
                handleClose={() => setDeleteModal(false)}
                title={'Elimina chat'}
                description={'Sei sicuro di voler eliminare la chat?'}
                labelDeny={'Annulla'}
                labelConfirm={'Elimina chat'}
                actionConfirm={() => { dispatch(deleteChat(chat?.id || 0)) }}
            />
        </div>
    )
}