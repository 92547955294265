import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../utils"
import { NewObjectService } from "./service"

interface AccountantState {
    saveDocumentStatus: PromiseStatuses
    findDocumentStatus: PromiseStatuses
    document: string | null
    findDocumentsStatus: PromiseStatuses,
    findDocumentsResponse: string[]
}

const initialState: AccountantState = {
    saveDocumentStatus: 'idle',
    findDocumentStatus: 'idle',
    document: null,
    findDocumentsStatus: "idle",
    findDocumentsResponse: []
}

export const saveDocument = createAsyncThunk(
    'objects/saveDocument',
    async (request: FormData[], thunkApi): Promise<void[]> => {
        let promises: Promise<void>[] = []
        promises = request.map(async (document) => {
            const objectService = NewObjectService()

            return objectService.saveDocument(document)
        })
        return Promise.all(promises)
    }
)

export const findFileById = createAsyncThunk(
    'objects/findFileById',
    async (request: string, thunkApi): Promise<string> => {
        const objectService = NewObjectService()

        return objectService.findFileById(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findMultipleFileById = createAsyncThunk(
    'objects/findMultipleFileById',
    async (request: string[], thunkApi): Promise<string[]> => {
        let promises: Promise<string>[] = []
        promises = request.map(async (id) => {
            if (id !== null) {
                const objectService = NewObjectService()

                return objectService.findFileById(id)
            } else return ''
        })
        return Promise.all(promises)
    }
)

const accountantSlice = createSlice({
    name: 'financialAdvisor/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(saveDocument.pending, (state) => {
                state.saveDocumentStatus = 'loading'
            })
            .addCase(saveDocument.fulfilled, (state) => {
                state.saveDocumentStatus = 'successfully'
            })
            .addCase(saveDocument.rejected, (state) => {
                state.saveDocumentStatus = 'failed'
            })
            .addCase(findFileById.pending, (state) => {
                state.findDocumentStatus = 'loading'
            })
            .addCase(findFileById.fulfilled, (state, action) => {
                state.findDocumentStatus = 'successfully'
                state.document = action.payload
            })
            .addCase(findFileById.rejected, (state) => {
                state.findDocumentStatus = 'failed'
            })
            .addCase(findMultipleFileById.pending, (state) => {
                state.findDocumentsStatus = 'loading'
            })
            .addCase(findMultipleFileById.fulfilled, (state, action) => {
                state.findDocumentsStatus = 'successfully'
                state.findDocumentsResponse = action.payload
            })
            .addCase(findMultipleFileById.rejected, (state) => {
                state.findDocumentsStatus = 'failed'
            })
    },
    reducers: {
        setSaveDocumentStatus: (state, action) => {
            state.saveDocumentStatus = action.payload
        },
        setFindDocumentStatus: (state, action) => {
            state.findDocumentStatus = action.payload
        },
        setFindMultipleFileById: (state, action) => {
            state.findDocumentsStatus = action.payload
        }
    },
})

export const {
    setFindDocumentStatus,
    setSaveDocumentStatus,
    setFindMultipleFileById
} = accountantSlice.actions

export default accountantSlice.reducer