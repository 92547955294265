import { MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { SearchIcon } from "../icons/search";
import { keycloak } from "../keycloak";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import { findAllRequestToConfirm, setUpdateCompanySettingsCompanyNameFilter, setUpdateCompanySettingsFinancialAdvisorFilter } from "./slice";

export function RequestToConfirmFiltersComponent() {
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const requestToConfirmState = useAppSelector(state => state.requestToConfirm)

    const dispatch = useAppDispatch()

    let financialAdvisorsMenuItems: ReactNode[] = []

    useEffect(() => {
        dispatch(findAllFinancialAdvisorWithoutPagination())
    }, [])

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading') {
        return (<div><SpinnerComponent size={"small"} /></div>)
    }

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed') {
        return (<div>Si è verificato un errore</div>)
    }

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0) {
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.slice().sort((a, b) => a.surname.localeCompare(b.surname)).forEach(financialAdvisor => {
            financialAdvisorsMenuItems.push(
                <MenuItem key={'request-to-confirm-financial-advisor-filter-' + financialAdvisor.id} value={financialAdvisor.id}>{financialAdvisor.surname + ' ' + financialAdvisor.name}</MenuItem>
            )
        })
    }

    return (
        <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
            <div className="col-12 col-lg p-0 m-0">
                <TextInput
                    value={requestToConfirmState.requestToAddFilters.companyName}
                    onChange={(e) => dispatch(setUpdateCompanySettingsCompanyNameFilter(e.target.value))}
                    id="request-to-confirm-company-filter"
                    type={"text"}
                    placeholder={"Cerca azienda"}
                    startIcon={<SearchIcon colorBase={""} />}
                />
            </div>
            {
                keycloak.hasRealmRole('admin') &&
                <div className="col-12 col-lg p-0 m-0">
                    <SelectComponent
                        onChange={(e) => {
                            dispatch(setUpdateCompanySettingsFinancialAdvisorFilter(e.target.value))
                            dispatch(findAllRequestToConfirm({
                                companyName: requestToConfirmState.requestToAddFilters.companyName,
                                itemsPerPage: requestToConfirmState.requestToAddFilters.itemsPerPage,
                                page: requestToConfirmState.requestToAddFilters.page,
                                status: requestToConfirmState.requestToAddFilters.status,
                                financialAdvisor: e.target.value
                            }))
                        }
                        }
                        id={"request-to-confirm-financial-advisor-filter"}
                        value={requestToConfirmState.requestToAddFilters.financialAdvisor}
                        menuItems={
                            [
                                <MenuItem key='' value=''>Tutti i commercialisti</MenuItem>,
                                financialAdvisorsMenuItems
                            ]
                        }
                    />
                </div>
            }
            <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="row p-0 m-0" style={{ gap: '8px' }}>
                    <div className="col p-0 m-0">
                        <ButtonComponent
                            label={"Pulisci"}
                            onClick={() => {
                                dispatch(setUpdateCompanySettingsCompanyNameFilter(""))
                                dispatch(setUpdateCompanySettingsFinancialAdvisorFilter(""))
                                dispatch(findAllRequestToConfirm({
                                    companyName: "",
                                    itemsPerPage: requestToConfirmState.requestToAddFilters.itemsPerPage,
                                    page: requestToConfirmState.requestToAddFilters.page,
                                    status: requestToConfirmState.requestToAddFilters.status,
                                    financialAdvisor: ""
                                }))
                            }

                            }
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.MD}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div className="col p-0 m-0">
                        <ButtonComponent
                            label={"Applica"}
                            onClick={() => dispatch(findAllRequestToConfirm(requestToConfirmState.requestToAddFilters))}
                            color={Colors.PRIMARY}
                            variant={Variant.OUTLINE}
                            size={Size.MD}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}