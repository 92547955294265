import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { keycloak } from "../keycloak"
import { NewObjectService } from "../objects/service"
import { PromiseStatuses } from "../utils"
import { CreateFinancialAdvisorDTO, EditFinancialAdvisorDTO, FinancialAdvisorResponseDTO, FindAllFinancialAdvisorFiltersDTO, FindAllFinancialAdvisors, SetAccountantDTO } from "./dto"
import { NewFinancialAdvisorService } from "./service"

interface FinancialAdvisorState {
    financialAdvisorSettedId: string
    findAllFinancialAdvisorResponse?: FindAllFinancialAdvisors
    findAllFinancialAdvisorWithoutPaginationResponse?: FindAllFinancialAdvisors
    findFinancialAdvisorResponse?: FinancialAdvisorResponseDTO
    createFinancialAdvisorRequest: CreateFinancialAdvisorDTO
    editFinancialAdvisorRequest: EditFinancialAdvisorDTO
    findAllFinancialAdvisorFilters: FindAllFinancialAdvisorFiltersDTO
    findAllFinancialAdvisorStatus: PromiseStatuses
    findAllFinancialAdvisorWithoutPaginationStatus: PromiseStatuses
    findFinancialAdvisorStatus: PromiseStatuses
    createFinancialAdvisorStatus: PromiseStatuses
    editFinancialAdvisorStatus: PromiseStatuses
    editFinancialAdvisorFindAvatarStatus: PromiseStatuses
    openFinancialAdvisorModal: boolean
    editFinancialAdvisorAvatar: string
    findFinancialAdvisorsListAvatarsByIdResponse: string[]
    findFinancialAdvisorsListAvatarsByIdStatus: PromiseStatuses
    deleteFinancialAdvisorStatus: PromiseStatuses
    openDeleteFinancialAdvisor: boolean
    financialAdvisorIndexToDelete: string
    errors: {
        createFinancialAdvisor: {
            email?: string
        }
    },
    setAccountantRequest: SetAccountantDTO,
    setAccountantStatus: PromiseStatuses,
    editFinancialAdvisorComplete: PromiseStatuses
}

const initialState: FinancialAdvisorState = {
    financialAdvisorSettedId: '',
    financialAdvisorIndexToDelete: '',
    findFinancialAdvisorResponse: undefined,
    createFinancialAdvisorRequest: {
        email: '',
        businessName: '',
        name: '',
        surname: '',
        description: '',
        accountantId: '',
        activityNotifications: false,
        operationsNotifications: false,
    },
    findAllFinancialAdvisorFilters: { page: 0, itemsPerPage: 25, financialAdvisor: '', accountant: '', allofall: false },
    editFinancialAdvisorRequest: {},
    findAllFinancialAdvisorStatus: 'idle',
    createFinancialAdvisorStatus: 'idle',
    findFinancialAdvisorStatus: 'idle',
    editFinancialAdvisorStatus: 'idle',
    findAllFinancialAdvisorWithoutPaginationStatus: 'idle',
    editFinancialAdvisorFindAvatarStatus: 'idle',
    openFinancialAdvisorModal: false,
    editFinancialAdvisorAvatar: '',
    findFinancialAdvisorsListAvatarsByIdResponse: [],
    findFinancialAdvisorsListAvatarsByIdStatus: 'idle',
    deleteFinancialAdvisorStatus: 'idle',
    openDeleteFinancialAdvisor: false,
    errors: {
        createFinancialAdvisor: {
            email: undefined
        }
    },
    setAccountantRequest: {
        id: 0
    },
    setAccountantStatus: "idle",
    editFinancialAdvisorComplete: 'idle'
}

export const findAllFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/findAllFinancialAdvisor',
    async (request: FindAllFinancialAdvisorFiltersDTO, thunkApi): Promise<FindAllFinancialAdvisors> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.findAllFinancialAdvisor(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAllFinancialAdvisorWithoutPagination = createAsyncThunk(
    'financialAdvisor/findAllFinancialAdvisorWithoutPagination',
    async (accountant: string | undefined, thunkApi): Promise<FindAllFinancialAdvisors> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.findAllFinancialAdvisor({ page: 0, itemsPerPage: 0, financialAdvisor: '', accountant: accountant === undefined ? '' : accountant, allofall: keycloak.hasRealmRole('accountant') ? true : false }).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const createFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/createFinancialAdvisor',
    async (request: FormData, thunkApi): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.createFinancialAdvisor(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error.response.data.errors))
        })
    }
)

export const findFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/findFinancialAdvisor',
    async (request: string, thunkApi): Promise<FinancialAdvisorResponseDTO> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.findFinancialAdvisor(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const editFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/editFinancialAdvisor',
    async (request: { data: FormData, id: string }, thunkApi): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.editFinancialAdvisor(request.data, request.id).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findFinancialAdvisorAvatarById = createAsyncThunk(
    'financialAdvisor/findFinancialAdvisorAvatarById',
    async (request: string, thunkApi): Promise<string> => {
        const objectService = NewObjectService()

        return objectService.findFileById(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findFinancialAdvisorsListAvatarsById = createAsyncThunk(
    'financialAdvisor/findFinancialAdvisorsListAvatarsById',
    async (request: string[], thunkApi): Promise<string[]> => {
        let promises: Promise<string>[] = []
        promises = request.map(async (id) => {
            if (id !== null) {
                const objectService = NewObjectService()

                return objectService.findFileById(id)
            } else return ''
        })
        return Promise.all(promises)
    }
)

export const deleteFinancialAdvisor = createAsyncThunk(
    'financialAdvisor/deleteFinancialAdvisor',
    async (id: string, thunkApi): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.deleteFinancialAdvisor(id).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const setAccountant = createAsyncThunk(
    'financialAdvisor/setAccountant',
    async (request: { id: string, adminAccountant: SetAccountantDTO }, thunkApi): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.setAccountant(request.id, request.adminAccountant).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const editFinancialAdvisorComplete = createAsyncThunk(
    'financialAdvisor/editFinancialAdvisorComplete',
    async (request: { id: string, formData: FormData, setAccountant: SetAccountantDTO }): Promise<void[]> => {
        let promises: Promise<void>[] = []
        const financialAdvisorService = NewFinancialAdvisorService()
        promises.push(financialAdvisorService.editFinancialAdvisor(request.formData, request.id))
        if (request.setAccountant.id !== 0)
            promises.push(financialAdvisorService.setAccountant(request.id, request.setAccountant))
        return Promise.all(promises)
    }
)

const financialAdvisorSlice = createSlice({
    name: 'financialAdvisor/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllFinancialAdvisor.pending, (state) => {
                state.findAllFinancialAdvisorStatus = 'loading'
            })
            .addCase(findAllFinancialAdvisor.fulfilled, (state, action) => {
                state.findAllFinancialAdvisorResponse = action.payload
                state.findAllFinancialAdvisorStatus = 'successfully'
            })
            .addCase(findAllFinancialAdvisor.rejected, (state) => {
                state.findAllFinancialAdvisorStatus = 'failed'
            })
            .addCase(findAllFinancialAdvisorWithoutPagination.pending, (state) => {
                state.findAllFinancialAdvisorWithoutPaginationStatus = 'loading'
            })
            .addCase(findAllFinancialAdvisorWithoutPagination.fulfilled, (state, action) => {
                state.findAllFinancialAdvisorWithoutPaginationResponse = action.payload
                state.findAllFinancialAdvisorWithoutPaginationStatus = 'successfully'
            })
            .addCase(findAllFinancialAdvisorWithoutPagination.rejected, (state) => {
                state.findAllFinancialAdvisorWithoutPaginationStatus = 'failed'
            })
            .addCase(createFinancialAdvisor.pending, (state) => {
                state.createFinancialAdvisorStatus = 'loading'
            })
            .addCase(createFinancialAdvisor.fulfilled, (state) => {
                state.createFinancialAdvisorStatus = 'successfully'
            })
            .addCase(createFinancialAdvisor.rejected, (state, action) => {
                //@ts-ignore
                const errorEmail = action.payload.find(error => error.message.includes('User with username') && error.message.includes('already exists'))
                if (errorEmail === undefined) {
                    state.createFinancialAdvisorStatus = 'failed'
                } else {
                    state.createFinancialAdvisorStatus = 'idle'
                }
                state.errors.createFinancialAdvisor.email = errorEmail
            })
            .addCase(findFinancialAdvisor.pending, (state) => {
                state.findFinancialAdvisorStatus = 'loading'
            })
            .addCase(findFinancialAdvisor.fulfilled, (state, action: PayloadAction<FinancialAdvisorResponseDTO>) => {
                state.findFinancialAdvisorResponse = action.payload
                state.findFinancialAdvisorStatus = 'successfully'
            })
            .addCase(findFinancialAdvisor.rejected, (state) => {
                state.findFinancialAdvisorStatus = 'failed'
            })
            .addCase(editFinancialAdvisor.pending, (state) => {
                state.editFinancialAdvisorStatus = 'loading'
            })
            .addCase(editFinancialAdvisor.fulfilled, (state) => {
                state.editFinancialAdvisorStatus = 'successfully'
            })
            .addCase(editFinancialAdvisor.rejected, (state) => {
                state.editFinancialAdvisorStatus = 'failed'
            })
            .addCase(findFinancialAdvisorAvatarById.pending, (state) => {
                state.editFinancialAdvisorFindAvatarStatus = 'loading'
            })
            .addCase(findFinancialAdvisorAvatarById.fulfilled, (state, action) => {
                state.editFinancialAdvisorFindAvatarStatus = 'successfully'
                state.editFinancialAdvisorAvatar = action.payload
            })
            .addCase(findFinancialAdvisorAvatarById.rejected, (state) => {
                state.editFinancialAdvisorFindAvatarStatus = 'failed'
            })
            .addCase(findFinancialAdvisorsListAvatarsById.pending, (state) => {
                state.findFinancialAdvisorsListAvatarsByIdStatus = 'loading'
            })
            .addCase(findFinancialAdvisorsListAvatarsById.fulfilled, (state, action) => {
                state.findFinancialAdvisorsListAvatarsByIdStatus = 'successfully'
                state.findFinancialAdvisorsListAvatarsByIdResponse = action.payload
            })
            .addCase(findFinancialAdvisorsListAvatarsById.rejected, (state) => {
                state.findFinancialAdvisorsListAvatarsByIdStatus = 'failed'
            })
            .addCase(deleteFinancialAdvisor.pending, (state) => {
                state.deleteFinancialAdvisorStatus = 'loading'
            })
            .addCase(deleteFinancialAdvisor.fulfilled, (state) => {
                state.deleteFinancialAdvisorStatus = 'successfully'
            })
            .addCase(deleteFinancialAdvisor.rejected, (state) => {
                state.deleteFinancialAdvisorStatus = 'failed'
            })
            .addCase(setAccountant.pending, (state) => {
                state.setAccountantStatus = 'loading'
            })
            .addCase(setAccountant.fulfilled, (state) => {
                state.setAccountantStatus = 'successfully'
            })
            .addCase(setAccountant.rejected, (state) => {
                state.setAccountantStatus = 'failed'
            })
            .addCase(editFinancialAdvisorComplete.pending, (state) => {
                state.editFinancialAdvisorComplete = 'loading'
            })
            .addCase(editFinancialAdvisorComplete.fulfilled, (state) => {
                state.editFinancialAdvisorComplete = 'successfully'
            })
            .addCase(editFinancialAdvisorComplete.rejected, (state) => {
                state.editFinancialAdvisorComplete = 'failed'
            })
    },
    reducers: {
        setAccountantFilterFa: (state, action) => {
            state.findAllFinancialAdvisorFilters.accountant = action.payload
        },
        setPageFilter: (state, action) => {
            state.findAllFinancialAdvisorFilters.page = action.payload
        },
        setFinancialAdvisorFilterFa: (state, action) => {
            state.findAllFinancialAdvisorFilters.financialAdvisor = action.payload
        },
        setFinancialAdvisorSettedId: (state, action) => {
            state.financialAdvisorSettedId = action.payload
        },
        setFinancialAdvisorIndexToDelete: (state, action) => {
            state.financialAdvisorIndexToDelete = action.payload
        },
        setOpenFinancialAdvisorModal: (state, action) => {
            state.openFinancialAdvisorModal = action.payload
        },
        setCreateFinancialAdvisorRequestBusinessName: (state, action) => {
            state.createFinancialAdvisorRequest.businessName = action.payload
        },
        setCreateFinancialAdvisorRequestDescription: (state, action) => {
            state.createFinancialAdvisorRequest.description = action.payload
        },
        setCreateFinancialAdvisorRequestEmail: (state, action) => {
            state.createFinancialAdvisorRequest.email = action.payload
        },
        setCreateFinancialAdvisorRequestName: (state, action) => {
            state.createFinancialAdvisorRequest.name = action.payload
        },
        setCreateFinancialAdvisorRequestPhoneNumber: (state, action) => {
            state.createFinancialAdvisorRequest.phoneNumber = action.payload
        },
        setCreateFinancialAdvisorRequestSurname: (state, action) => {
            state.createFinancialAdvisorRequest.surname = action.payload
        },
        setCreateFinancialAdvisorAdminAccountantId: (state, action) => {
            state.createFinancialAdvisorRequest.accountantId = action.payload
        },
        setEditFinancialAdvisorRequestBusinessName: (state, action) => {
            state.editFinancialAdvisorRequest.businessName = action.payload
        },
        setEditFinancialAdvisorRequestDescription: (state, action) => {
            state.editFinancialAdvisorRequest.description = action.payload
        },
        setEditFinancialAdvisorAccountant: (state, action) => {
            state.setAccountantRequest.id = action.payload
        },
        setEditFinancialAdvisorRequestName: (state, action) => {
            state.editFinancialAdvisorRequest.name = action.payload
        },
        setEditFinancialAdvisorRequestPhoneNumber: (state, action) => {
            state.editFinancialAdvisorRequest.phoneNumber = action.payload
        },
        setEditFinancialAdvisorRequestSurname: (state, action) => {
            state.editFinancialAdvisorRequest.surname = action.payload
        },
        setEditFinancialAdvisorStatus: (state, action) => {
            state.editFinancialAdvisorComplete = action.payload
        },
        resetCreateFinancialAdvisorRequest: (state) => {
            state.createFinancialAdvisorRequest = {
                email: '',
                businessName: '',
                name: '',
                surname: '',
                description: '',
                accountantId: '',
                activityNotifications: false,
                operationsNotifications: false,
            }
        },
        setCreateFinancialAdvisorStatus: (state, action) => {
            state.createFinancialAdvisorStatus = action.payload
        },
        setEditFinancialAdvisorFindAvatarStatus: (state, action) => {
            state.editFinancialAdvisorFindAvatarStatus = action.payload
        },
        setFinancialAdvisorFindAllWithoutPaginationStatus: (state, action) => {
            state.findAllFinancialAdvisorWithoutPaginationStatus = action.payload
        },
        setOpenDeleteFinancialAdvisorModal: (state, action) => {
            state.openDeleteFinancialAdvisor = action.payload
        },
        setDeleteFiancialAdvisorStatus: (state, action) => {
            state.deleteFinancialAdvisorStatus = action.payload
        },
        resetErrorCreateFinancialAdvisorEmail: (state) => {
            state.errors.createFinancialAdvisor.email = undefined
        }
    },
})

export const {
    setAccountantFilterFa,
    setFinancialAdvisorFilterFa,
    setOpenFinancialAdvisorModal,
    setCreateFinancialAdvisorRequestBusinessName,
    setCreateFinancialAdvisorRequestDescription,
    setCreateFinancialAdvisorRequestEmail,
    setCreateFinancialAdvisorRequestName,
    setCreateFinancialAdvisorRequestPhoneNumber,
    setCreateFinancialAdvisorRequestSurname,
    setCreateFinancialAdvisorAdminAccountantId,
    setFinancialAdvisorSettedId,
    setEditFinancialAdvisorRequestBusinessName,
    setEditFinancialAdvisorRequestDescription,
    setEditFinancialAdvisorAccountant,
    setEditFinancialAdvisorRequestName,
    setEditFinancialAdvisorRequestPhoneNumber,
    setEditFinancialAdvisorRequestSurname,
    setEditFinancialAdvisorStatus,
    resetCreateFinancialAdvisorRequest,
    setCreateFinancialAdvisorStatus,
    setEditFinancialAdvisorFindAvatarStatus,
    setFinancialAdvisorFindAllWithoutPaginationStatus,
    setOpenDeleteFinancialAdvisorModal,
    setFinancialAdvisorIndexToDelete,
    setDeleteFiancialAdvisorStatus,
    resetErrorCreateFinancialAdvisorEmail,
    setPageFilter
} = financialAdvisorSlice.actions

export default financialAdvisorSlice.reducer