import { TableBody } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { findAllCompanyWithoutPagination } from "../company/slice";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import { RequestToConfirmMenuComponent } from "./menu";
import { findAllRequestToConfirm, setCompanyToRequestModal, setCompanyToRequestSettedId } from "./slice";

export function RequestToConfirmListComponent() {
    const requestToConfirmState = useAppSelector(state => state.requestToConfirm)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const companyState = useAppSelector(state => state.company)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllRequestToConfirm(requestToConfirmState.requestToAddFilters))
        dispatch(findAllFinancialAdvisorWithoutPagination())
        dispatch(findAllCompanyWithoutPagination())
    }, [])

    useEffect(() => {
        if (requestToConfirmState.confirmOrDenyRequestStatus === 'successfully') {
            dispatch(setCompanyToRequestModal(false))
            dispatch(setCompanyToRequestSettedId(''))
            dispatch(findAllRequestToConfirm(requestToConfirmState.requestToAddFilters))
        }
    }, [requestToConfirmState.confirmOrDenyRequestStatus])

    if (
        requestToConfirmState.findAllUpdateCompanySettingsStatus === 'loading' ||
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading' ||
        requestToConfirmState.confirmOrDenyRequestStatus === 'loading'
    ) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <SpinnerComponent size={"small"} />
            </div>
        )
    }

    if (requestToConfirmState.findAllUpdateCompanySettingsStatus === 'failed' || financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed' || companyState.findAllCompaniesWithoutPaginationStatus === "failed") {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    let companyRows: ReactNode[] = []

    if (
        requestToConfirmState.findAllUpdateCompanySettingsStatus === 'successfully' &&
        requestToConfirmState.findAllUpdateCompanySettingsResponse !== undefined &&
        requestToConfirmState.findAllUpdateCompanySettingsResponse.data !== undefined &&
        requestToConfirmState.findAllUpdateCompanySettingsResponse.data.length > 0 &&
        companyState.findAllCompaniesWithoutPaginationStatus === "successfully" &&
        companyState.findAllCompaniesWithoutPaginationResponse !== undefined &&
        companyState.findAllCompaniesWithoutPaginationResponse.data !== undefined &&
        companyState.findAllCompaniesWithoutPaginationResponse.data.length !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0
    ) {
        requestToConfirmState.findAllUpdateCompanySettingsResponse.data.forEach((company, index) => {
            companyState.findAllCompaniesWithoutPaginationResponse?.data.filter(id => id.id.toString() === company.companyId.toString()).forEach((comp) => {
                const financialAdvisor = financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse?.data.find(financialAdvisor => financialAdvisor.id === comp?.financialAdvisorId)
                companyRows.push(
                    <TableRowComponent key={'company-to-confirm-' + company.id}>
                        <TableCellComponent label={comp?.businessName!} cellType={"row-medium"} alignLabel={"left"} />
                        <TableCellComponent label={financialAdvisor?.name + ' ' + financialAdvisor?.surname} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent content={<RequestToConfirmMenuComponent id={Number(company.id)} />} cellType={"row-regular"} alignLabel={"right"} />
                    </TableRowComponent>
                )
            })

        })
    }

    return (
        <TableComponent>
            <TableHeadComponent>
                <TableCellComponent label={"ragione sociale"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent size='large' label={"commercialista"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
            </TableHeadComponent>
            <TableBody>
                {companyRows}
            </TableBody>
        </TableComponent>
    )
}