import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { EditAccountantDTO } from "../accountant/dto"
import { NewAccountantService } from "../accountant/service"
import { EditFinancialAdvisorDTO, SetAccountantDTO } from "../financialAdvisor/dto"
import { NewFinancialAdvisorService } from "../financialAdvisor/service"
import { NewObjectService } from "../objects/service"
import { EditOperatorDTO } from "../operator/dto"
import { NewOperatorService } from "../operator/service"
import { PromiseStatuses } from "../utils"
import { FindMeResponseDTO } from "./dto"
import { NewAuthService } from "./service"

interface AuthState {
    findMeResponse?: FindMeResponseDTO
    findMeStatus: PromiseStatuses
    findAvatarResponse: string
    findAvatarStatus: PromiseStatuses
    editMeFinancialAdvisorStatus: PromiseStatuses
    editMeFinancialAdvisorRequest: EditFinancialAdvisorDTO
    editMeAccountantStatus: PromiseStatuses
    editMeAccountantRequest: EditAccountantDTO
    setAccountantRequest: SetAccountantDTO
    setAdminAccountantStatus: PromiseStatuses,
    editMeFinancialAdvisorComplete: PromiseStatuses
    financialAdvisorSuccessPopup: boolean
    editMeOperatorStatus: PromiseStatuses
    editMeOperatorRequest: EditOperatorDTO
    editOperatorFindAvatarStatus: PromiseStatuses
    editOperatorAvatar: string
}

const initialState: AuthState = {
    findMeStatus: 'idle',
    findAvatarResponse: '',
    findAvatarStatus: 'idle',
    editMeFinancialAdvisorStatus: 'idle',
    editMeFinancialAdvisorRequest: {},
    editMeAccountantStatus: 'idle',
    editMeAccountantRequest: {},
    setAccountantRequest: { id: 0 },
    setAdminAccountantStatus: "idle",
    editMeFinancialAdvisorComplete: 'idle',
    financialAdvisorSuccessPopup: false,
    editMeOperatorRequest: {},
    editMeOperatorStatus: 'idle',
    editOperatorFindAvatarStatus: 'idle',
    editOperatorAvatar: ''
}

export const findMe = createAsyncThunk(
    'auth/findMe',
    async (_, thunkApi): Promise<FindMeResponseDTO> => {
        const authService = NewAuthService()
        return authService.findMe().catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAvatar = createAsyncThunk(
    'auth/findAvatar',
    async (id: string, thunkApi): Promise<string> => {
        const objectService = NewObjectService()

        return objectService.findFileById(id).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const editMeFinancialAdvisor = createAsyncThunk(
    'auth/editMeFinancialAdvisor',
    async (request: { data: FormData, id: string }, thunkApi): Promise<void> => {
        const financialAdvisorService = NewFinancialAdvisorService()

        return financialAdvisorService.editFinancialAdvisor(request.data, request.id).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const editMeAccountant = createAsyncThunk(
    'auth/editMeAccountant',
    async (request: { id: string, data: FormData }, thunkApi): Promise<void> => {
        const accountantService = NewAccountantService()

        return accountantService.editAccountant(request.id, request.data).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const editMeOperator = createAsyncThunk(
    'auth/editMeOperator',
    async (request: { id: string, data: FormData }, thunkApi): Promise<void> => {
        const operatorService = NewOperatorService()

        return operatorService.editOperator(request.id, request.data).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findOperatorAvatarById = createAsyncThunk(
    'auth/findOperatorAvatarById',
    async (request: string, thunkApi): Promise<string> => {
        const objectService = NewObjectService()

        return objectService.findFileById(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const editMeFinancialAdvisorComplete = createAsyncThunk(
    'financialAdvisor/editMeFinancialAdvisorComplete',
    async (request: { id: string, formData: FormData, setAccountant: SetAccountantDTO }): Promise<void[]> => {
        let promises: Promise<void>[] = []
        const financialAdvisorService = NewFinancialAdvisorService()
        promises.push(financialAdvisorService.editFinancialAdvisor(request.formData, request.id))
        if (request.setAccountant.id !== 0)
            promises.push(financialAdvisorService.setAccountant(request.id, request.setAccountant))
        return Promise.all(promises)
    }
)

const authSlice = createSlice({
    name: 'auth/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findMe.pending, (state) => {
                state.findMeStatus = 'loading'
            })
            .addCase(findMe.fulfilled, (state, action) => {
                state.findMeResponse = action.payload
                state.findMeStatus = 'successfully'
            })
            .addCase(findMe.rejected, (state) => {
                state.findMeStatus = 'failed'
            })
            .addCase(findAvatar.pending, (state) => {
                state.findAvatarStatus = 'loading'
            })
            .addCase(findAvatar.fulfilled, (state, action) => {
                state.findAvatarResponse = action.payload
                state.findAvatarStatus = 'successfully'
            })
            .addCase(findAvatar.rejected, (state) => {
                state.findAvatarStatus = 'failed'
            })
            .addCase(editMeFinancialAdvisor.pending, (state) => {
                state.editMeFinancialAdvisorStatus = 'loading'
            })
            .addCase(editMeFinancialAdvisor.fulfilled, (state, action) => {
                state.editMeFinancialAdvisorStatus = 'successfully'
            })
            .addCase(editMeFinancialAdvisor.rejected, (state) => {
                state.editMeFinancialAdvisorStatus = 'failed'
            })
            .addCase(editMeAccountant.pending, (state) => {
                state.editMeAccountantStatus = 'loading'
            })
            .addCase(editMeAccountant.fulfilled, (state, action) => {
                state.editMeAccountantStatus = 'successfully'
            })
            .addCase(editMeAccountant.rejected, (state) => {
                state.editMeAccountantStatus = 'failed'
            })
            .addCase(editMeFinancialAdvisorComplete.pending, (state) => {
                state.editMeFinancialAdvisorComplete = 'loading'
            })
            .addCase(editMeFinancialAdvisorComplete.fulfilled, (state, action) => {
                state.editMeFinancialAdvisorComplete = 'successfully'
            })
            .addCase(editMeFinancialAdvisorComplete.rejected, (state) => {
                state.editMeFinancialAdvisorComplete = 'failed'
            })
            .addCase(editMeOperator.pending, (state) => {
                state.editMeOperatorStatus = 'loading'
            })
            .addCase(editMeOperator.fulfilled, (state, action) => {
                state.editMeOperatorStatus = 'successfully'
            })
            .addCase(editMeOperator.rejected, (state) => {
                state.editMeOperatorStatus = 'failed'
            })
            .addCase(findOperatorAvatarById.pending, (state) => {
                state.editOperatorFindAvatarStatus = 'loading'
            })
            .addCase(findOperatorAvatarById.fulfilled, (state, action) => {
                state.editOperatorFindAvatarStatus = 'successfully'
                state.editOperatorAvatar = action.payload
            })
            .addCase(findOperatorAvatarById.rejected, (state) => {
                state.editOperatorFindAvatarStatus = 'failed'
            })
    },
    reducers: {
        setEditMeFinancialAdvisorRequestBusinessName: (state, action) => {
            state.editMeFinancialAdvisorRequest.businessName = action.payload
        },
        setEditMeFinancialAdvisorRequestDescription: (state, action) => {
            state.editMeFinancialAdvisorRequest.description = action.payload
        },
        setEditMeFinancialAdvisorRequestAccountantId: (state, action) => {
            state.setAccountantRequest.id = action.payload
        },
        setEditMeFinancialAdvisorRequestName: (state, action) => {
            state.editMeFinancialAdvisorRequest.name = action.payload
        },
        setEditMeFinancialAdvisorRequestPhoneNumber: (state, action) => {
            state.editMeFinancialAdvisorRequest.phoneNumber = action.payload
        },
        setEditMeFinancialAdvisorRequestSurname: (state, action) => {
            state.editMeFinancialAdvisorRequest.surname = action.payload
        },
        setEditMeFinancialAdvisorRequestActivityNotifications: (state, action) => {
            state.editMeFinancialAdvisorRequest.activityNotifications = action.payload
        },
        setEditMeFinancialAdvisorRequestOperationsNotifications: (state, action) => {
            state.editMeFinancialAdvisorRequest.operationsNotifications = action.payload
        },
        setEditMeFinancialAdvisorStatus: (state, action) => {
            state.editMeFinancialAdvisorComplete = action.payload
        },
        setFindMeStatus: (state, action) => {
            state.findMeStatus = action.payload
        },
        setEditMeAccountantName: (state, action) => {
            state.editMeAccountantRequest.name = action.payload
        },
        setEditMeAccountantSurname: (state, action) => {
            state.editMeAccountantRequest.surname = action.payload
        },
        setEditMeAccountantPhoneNumber: (state, action) => {
            state.editMeAccountantRequest.phoneNumber = action.payload
        },
        setEditMeAccountantDescription: (state, action) => {
            state.editMeAccountantRequest.description = action.payload
        },
        setEditMeAccountantRequestActivityNotifications: (state, action) => {
            state.editMeAccountantRequest.activityNotifications = action.payload
        },
        setEditMeAccountantRequestOperationsNotifications: (state, action) => {
            state.editMeAccountantRequest.operationsNotifications = action.payload
        },
        setEditMeAccountantStatus: (state, action) => {
            state.editMeAccountantStatus = action.payload
        },
        setFinancialAdvisorSuccessPopup: (state, action) => {
            state.financialAdvisorSuccessPopup = action.payload
        },
        setEditMeOperatorName: (state, action) => {
            state.editMeOperatorRequest.name = action.payload
        },
        setEditMeOperatorSurname: (state, action) => {
            state.editMeOperatorRequest.surname = action.payload
        },
        setEditMeOperatorPhoneNumber: (state, action) => {
            state.editMeOperatorRequest.phoneNumber = action.payload
        },
        setEditMeOperatorDescription: (state, action) => {
            state.editMeOperatorRequest.description = action.payload
        },
        setEditMeOperatorRequestActivityNotifications: (state, action) => {
            state.editMeOperatorRequest.activityNotifications = action.payload
        },
        setEditMeOperatorRequestOperationsNotifications: (state, action) => {
            state.editMeOperatorRequest.operationsNotifications = action.payload
        },
        setEditMeOperatorStatus: (state, action) => {
            state.editMeOperatorStatus = action.payload
        },
        setEditOperatorFindAvatarStatus: (state, action) => {
            state.editOperatorFindAvatarStatus = action.payload
        }
    },
})

export const {
    setEditMeFinancialAdvisorRequestBusinessName,
    setEditMeFinancialAdvisorRequestDescription,
    setEditMeFinancialAdvisorRequestName,
    setEditMeFinancialAdvisorRequestPhoneNumber,
    setEditMeFinancialAdvisorRequestSurname,
    setEditMeFinancialAdvisorStatus,
    setEditMeFinancialAdvisorRequestAccountantId,
    setFindMeStatus,
    setEditMeAccountantDescription,
    setEditMeAccountantName,
    setEditMeAccountantPhoneNumber,
    setEditMeAccountantStatus,
    setEditMeAccountantSurname,
    setFinancialAdvisorSuccessPopup,
    setEditMeOperatorDescription,
    setEditMeOperatorName,
    setEditMeOperatorPhoneNumber,
    setEditMeOperatorSurname,
    setEditMeOperatorStatus,
    setEditOperatorFindAvatarStatus,
    setEditMeFinancialAdvisorRequestActivityNotifications,
    setEditMeFinancialAdvisorRequestOperationsNotifications,
    setEditMeAccountantRequestActivityNotifications,
    setEditMeAccountantRequestOperationsNotifications,
    setEditMeOperatorRequestActivityNotifications,
    setEditMeOperatorRequestOperationsNotifications
} = authSlice.actions

export default authSlice.reducer