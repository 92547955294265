import { RadioGroup } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { NewAccountantModal } from "../accountant/newAccountantModal";
import { findAccountantsListAvatarsById, findAllAccountant, setFindAllAccountantStatus, setOpenAccountantModal } from "../accountant/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { CheckboxSize } from "../checkbox/dto";
import { EmptyList } from "../emptyList/emptyList";
import { ErrorPopup } from "../errorPopup";
import { findFinancialAdvisor, findFinancialAdvisorAvatarById } from "../financialAdvisor/slice";
import { HeadingComponent } from "../heading";
import { HeadingSize } from "../heading/dto";
import { SearchIcon } from "../icons/search";
import { keycloak } from "../keycloak";
import { OperatorResponseDTO } from "../operator/dto";
import { NewOperatorModal } from "../operator/newOperatorModal";
import { findAllOperators, setFindAllOperatorStatus, setOpenOperatorModal } from "../operator/slice";
import { RadioComponent } from "../radio";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import { SuccessPopup } from "../successPopup";
import { SwitchComponent } from "../switch";
import { TextInput } from "../textInput";
import { PromiseStatuses } from "../utils";
import colors from '../utils/index.module.scss';
import { findOperatorsListAvatarsById, setAccountantToAdd, setContabiliToAdd, setOperatorsToAddList, setSaveRolesStatus, setUnsetContabileStatus, unsetContabile } from "./slice";
import './style.scss';

export function RolesCompanyComponent() {
    const companyState = useAppSelector(state => state.company)
    const accountantState = useAppSelector(state => state.accountant)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const operatorState = useAppSelector(state => state.operator)
    const authState = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()

    let operatorsList: ReactElement[] = []
    let accountantsList: ReactElement[] = []

    const [operatorFilter, setOperatorFilter] = useState<string>('')
    const [accountantStatus, setAccountantStatus] = useState<PromiseStatuses>('idle')
    const [operatorStatus, setOperatorStatus] = useState<PromiseStatuses>('idle')
    const [contabiliList, setContabiliList] = useState<ReactElement[]>([])
    const [contabiliButtons, setContabiliButtons] = useState<ReactElement[]>([])

    useEffect(() => {
        if (accountantState.createAccountantStatus === "successfully") {
            setAccountantStatus("successfully")
        } else if (accountantState.createAccountantStatus === "failed") {
            setAccountantStatus("failed")
        }
    }, [accountantState.createAccountantStatus])

    useEffect(() => {
        if (operatorState.createOperatorStatus === "successfully") {
            setOperatorStatus("successfully")
        } else if (operatorState.createOperatorStatus === "failed") {
            setOperatorStatus("failed")
        }
    }, [operatorState.createOperatorStatus])

    useEffect(() => {
        if (
            companyState.findCompanyStatus === 'successfully' &&
            companyState.settingsTabValue === 2 &&
            companyState.findCompanyResponse !== undefined
        ) {
            dispatch(setOperatorsToAddList({ operatorIds: [] }))
            if (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('accountant'))
                dispatch(findAllOperators({ financialAdvisor: '', operator: '', page: 0, itemsPerPage: 0 }))
            dispatch(findFinancialAdvisor(companyState.findCompanyResponse.financialAdvisorId))
            dispatch(findAllAccountant({ page: 0, itemsPerPage: 0, financialAdvisor: '', accountant: '', allofall: true }))
            let temp: ReactElement[] = []
            if (keycloak.hasRealmRole('admin'))
                temp.push(<ButtonComponent label='Nuovo contabile' onClick={() => dispatch(setOpenAccountantModal(true))} size={Size.MD} color={Colors.PRIMARY} variant={Variant.SOLID} iconStyle={IconStyle.OFF} />)
            if (companyState.findCompanyResponse.contabileId)
                temp.push(<ButtonComponent label='Rimuovi' onClick={() => dispatch(unsetContabile(companyState.findCompanyResponse?.id!))} size={Size.MD} color={Colors.PRIMARY} variant={Variant.SOLID} iconStyle={IconStyle.OFF} />)
            setContabiliButtons(temp)
        }
    }, [companyState.findCompanyStatus, companyState.settingsTabValue])

    useEffect(() => {
        if (
            financialAdvisorState.findFinancialAdvisorStatus === 'successfully' &&
            financialAdvisorState.findFinancialAdvisorResponse !== undefined &&
            companyState.findCompanyStatus === 'successfully' &&
            companyState.settingsTabValue === 2 &&
            companyState.findCompanyResponse !== undefined
        ) {
            if (financialAdvisorState.findFinancialAdvisorResponse.avatarObjectId !== null)
                dispatch(findFinancialAdvisorAvatarById(financialAdvisorState.findFinancialAdvisorResponse.avatarObjectId))

        }
    }, [financialAdvisorState.findFinancialAdvisorStatus, financialAdvisorState.findFinancialAdvisorResponse, companyState.findCompanyResponse, companyState.findCompanyStatus])

    useEffect(() => {
        if (
            companyState.findCompanyResponse !== undefined
        ) {
            dispatch(setAccountantToAdd({ accountantId: companyState.findCompanyResponse.accountantId }))
            dispatch(setContabiliToAdd({ contabileId: companyState.findCompanyResponse.contabileId }))
        }
    }, [companyState.findCompanyResponse])

    useEffect(() => {
        if (companyState.unsetContabileStatus === 'successfully') {
            window.location.reload()
            dispatch(setUnsetContabileStatus('idle'))
        }
    }, [companyState.unsetContabileStatus])

    useEffect(() => {
        if (
            accountantState.findAllAccountantStatus === 'successfully' &&
            accountantState.findAllAccountantResponse !== undefined &&
            accountantState.findAllAccountantResponse.data !== undefined &&
            accountantState.findAllAccountantResponse.data.length > 0 &&
            companyState.settingsTabValue === 2
        ) {
            dispatch(setFindAllAccountantStatus('idle'))
            const avatars = accountantState.findAllAccountantResponse.data.map(accountant => ({ userId: accountant.userId, objectId: accountant.avatarObjectId }))
            dispatch(findAccountantsListAvatarsById(avatars))
        }
    }, [accountantState.findAllAccountantStatus, accountantState.findAllAccountantResponse])

    useEffect(() => {
        if (accountantState.findAllAccountantResponse && companyState.accountantToAdd.accountantId !== '') {
            const accountantsList = accountantState.findAllAccountantResponse.data?.filter(item => item.id.toString() !== companyState.accountantToAdd.accountantId.toString())
            let temp: ReactElement[] = []
            if (!keycloak.hasRealmRole('financial-advisor'))
                accountantsList
                    .forEach((accountant, index) => {
                        const avatar = accountantState.accountantsListAvatarResponse.find(avatar => avatar.userId === accountant.userId)?.objectId
                        temp.push(
                            <div
                                key={'edit-company-accountant-' + accountant.id}
                                className={companyState.contabiliToAdd.contabileId === null ? "company-roles-frame" : accountant.id.toString() === companyState.contabiliToAdd.contabileId.toString() ? "company-roles-frame--active" : "company-roles-frame"}>
                                <AvatarComponent
                                    src={avatar ?? ''}
                                    type={AvatarType.USER}
                                    size={AvatarSize.SM}
                                    name={accountant.name + ' ' + accountant.surname}
                                    subLabel={accountant.email}
                                />
                                <div className=" d-flex gap-1">
                                    <RadioComponent
                                        size={CheckboxSize.SM}
                                        value={accountant.id}
                                        onChange={e => { dispatch(setContabiliToAdd({ contabileId: e.target.value })) }}
                                        disabled={(keycloak.hasRealmRole('accountant') &&
                                            companyState.findCompanyResponse?.contabileId === authState.findMeResponse?.id) || keycloak.hasRealmRole("financial-advisor")
                                        }
                                    />
                                </div>
                            </div>
                        )
                    })
            else {
                let accountant = accountantState.findAllAccountantResponse.data.find(item => item.id === companyState.findCompanyResponse?.contabileId)
                if (accountant)
                    temp.push(<div
                        key={'edit-company-accountant-' + accountant.id}
                        className={companyState.contabiliToAdd.contabileId === null ? "company-roles-frame" : accountant.id.toString() === companyState.contabiliToAdd.contabileId.toString() ? "company-roles-frame--active" : "company-roles-frame"}>
                        <AvatarComponent
                            src={accountant.avatarObjectId ?? ''}
                            type={AvatarType.USER}
                            size={AvatarSize.SM}
                            name={accountant.name + ' ' + accountant.surname}
                            subLabel={accountant.email}
                        />
                    </div>)
            }
            setContabiliList(temp)
        }
    }, [companyState.accountantToAdd.accountantId, accountantState.findAllAccountantStatus])

    useEffect(() => {
        if (
            operatorState.findAllOperatorStatus === 'successfully' &&
            financialAdvisorState.findFinancialAdvisorStatus === 'successfully' &&
            operatorState.findAllOperatorResponse !== undefined &&
            operatorState.findAllOperatorResponse.data !== undefined &&
            operatorState.findAllOperatorResponse.data.length > 0 &&
            companyState.settingsTabValue === 2
        ) {
            let avatars =
                operatorState.findAllOperatorResponse.data.filter(operator =>
                    financialAdvisorState.findFinancialAdvisorResponse?.id !== undefined &&
                    operator.financialAdvisorId.toString() === financialAdvisorState.findFinancialAdvisorResponse?.id.toString()
                ).map(filtredOperator => ({ objectId: filtredOperator.avatarObjectId?.toString() || null, userId: filtredOperator.userId }))

            dispatch(findOperatorsListAvatarsById(avatars))
        }
    }, [operatorState.findAllOperatorStatus, operatorState.findAllOperatorResponse, financialAdvisorState.findFinancialAdvisorStatus])

    useEffect(() => {
        if (
            companyState.findCompanyResponse !== undefined &&
            companyState.findCompanyStatus === 'successfully' &&
            companyState.settingsTabValue === 2 &&
            operatorState.findAllOperatorResponse !== undefined &&
            operatorState.findAllOperatorResponse.data !== undefined &&
            operatorState.findAllOperatorResponse.data.length > 0 &&
            operatorState.findAllOperatorStatus === 'successfully' &&
            companyState.findOperatorsListAvatarsByIdStatus === 'successfully' &&
            financialAdvisorState.findFinancialAdvisorStatus === 'successfully' &&
            financialAdvisorState.findFinancialAdvisorResponse !== undefined
        ) {
            dispatch(setFindAllOperatorStatus('idle'))
            let selectedIndex: number[] = []
            let operators: OperatorResponseDTO[] = []
            if (keycloak.hasRealmRole('accountant')) {
                operatorState.findAllOperatorResponse.data.filter(_operator =>
                    companyState.findCompanyResponse?.operatorIds.forEach(companyOperator => {
                        if (companyOperator.toString() === _operator.id.toString()) {
                            operators.push(_operator)
                        }
                    })
                )
            } else {
                operatorState.findAllOperatorResponse.data
                    .forEach(_operator => {
                        if (financialAdvisorState.findFinancialAdvisorResponse?.id !== undefined &&
                            _operator.financialAdvisorId.toString() === financialAdvisorState.findFinancialAdvisorResponse?.id.toString()) {
                            operators.push(_operator)
                        }
                    }
                    )
            }
            operators
                .forEach(operator => {
                    if (
                        operator.companyIds !== null &&
                        operator.companyIds !== undefined &&
                        operator.companyIds.length > 0 &&
                        companyState.findCompanyResponse !== undefined &&
                        operator.companyIds.map(companyId => companyId.toString()).find(_id => _id === companyState.findCompanyResponse?.id.toString()) !== undefined
                    ) {
                        selectedIndex.push(operator.id)
                    }
                    dispatch(setOperatorsToAddList({ operatorIds: [...selectedIndex] }))
                })
        }
    }, [companyState.findCompanyStatus, companyState.findOperatorsListAvatarsByIdStatus, operatorState.findAllOperatorStatus, financialAdvisorState.findFinancialAdvisorStatus])

    if (
        operatorState.findAllOperatorStatus === 'loading' ||
        operatorState.createOperatorStatus === 'loading' ||
        companyState.findOperatorsListAvatarsByIdStatus === 'loading' ||
        companyState.findCompanyStatus === 'loading' ||
        financialAdvisorState.editFinancialAdvisorFindAvatarStatus === 'loading' ||
        financialAdvisorState.findFinancialAdvisorStatus === 'loading' ||
        accountantState.editAccountantFindAvatarStatus === 'loading' ||
        accountantState.findAllAccountantStatus === 'loading' ||
        accountantState.findAllAccountantsWithoutPaginationStatus === 'loading' ||
        accountantState.findAllAccountantsAvatarStatus === 'loading' ||
        companyState.saveRolesStatus === 'loading'
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    if (
        operatorState.findAllOperatorStatus === 'failed' ||
        companyState.findOperatorsListAvatarsByIdStatus === 'failed' ||
        companyState.findCompanyStatus === 'failed' ||
        financialAdvisorState.editFinancialAdvisorFindAvatarStatus === 'failed' ||
        financialAdvisorState.findFinancialAdvisorStatus === 'failed' ||
        accountantState.editAccountantFindAvatarStatus === 'failed' ||
        accountantState.findAllAccountantStatus === 'failed' ||
        accountantState.findAllAccountantsAvatarStatus === 'failed'

    ) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    if (
        operatorState.findAllOperatorResponse !== undefined &&
        operatorState.findAllOperatorResponse.data !== undefined &&
        companyState.findOperatorsListAvatarsByIdStatus === 'successfully' &&
        companyState.findOperatorsListAvatarsByIdResponse !== undefined &&
        companyState.findOperatorsListAvatarsByIdResponse.length > 0 &&
        companyState.settingsTabValue === 2
    ) {
        let operators: OperatorResponseDTO[] = []
        if (keycloak.hasRealmRole('accountant')) {
            operatorState.findAllOperatorResponse.data.filter(_operator =>
                companyState.findCompanyResponse?.operatorIds.forEach(companyOperator => {
                    if (companyOperator.toString() === _operator.id.toString()) {
                        operators.push(_operator)
                    }
                })
            )
        } else {
            operatorState.findAllOperatorResponse.data
                .forEach(_operator => {
                    if (financialAdvisorState.findFinancialAdvisorResponse?.id !== undefined &&
                        _operator.financialAdvisorId.toString() === financialAdvisorState.findFinancialAdvisorResponse?.id.toString()) {
                        operators.push(_operator)
                    }
                }
                )
        }
        operators
            .forEach((operator, index) => {
                const selected = companyState.operatorsToAddList.operatorIds.find(id => operator.id.toString() === id.toString())
                const avatar = companyState.findOperatorsListAvatarsByIdResponse?.find(avatar => avatar.userId === operator.userId)?.objectId
                operatorsList.push(
                    <div
                        key={'edit-company-operator-' + operator.id}
                        className={selected !== undefined ? "company-roles-frame--active" : "company-roles-frame"}>
                        <AvatarComponent
                            src={avatar ?? ''}
                            type={AvatarType.USER}
                            size={AvatarSize.SM}
                            name={operator.name + ' ' + operator.surname}
                            subLabel={operator.email}
                        />
                        <SwitchComponent
                            id={'edit-company-operator-switch-' + operator.id}
                            checked={selected !== undefined}
                            disabled={keycloak.hasRealmRole('accountant')}
                            onChange={(_, checked) => {
                                if (checked) {
                                    dispatch(setOperatorsToAddList({ operatorIds: companyState.operatorsToAddList.operatorIds.concat(operator.id.valueOf()) }))
                                } else {
                                    dispatch(setOperatorsToAddList({ operatorIds: companyState.operatorsToAddList.operatorIds.filter(selectedOperator => selectedOperator.toString() !== operator.id.toString()) }))
                                }
                            }}
                        />
                    </div>
                )
            })
    }

    if (
        companyState.settingsTabValue === 2 &&
        accountantState.findAllAccountantResponse !== undefined &&
        accountantState.findAllAccountantResponse.data !== undefined &&
        accountantState.findAllAccountantsAvatarStatus === 'successfully' &&
        accountantState.accountantsListAvatarResponse !== undefined &&
        accountantState.accountantsListAvatarResponse.length > 0
    ) {

        const accountants = accountantState.findAllAccountantResponse.data
        if (!keycloak.hasRealmRole('financial-advisor'))
            accountants
                .forEach((accountant, index) => {
                    const avatar = accountantState.accountantsListAvatarResponse.find(avatar => avatar.userId === accountant.userId)?.objectId
                    accountantsList.push(
                        <div
                            key={'edit-company-accountant-' + accountant.id}
                            className={companyState.accountantToAdd.accountantId.toString() === accountant.id.toString() ? "company-roles-frame--active" : "company-roles-frame"}>
                            <AvatarComponent
                                src={avatar ?? ''}
                                type={AvatarType.USER}
                                size={AvatarSize.SM}
                                name={accountant.name + ' ' + accountant.surname}
                                subLabel={accountant.email}
                            />
                            <RadioComponent
                                size={CheckboxSize.SM}
                                value={accountant.id.toString()}
                                onChange={e => dispatch(setAccountantToAdd({ accountantId: e.target.value }))}
                                disabled={(keycloak.hasRealmRole('accountant') &&
                                    authState.findMeResponse?.accountantId?.toString() !== companyState.accountantToAdd.accountantId) || keycloak.hasRealmRole("financial-advisor")
                                }
                            />
                        </div>

                    )
                })
        else {
            let accountant = accountantState.findAllAccountantResponse.data.find(item => item.id === companyState.findCompanyResponse?.accountantId)
            if (accountant)
                accountantsList.push(
                    <div
                        key={'edit-company-accountant-' + accountant.id}
                        className={"company-roles-frame--active"}>
                        <AvatarComponent
                            src={accountant.avatarObjectId ?? ''}
                            type={AvatarType.USER}
                            size={AvatarSize.SM}
                            name={accountant.name + ' ' + accountant.surname}
                            subLabel={accountant.email}
                        />
                    </div>)
        }
    }

    return (
        <div style={{
            marginTop: '16px',
            width: '100%'
        }}>
            <SuccessPopup
                active={companyState.saveRolesStatus === 'successfully'}
                close={() => dispatch(setSaveRolesStatus('idle'))}
                message="Ruoli modificati correttamente"
            />
            <ErrorPopup
                active={companyState.saveRolesStatus === 'failed'}
                close={() => dispatch(setSaveRolesStatus('idle'))}
                message="Si è verificato un errore con la modifica dei ruoli"
            />
            <SuccessPopup
                active={accountantStatus === 'successfully'}
                close={() => setAccountantStatus('idle')}
                message="Contabile creato correttamente"
            />
            <ErrorPopup
                active={accountantStatus === 'failed'}
                close={() => setAccountantStatus('idle')}
                message="Si è verificato un errore con la creazione del contabile"
            />
            <SuccessPopup
                active={operatorStatus === 'successfully'}
                close={() => setOperatorStatus('idle')}
                message="Collaboratore creato correttamente"
            />
            <ErrorPopup
                active={operatorStatus === 'failed'}
                close={() => setOperatorStatus('idle')}
                message="Si è verificato un errore con la creazione del collaboratore"
            />
            <ErrorPopup
                active={companyState.unsetContabileStatus === 'failed'}
                close={() => dispatch(setUnsetContabileStatus('idle'))}
                message="Si è verificato un errore con la rimozione del contabile"
            />
            <div style={{ width: '100%', display: 'flex', gap: 24, justifyContent: 'space-between', height: 650 }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 16 }}>
                    <div style={{ padding: 16, border: '1px solid', borderColor: colors.neutral80, borderRadius: 12, display: "flex", gap: 16, flexDirection: "column" }}>
                        <HeadingComponent size={HeadingSize.SM} label={"Commercialista"} buttons={[]} />
                        <div className="company-roles-frame--active">
                            <AvatarComponent
                                src={financialAdvisorState.editFinancialAdvisorAvatar}
                                type={AvatarType.USER} size={AvatarSize.MD}
                                name={financialAdvisorState.findFinancialAdvisorResponse?.name + ' ' + financialAdvisorState.findFinancialAdvisorResponse?.surname}
                                subLabel={financialAdvisorState.findFinancialAdvisorResponse?.email}
                            />
                        </div>
                    </div>
                    {
                        (
                            keycloak.hasRealmRole('admin') ||
                            keycloak.hasRealmRole('financial-advisor') ||
                            keycloak.hasRealmRole('operator') ||
                            keycloak.hasRealmRole('accountant')
                        ) &&
                        <div
                            style={{ display: 'flex', border: '1px solid', borderColor: colors.neutral80, borderRadius: 12, flexDirection: 'column', padding: 16, gap: 16, height: 'calc(100% - 132px - 16px)' }}
                        >
                            <HeadingComponent size={HeadingSize.SM} label={"Collaboratori"} buttons={keycloak.hasRealmRole('admin') ||
                                keycloak.hasRealmRole('financial-advisor') ? [<ButtonComponent label='Nuovo collaboratore' onClick={() => dispatch(setOpenOperatorModal(true))} size={Size.MD} color={Colors.PRIMARY} variant={Variant.SOLID} iconStyle={IconStyle.OFF} />] : []} />
                            <TextInput id="dashboard-company-filter" type={"text"} placeholder={"Cerca collaboratore"} startIcon={<SearchIcon colorBase={""} />} onChange={e => setOperatorFilter(e.target.value)} defaultValue={operatorFilter} />
                            <div style={{ overflow: 'auto', width: '100%', gap: '8px', display: 'flex', flexDirection: 'column', maxHeight: '100%' }}>
                                {
                                    operatorState.findAllOperatorResponse !== undefined &&
                                        operatorState.findAllOperatorResponse.total === 0 ?
                                        <EmptyList />
                                        :
                                        operatorsList
                                }
                            </div>
                        </div>
                    }
                </div>
                {
                    <>
                        <div style={{ padding: 16, border: '1px solid', borderColor: colors.neutral80, borderRadius: 12, width: "100%", display: "flex", gap: 16, flexDirection: "column" }}>
                            <HeadingComponent size={HeadingSize.SM} label={"Account"} buttons={keycloak.hasRealmRole('admin') ? [<ButtonComponent label='Nuovo contabile' onClick={() => dispatch(setOpenAccountantModal(true))} size={Size.MD} color={Colors.PRIMARY} variant={Variant.SOLID} iconStyle={IconStyle.OFF} />,] : []} />
                            {!keycloak.hasRealmRole('financial-advisor') &&
                                <TextInput id="dashboard-company-filter" type={"text"} placeholder={"Cerca contabile"} startIcon={<SearchIcon colorBase={""} />} />
                            }
                            <div style={{ width: '100%', gap: '8px', display: 'flex', flexDirection: 'column', maxHeight: "100%", overflow: 'auto', }}>
                                <RadioGroup value={companyState.accountantToAdd.accountantId.toString()}>
                                    {accountantsList}
                                </RadioGroup>
                            </div>
                        </div>
                        <div style={{ padding: 16, border: '1px solid', borderColor: colors.neutral80, borderRadius: 12, display: "flex", gap: 16, flexDirection: "column", width: '100%' }}>
                            <HeadingComponent size={HeadingSize.SM} label={"Contabili"} buttons={keycloak.hasRealmRole('admin') ? contabiliButtons : []} />
                            { !keycloak.hasRealmRole('financial-advisor') &&
                                <TextInput id="dashboard-company-filter" type={"text"} placeholder={"Cerca contabile"} startIcon={<SearchIcon colorBase={""} />} />
                            }
                            <div style={{ overflow: 'auto', width: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                                <RadioGroup value={companyState.contabiliToAdd.contabileId}>
                                    {contabiliList.length > 0? contabiliList : <EmptyList />}
                                </RadioGroup>
                            </div>
                        </div>
                    </>
                }
            </div>
            <NewOperatorModal />
            <NewAccountantModal />
        </div >
    )
}