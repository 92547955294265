import { Collapse, List, ListItemButton } from "@mui/material"
import { ReactNode, useState } from "react"
import { CheckboxComponent } from "../checkbox"
import { CheckboxSize, CheckboxStyle } from "../checkbox/dto"
import { CheckIcon } from "../icons/check"
import { ChevronDownIcon } from "../icons/chevronDown"
import { useAppSelector } from "../redux/hooks"
import colors from '../utils/index.module.scss'
import { AccountingSchemeEnum, AnnualForeignInvoicesEnum, AtecosEnum, BankEnum, JournalEnum, PeriodicityEnum, numberToAtecoMap, numberToBankMap, numberToForeignMap, numberToJournalMap } from "./dto"
import { NewCompanyQuotationService } from "./service"

export const journalLabelMap = new Map<JournalEnum, ReactNode>([
    [JournalEnum.JOURNAL_0,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Fino a '}
        <span className="typography--semibold">
            5.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_1,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Da '}
        <span className="typography--semibold">
            5.000 movimenti
        </span>
        {' a '}
        <span className="typography--semibold">
            10.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_2,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Da '}
        <span className="typography--semibold">
            10.000 movimenti
        </span>
        {' a '}
        <span className="typography--semibold">
            15.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_3,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Da '}
        <span className="typography--semibold">
            15.000 movimenti
        </span>
        {' a '}
        <span className="typography--semibold">
            20.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_4,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Da '}
        <span className="typography--semibold">
            20.000 movimenti
        </span>
        {' a '}
        <span className="typography--semibold">
            30.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_5,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Da '}
        <span className="typography--semibold">
            30.000 movimenti
        </span>
        {' a '}
        <span className="typography--semibold">
            50.000 movimenti
        </span>
    </p>
    ],
    [JournalEnum.JOURNAL_6,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Oltre '}
        <span className="typography--semibold">
            50.000 movimenti
        </span>
    </p>
    ]
])

export const banksLabelMap = new Map<BankEnum, ReactNode>([
    [BankEnum.BANK_0,
    <p className="m-0 p-0 text text--lg typography--semibold typography-neutral--500">
        Fino a 2 estratti conto
    </p>
    ],
    [BankEnum.BANK_1,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Da '}
        <span className="typography--semibold">
            2 a 5 estratti conto
        </span>
    </p>
    ],
    [BankEnum.BANK_2,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Più di '}
        <span className="typography--semibold">
            5 estratti conto
        </span>
    </p>
    ]
])

export const foreignLabelMap = new Map<AnnualForeignInvoicesEnum, ReactNode>([
    [AnnualForeignInvoicesEnum.FOREIGN_EMPTY,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Nessuna  '}
        <span className="typography--semibold">
            fattura cartacea
        </span>
    </p>
    ],
    [AnnualForeignInvoicesEnum.FOREIGN_0,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Fino a  '}
        <span className="typography--semibold">
            10 fatture cartacee
        </span>
    </p>
    ],
    [AnnualForeignInvoicesEnum.FOREIGN_1,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Da '}
        <span className="typography--semibold">
            10
        </span>
        {' a '}
        <span className="typography--semibold">
            100 fatture cartacee
        </span>
    </p>
    ],
    [AnnualForeignInvoicesEnum.FOREIGN_2,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Più di '}
        <span className="typography--semibold">
            100 fatture cartacee
        </span>
    </p>
    ]
])

export const atecoLabelMap = new Map<AtecosEnum, ReactNode>([
    [AtecosEnum.ATECOS_0,
    <p className="m-0 p-0 text text--lg typography--semibold typography-neutral--500">
        Fino a 2 sezionali/registri
    </p>
    ],
    [AtecosEnum.ATECOS_1,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        <span className="typography--semibold">
            Da 3 a 4 sezionali/registri
        </span>
    </p>
    ],
    [AtecosEnum.ATECOS_2,
    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
        {'Più di '}
        <span className="typography--semibold">
            4 sezionali/registri
        </span>
    </p>
    ]
])

export function CompanyQuotationSummary() {
    const companyQuotationWizardState = useAppSelector(state => state.companyQuotation)
    const activityState = useAppSelector(state => state.activity)

    let companyToAddList: ReactNode[] = []

    const [open, setOpen] = useState<boolean[]>([true]);
    let totalPrice = 0

    companyQuotationWizardState.companiesToAdd.forEach((company, index) => {
        let companyPrice = NewCompanyQuotationService().calculatePrice(company)
        totalPrice += companyPrice
        let activityCategory: string = ''
        company.activityCategoryIds.forEach((category, _index) => {
            if (activityState.findAllActivityCategoryResponse !== undefined && activityState.findAllActivityCategoryResponse.data !== undefined && activityState.findAllActivityCategoryResponse.data.length > 0) {
                activityState.findAllActivityCategoryResponse.data.forEach(_category => {
                    if (_category.id === category) {
                        let addString = ''
                        if (_index === 0) {
                            addString = ' '
                        } else if (_index === company.activityCategoryIds.length - 1) {
                            addString = ' e '
                        } else {
                            addString = ', '
                        }
                        activityCategory = activityCategory + addString + _category.name
                    }
                })
            }
        })
        companyToAddList.push(
            <div key={'company-' + index} style={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
                <div style={{ width: '100%', height: '1px', backgroundColor: (index === 0 ? colors.neutral80 : 'transparent') }} />
                <div>
                    <ListItemButton onClick={() => handleClick(index, open[index])}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                                <CheckboxComponent size={CheckboxSize.SM} style={CheckboxStyle.SQUARE} checked disabled />
                                <p className="m-0 p-0 text text--lg typography--medium typography-neutral--700">
                                    {'Azienda ' + (index + 1) + ' · Contabilità '}
                                    <span className="typography--bold">
                                        {company.accountingScheme === AccountingSchemeEnum.Ordinary ? 'Ordinaria' : 'Semplificata'}
                                    </span>
                                </p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px', justifyContent: 'flex-end' }}>
                                <span className="text text--lg typography--medium typography-neutral--700">{'€ ' + companyPrice.toLocaleString('it-IT', { useGrouping: true })}</span>
                                {open[index] ? <div style={{ transform: 'rotate(180deg)' }}><ChevronDownIcon colorBase={colors.primary500} /></div> : <ChevronDownIcon colorBase={colors.neutral500} />}
                            </div>
                        </div>
                    </ListItemButton>
                    <Collapse in={open[index]} timeout="auto" unmountOnExit>
                        <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 0px 52px', gap: 8 }}>
                            {
                                company?.accountingScheme === AccountingSchemeEnum.Ordinary &&
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                    <CheckIcon />
                                    {journalLabelMap.get(numberToJournalMap(company.journal))}
                                </div>
                            }
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                <CheckIcon />
                                <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                    {'Periodicità '}
                                    <span className="typography--semibold">
                                        {company.periodicity === PeriodicityEnum.Monthly ? 'Mensile' : 'Trimestrale'}
                                    </span>
                                </p>
                            </div>
                            {
                                company?.accountingScheme === AccountingSchemeEnum.Ordinary &&
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                    <CheckIcon />
                                    {banksLabelMap.get(numberToBankMap(company.banksNumber))}
                                </div>
                            }
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                <CheckIcon />
                                {foreignLabelMap.get(numberToForeignMap(company.annualForeignInvoices))}
                            </div>
                            {company.specialVatSchemes &&
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                    <CheckIcon />
                                    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                        {'Gestione regimi IVA speciali '}
                                        <span className="typography--semibold">
                                        </span>
                                    </p>
                                </div>
                            }
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                <CheckIcon />
                                {atecoLabelMap.get(numberToAtecoMap(company.atecoCodesNumber))}
                            </div>
                            {
                                company?.accountingScheme === AccountingSchemeEnum.Ordinary &&
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                    <CheckIcon />
                                    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                        {'Gestione servizi ' + activityCategory}
                                    </p>
                                </div>
                            }
                            {
                                company.professionistWithFund &&
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                    <CheckIcon />
                                    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                        Professionista con cassa
                                    </p>
                                </div>
                            }
                        </div>
                    </Collapse>
                </div>
                <div style={{ width: '100%', height: '1px', backgroundColor: colors.neutral80 }} />
            </div>
        )
    })

    const handleClick = (index: number, value: boolean) => {
        for (let i = 0; i < companyQuotationWizardState.companiesToAdd.length; i++) {
            setOpen([...open, open[i] = false])
        }
        const newOpen = [...open]
        newOpen[index] = !value
        setOpen(newOpen);
    };

    return (
        <div>
            <List
                sx={{ width: '100%', maxHeight: 500, overflow: 'auto' }}
            >
                {companyToAddList}
            </List>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', padding: '24px 52px 24px 52px' }}>
                <p className="m-0 p-0 text text--lg typography--semibold typography-neutral--700">
                    {'Totale annuale '}
                    <span className="typography--regular typography-neutral--400">
                        {'(+ IVA 22%)'}
                    </span>
                </p>
                <span className="heading heading--xs typography--bold typography-neutral--700">{'€ ' + totalPrice.toLocaleString('it-IT', { useGrouping: true })}</span>
            </div>
            <div style={{ width: '100%', height: '1px', backgroundColor: colors.neutral80 }} />
        </div>
    )
}