import { TableBody } from "@mui/material";
import { ReactElement, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { findAllAccountantWithoutPagination } from "../accountant/slice";
import { findAllActivityCategory } from "../activity/slice";
import { unSeenMessages } from "../company/chatV2/slice";
import { setControlsInitialTab, setOrdinaryControlsActivityCategories, setOrdinaryControlsActivityGroups } from "../company/controls/slice";
import { ActivityGroupStatus, ActivityStatus } from "../company/dto";
import { findAllCompanies, getCompanyUnseenInfoMessages, setInitialTabAccountingValue, setOpenOrdinaryAccountingModal, setOperationsTabValue, setOrdinaryAccountingActivityCategories, setOrdinaryAccountingActivityGroups, setOrdinaryAccountingCompany, setOrdinaryAccountingInitialTab, setOrdinaryAccountingPeriod, setOrdinaryAccountingYear } from "../company/slice";
import { setWithholdingsForAccounting, setWithholdingsPeriod, setWittholdingsCompanyId, setWittholdingsYear } from "../company/withholdings/slice";
import { AccountingSchemeEnum, PeriodicityEnum } from "../companyQuotationWizard/dto";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { keycloak } from "../keycloak";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import { StatusIndicatorComponent } from "../statusIndicator";
import { StatusIndicatorColor, StatusIndicatorIcon, StatusIndicatorSize } from "../statusIndicator/dto";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import { ActivityKind, ActvityGroupDashboard } from "./dto";
import { findActivitiesDashboard, getAllCount } from "./slice";

const monthlyPeriods = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
const quarterlyPeriods = [0, 0, 0, 1, 1, 1, 2, 2, 2, 3, 3, 3]

export function DashboardListComponent() {
    const dashboardState = useAppSelector(state => state.dashboard)
    const companyState = useAppSelector(state => state.company)
    const activityState = useAppSelector(state => state.activity)
    const accountantState = useAppSelector(state => state.accountant)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const operatorState = useAppSelector(state => state.operator)
    const chatState = useAppSelector(state => state.chatV2)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(findAllCompanies(dashboardState.companyFilters))
        if (!keycloak.hasRealmRole('operator')) {
            dispatch(findAllAccountantWithoutPagination())
        }
        dispatch(findAllFinancialAdvisorWithoutPagination())
        dispatch(findAllActivityCategory())
        dispatch(getAllCount(dashboardState.filtersCounter))
    }, [])

    useEffect(() => {
        if (
            companyState.setActivityCompleteStatus === 'successfully' ||
            companyState.setActivityOpenStatus === 'successfully' ||
            companyState.completeGroupStatus === 'successfully'
        ) {
            dispatch(findAllCompanies(dashboardState.companyFilters))
            if (!keycloak.hasRealmRole('operator')) {
                dispatch(findAllAccountantWithoutPagination())
            }
            dispatch(findAllFinancialAdvisorWithoutPagination())
        }
    }, [companyState.setActivityCompleteStatus, companyState.setActivityOpenStatus, companyState.completeGroupStatus])

    useEffect(() => {
        if (
            companyState.findAllCompaniesStatus === 'successfully' &&
            companyState.findAllCompaniesResponse !== undefined
        ) {
            const companiesId = companyState.findAllCompaniesResponse.data.map(company => company.id)
            dispatch(findActivitiesDashboard({ companyIds: companiesId, year: new Date().getFullYear() }))
            dispatch(getCompanyUnseenInfoMessages(companiesId.map(id => id.toString())))
            dispatch(unSeenMessages(companiesId.map(id => id.toString())))
        }
    }, [companyState.findAllCompaniesStatus])

    if (
        companyState.findAllCompaniesStatus === 'loading' ||
        accountantState.findAllAccountantsWithoutPaginationStatus === 'loading' ||
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading' ||
        companyState.setActivityCompleteStatus === 'loading' ||
        companyState.setActivityOpenStatus === 'loading' ||
        companyState.completeGroupStatus === 'loading' ||
        dashboardState.findActivitiesDashboardStatus === 'loading' ||
        dashboardState.getAllCountStatus === "loading"

    ) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <SpinnerComponent size={"small"} />
            </div>
        )
    }

    if (
        companyState.findAllCompaniesStatus === 'failed' ||
        accountantState.findAllAccountantsWithoutPaginationStatus === 'failed' ||
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed'
    ) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    let companiesTestRows: ReactNode[] = []

    const activityGroupsColorsMap = (activityGroups: ActvityGroupDashboard[]): StatusIndicatorColor => {
        if (activityGroups.length === 0) {
            return StatusIndicatorColor.INACTIVE
        }
        let activityStatus = activityGroups.flatMap(group => group.activities).map(activity => activity.state)
        if (activityGroups.some(group => group.state === ActivityGroupStatus.Expired)) {
            return StatusIndicatorColor.ERROR
        } else if (activityGroups.every(group => group.state === ActivityGroupStatus.Completed)) {
            return StatusIndicatorColor.SUCCESS
        }
        else if (activityGroups.some(group => group.state === ActivityGroupStatus.Pending) && activityStatus.includes(ActivityStatus.Completed)) {
            return StatusIndicatorColor.ACTIVE
        }

        return StatusIndicatorColor.INACTIVE
    }

    const activityGroupsControlsColorsMap = (activityGroups: ActvityGroupDashboard[]): StatusIndicatorColor => {
        if (activityGroups.length === 0) {
            return StatusIndicatorColor.INACTIVE
        }
        let activityStatus = activityGroups.flatMap(group => group.activities).map(activity => activity.state)
        if (activityGroups.some(group => group.state === ActivityGroupStatus.Expired) && activityStatus.every(status => status === ActivityStatus.Pending)) {
            return StatusIndicatorColor.ERROR
        } else if (activityGroups.every(group => group.state === ActivityGroupStatus.Completed)) {
            return StatusIndicatorColor.SUCCESS
        }
        else if ((activityGroups.some(group => group.state === ActivityGroupStatus.Pending) || activityGroups.some(group => group.state === ActivityGroupStatus.Expired)) && activityStatus.includes(ActivityStatus.Completed)) {
            return StatusIndicatorColor.ACTIVE
        }

        return StatusIndicatorColor.INACTIVE
    }

    const combineColor = (colors: StatusIndicatorColor[]): StatusIndicatorColor => {
        if (colors.some(color => color === StatusIndicatorColor.ERROR)) {
            return StatusIndicatorColor.ERROR
        } else if (colors.every(color => color === StatusIndicatorColor.SUCCESS)) {
            return StatusIndicatorColor.SUCCESS
        } else if (colors.every(color => color === StatusIndicatorColor.INACTIVE)) {
            return StatusIndicatorColor.INACTIVE
        } else if (colors.some(color => color === StatusIndicatorColor.ACTIVE)) {
            return StatusIndicatorColor.ACTIVE
        } else if (colors.some(color => color === StatusIndicatorColor.SUCCESS) && !colors.every(color => color === StatusIndicatorColor.SUCCESS)) {
            return StatusIndicatorColor.ACTIVE
        } else return StatusIndicatorColor.INACTIVE
    }

    if (
        (!keycloak.hasRealmRole('operator') &&
            companyState.findAllCompaniesStatus === 'successfully' &&
            companyState.findAllCompaniesResponse !== undefined &&
            companyState.findAllCompaniesResponse.data !== undefined &&
            companyState.findAllCompaniesResponse.data.length > 0 &&
            accountantState.findAllAccountantsWithoutPaginationStatus === 'successfully' &&
            accountantState.findAllAccountantsWithoutPaginationResponse !== undefined &&
            accountantState.findAllAccountantsWithoutPaginationResponse.data !== undefined &&
            accountantState.findAllAccountantsWithoutPaginationResponse.data.length > 0 &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0 &&
            dashboardState.findActivitiesDashboardStatus === 'successfully'
        ) ||
        (keycloak.hasRealmRole('operator') &&
            companyState.findAllCompaniesStatus === 'successfully' &&
            companyState.findAllCompaniesResponse !== undefined &&
            companyState.findAllCompaniesResponse.data !== undefined &&
            companyState.findAllCompaniesResponse.data.length > 0 &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0 &&
            dashboardState.findActivitiesDashboardStatus === 'successfully'
        )
    ) {
        companyState.findAllCompaniesResponse.data.forEach(company => {
            const activitiesForDashboard = dashboardState.findActivitiesDashboardResponse.find(response => response.id === company.id)
            let categories: { id: number, name: string }[] = []

            company.activityCategoryIds?.forEach(category => {
                const _category = activityState.findAllActivityCategoryResponse?.data.find(categoryResponse => categoryResponse.id === category)
                if (_category !== undefined)
                    categories.push(_category)
            })

            const financialAdvisor = financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse?.data.find(financialAdvisor => financialAdvisor.id === company.financialAdvisorId)
            let operators: string[] = []
            company.operatorIds.forEach(operator => {
                const selectedOperator = operatorState.findAllOperatorResponse?.data.find(op => op.id.toString() === operator.toString())
                if (selectedOperator !== undefined) {
                    operators.push(selectedOperator.name + ' ' + selectedOperator.surname)
                }
            })
            let operatorLabel: string = '-'
            if (operators.length === 1) {
                operatorLabel = operators[0]
            } else if (operators.length > 1) {
                operatorLabel = operators[0] + ', +' + (operators.length - 1)
            }

            const companyPeriods = company.periodicity === PeriodicityEnum.Monthly ? monthlyPeriods : quarterlyPeriods

            let pills: ReactElement[] = []

            companyPeriods.forEach((period, index) => {
                const registersColor = activityGroupsColorsMap(activitiesForDashboard?.activityGroups.filter(group => group.period === period && group.activityKind === ActivityKind.Records) || [])
                const controlsColor = activityGroupsControlsColorsMap(activitiesForDashboard?.activityGroups.filter(group => group.period === period && group.activityKind === ActivityKind.Controls) || [])
                pills.push(
                    <TableCellComponent
                        key={'activity-pill-company-' + company.id + '-' + index}
                        content={
                            <StatusIndicatorComponent
                                isButton
                                onClick={() => {
                                    dispatch(setInitialTabAccountingValue(0))
                                    dispatch(setOrdinaryAccountingCompany(company))
                                    dispatch(setWittholdingsCompanyId(company.id))
                                    dispatch(setOrdinaryAccountingActivityCategories(categories))
                                    dispatch(setOrdinaryControlsActivityCategories(categories))
                                    dispatch(setWithholdingsForAccounting(activitiesForDashboard?.withholdings.filter(withholding => withholding.period === period)))
                                    dispatch(setOrdinaryAccountingActivityGroups(activitiesForDashboard?.activityGroups.filter(group => group.period === period) || []))
                                    dispatch(setOrdinaryControlsActivityGroups(activitiesForDashboard?.activityGroups.filter(group => group.period === period) || []))
                                    dispatch(setOrdinaryAccountingPeriod(period))
                                    dispatch(setWithholdingsPeriod(period))
                                    dispatch(setOrdinaryAccountingYear(new Date().getFullYear()))
                                    dispatch(setWittholdingsYear(new Date().getFullYear()))
                                    dispatch(setOrdinaryAccountingInitialTab(company.activityCategoryIds !== null ? company.activityCategoryIds[0] : 0));
                                    dispatch(setOpenOrdinaryAccountingModal(true));
                                    dispatch(setControlsInitialTab(company.activityCategoryIds !== null ? company.activityCategoryIds[0] : 0));
                                }}
                                icon={StatusIndicatorIcon.ONLY}
                                size={StatusIndicatorSize.LG}
                                pulsing={
                                    // combineColor([registersColor, withholdingsColor, controlsColor]) === StatusIndicatorColor.ACTIVE && (
                                    controlsColor === StatusIndicatorColor.ACTIVE && (
                                        keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')
                                    )
                                    // )
                                }
                                color={
                                    combineColor([registersColor, controlsColor])
                                }
                            />
                        } cellType={"row-regular"} alignLabel={"center"}
                    />
                )
            }
            )

            const documentsUnseen = companyState.companiesUnseenDocumentsResponse.find(document => document.companyId === company.id)?.unseenRecived
            const messagesUnseen = companyState.companiesUnseenMessagesResponse.find(document => document.topic.toString() === company.id.toString())?.unseen
            const groupMessagesUnseen = chatState.unSeenMessagesResponse?.find(mess => mess.topic.toString() === company.id.toString())?.unseen
            const accountant = accountantState.findAllAccountantsWithoutPaginationResponse?.data.find(accountant => accountant.id === company.accountantId)
            companiesTestRows.push(
                <TableRowComponent key={'dashboard-row-' + company.id}>
                    {
                        (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('accountant')) &&
                        (
                            <TableCellComponent label={financialAdvisor?.name + ' ' + financialAdvisor?.surname} cellType={"row-medium"} alignLabel={"left"} />
                        )
                    }
                    <TableCellComponent
                        label={company.businessName !== null ? company.businessName : ''}
                        content={
                            ((documentsUnseen !== undefined && documentsUnseen > 0) || (messagesUnseen !== undefined && messagesUnseen > 0) || (groupMessagesUnseen !== undefined && groupMessagesUnseen > 0)) ? (
                                <StatusIndicatorComponent key={company.id + 'notifications'} icon={StatusIndicatorIcon.ONLY} color={StatusIndicatorColor.PRIMARY} size={StatusIndicatorSize.SM} pulsing />
                            ) : (
                                ''
                            )
                        }
                        clickable onClick={() => {
                            navigate('/company/operation/' + company.id.toString() + '/activity')
                            dispatch(setOperationsTabValue(0))
                        }}
                        cellType={"row-bold"}
                        alignLabel={"left"}
                    />
                    {
                        (keycloak.hasRealmRole('accountant') || keycloak.hasRealmRole('admin')) &&
                        (
                            <TableCellComponent label={accountant?.name + ' ' + accountant?.surname} cellType={"row-bold"} alignLabel={"left"} />
                        )
                    }
                    {
                        keycloak.hasRealmRole('financial-advisor') &&
                        (
                            <TableCellComponent label={operatorLabel} cellType={"row-bold"} alignLabel={"left"} />
                        )
                    }
                    <TableCellComponent label={company.periodicity === PeriodicityEnum.Monthly ? 'Mensile' : 'Trimestrale'} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={company.accountingScheme === AccountingSchemeEnum.Ordinary ? 'Ordinario' : 'Sempificato'} cellType={"row-regular"} alignLabel={"left"} />
                    {pills}
                </TableRowComponent>
            )
        })
    }

    return (
        <TableComponent>
            <TableHeadComponent>
                {
                    (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('accountant')) &&
                    (
                        <TableCellComponent label={"commercialista"} cellType={"head"} alignLabel={"left"} />
                    )
                }
                <TableCellComponent label={"aziende"} size="large" cellType={"head"} alignLabel={"left"} />
                {
                    (keycloak.hasRealmRole('accountant') || keycloak.hasRealmRole('admin')) &&
                    (
                        <TableCellComponent label={"account"} cellType={"head"} alignLabel={"left"} />
                    )
                }
                {
                    keycloak.hasRealmRole('financial-advisor') &&
                    (
                        <TableCellComponent label={"collaboratore"} cellType={"head"} alignLabel={"left"} />
                    )
                }
                <TableCellComponent label={"periodicità"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent label={"regime"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent label={"gen"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent label={"feb"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent label={"mar"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent label={"apr"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent label={"mag"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent label={"giu"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent label={"lug"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent label={"ago"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent label={"set"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent label={"ott"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent label={"nov"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent label={"dec"} cellType={"head"} alignLabel={"center"} />
            </TableHeadComponent>
            <TableBody>
                {companiesTestRows}
            </TableBody>
        </TableComponent>
    )
}