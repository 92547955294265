import { MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { ModalComponent } from "../modal";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { TextInput } from "../textInput";
import colors from '../utils/index.module.scss';
import { ActivityKind } from "./dto";
import { createActivityType, findAllActivityTypeWithoutPagination, resetNewActivityTypeRequest, setNewActivityModalOpen, setNewActivityTypeActivityCategoryId, setNewActivityTypeActivityKind, setNewActivityTypeName } from "./slice";
import { setOpenAccountantModal, resetCreateAccountantRequest } from "../accountant/slice";
import { CloseIcon } from "../icons/close";

export function NewActivityModal() {
    const activityState = useAppSelector(state => state.activity)

    const dispatch = useAppDispatch()

    let activityCategoryMenuItems: ReactNode[] = []

    useEffect(() => {
        if (activityState.createActivityTypeStatus === 'successfully') {
            dispatch(setNewActivityModalOpen(false))
            dispatch(findAllActivityTypeWithoutPagination({ name: '', activityCategoryId: '', page: 0, itemsPerPage: 0 }))
            dispatch(resetNewActivityTypeRequest())
        }
    }, [activityState.createActivityTypeStatus])

    if (
        activityState.findAllActivityCategoryResponse !== undefined &&
        activityState.findAllActivityCategoryResponse.data !== undefined &&
        activityState.findAllActivityCategoryResponse.data.length > 0
    ) {
        activityState.findAllActivityCategoryResponse.data.forEach(category => {
            activityCategoryMenuItems.push(
                <MenuItem key={'new-activity-type-category-' + category.id} value={category.id}>{category.name}</MenuItem>
            )
        })
    }

    return (
        <ModalComponent
            open={activityState.newActivityModalOpen}
            handleClose={() => {
                dispatch(setNewActivityModalOpen(false));
                dispatch(resetNewActivityTypeRequest())
            }}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <span className="text text--lg typography--semibold typography--black">Aggiungi attività</span>
                    <ButtonComponent label={""} onClick={() => {
                                             dispatch(setNewActivityModalOpen(false));
                                             dispatch(resetNewActivityTypeRequest())
                                        }} color={Colors.NEUTRAL} variant={Variant.LINK} size={Size.SM} iconStyle={IconStyle.ONLY} icon={<CloseIcon colorBase={""} />} />
                </div>
                <div className="d-flex flex-column align-items-start justify-content-between" style={{ gap: '16px', width: '100%' }}>
                    <TextInput
                        id={"create-activity-type-name"}
                        type={"text"}
                        required
                        defaultValue={activityState.createActivityTypeRequest.name}
                        placeholder={"Nome"}
                        onChange={(e) => dispatch(setNewActivityTypeName(e.target.value))}
                    />
                    <SelectComponent
                        required
                        id={"create-activity-type-activity-category-select"}
                        defaultValue={activityState.createActivityTypeRequest.activityCategoryId.toString()}
                        menuItems={[
                            <MenuItem
                                key={'select-activity-category-0'}
                                value='0'>Seleziona la categoria...
                            </MenuItem>,
                            activityCategoryMenuItems]}
                        label="Categoria"
                        onChange={(e) => dispatch(setNewActivityTypeActivityCategoryId(e.target.value))}
                    />
                    <SelectComponent
                        required
                        id={"create-activity-type-activity-kind-select"}
                        defaultValue={activityState.createActivityTypeRequest.activityKind.toString()}
                        menuItems={[
                            <MenuItem
                                key={'select-activity-kind-0'}
                                value=""
                            >
                                Seleziona la tipologia...
                            </MenuItem>,
                            <MenuItem
                                key={'select-activity-kind-1'}
                                value={ActivityKind.Records}
                            >
                                Registrazioni
                            </MenuItem>,
                            <MenuItem
                                key={'select-activity-kind-2'}
                                value={ActivityKind.Controls}
                            >
                                Controlli
                            </MenuItem>
                        ]}
                        label="Tipologia"
                        onChange={(e) => dispatch(setNewActivityTypeActivityKind(e.target.value))}
                    />
                </div>
                <div style={{ margin: 0, padding: 0, gap: '12px' }} className="row">
                    <div className="col" style={{ margin: 0, padding: 0 }}>
                        <ButtonComponent
                            label={"Annulla"}
                            onClick={() => {
                                dispatch(setNewActivityModalOpen(false));
                                dispatch(resetNewActivityTypeRequest())
                            }}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.FIT}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div className="col" style={{ margin: 0, padding: 0 }}>
                        <ButtonComponent
                            label={"Avvia procedura"}
                            disabled={activityState.createActivityTypeRequest.name === '' || activityState.createActivityTypeRequest.activityCategoryId.toString() === '0' || activityState.createActivityTypeRequest.activityKind.toString() === ""}
                            onClick={() => {
                                dispatch(createActivityType(activityState.createActivityTypeRequest))
                            }
                            } color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.FIT} iconStyle={IconStyle.OFF}
                        />
                    </div>
                </div>
            </div>
        </ModalComponent >
    )
}