import { MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { findAllAccountant } from "../accountant/slice";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { SearchIcon } from "../icons/search";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import { findAllFinancialAdvisor, setAccountantFilterFa, setFinancialAdvisorFilterFa } from "./slice";

export function FinancialAdvisorFiltersComponent() {
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const accountantState = useAppSelector(state => state.accountant)

    const dispatch = useAppDispatch()

    let adminAccountantMenuItems: ReactNode[] = []

    useEffect(() => {
        dispatch(findAllAccountant({...accountantState.findAllAccountantFilters, accountant: '', financialAdvisor: ''}))
    }, [])

    if (accountantState.findAllAccountantStatus === 'loading') {
        return (<div> <SpinnerComponent size={"small"} /></div>)
    }

    if (accountantState.findAllAccountantStatus === 'failed') {
        return (<div>Si è verificato un errore</div>)
    }

    if (accountantState.findAllAccountantStatus === 'successfully' && accountantState.findAllAccountantResponse !== undefined && accountantState.findAllAccountantResponse.data !== undefined && accountantState.findAllAccountantResponse.data.length > 0) {
        accountantState.findAllAccountantResponse.data.slice().sort((a, b) => a.surname.localeCompare(b.surname)).forEach(accountant => {
            adminAccountantMenuItems.push(
                <MenuItem key={accountant.id} value={accountant.id}>
                    {accountant.surname + ' ' + accountant.name}
                </MenuItem>
            )
        })
    }

    return (
        <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
            <div className="col-12 col-lg-5 p-0 m-0">
                <TextInput
                    key={"financial-advisor-financial-advisor-filter"}
                    id="financial-advisor-financial-advisor-filter"
                    type={"text"}
                    placeholder={"Cerca un commercialista"}
                    startIcon={<SearchIcon colorBase={""} />}
                    onChange={(e) => dispatch(setFinancialAdvisorFilterFa(e.target.value))}
                    value={financialAdvisorState.findAllFinancialAdvisorFilters.financialAdvisor}
                />
            </div>
            <div className="col-12 col-lg p-0 m-0">
                <SelectComponent
                    value={financialAdvisorState.findAllFinancialAdvisorFilters.accountant}
                    key={"financial-advisor-accountant-filter"}
                    id={"financial-advisor-accountant-filter"}
                    onChange={(e) => {
                        dispatch(setAccountantFilterFa(e.target.value))
                        dispatch(findAllFinancialAdvisor({
                            financialAdvisor: financialAdvisorState.findAllFinancialAdvisorFilters.financialAdvisor,
                            page: financialAdvisorState.findAllFinancialAdvisorFilters.page,
                            itemsPerPage: financialAdvisorState.findAllFinancialAdvisorFilters.itemsPerPage,
                            accountant: e.target.value,
                            allofall: false
                        }))
                    }}
                    menuItems={[<MenuItem key='' value=''>Contabili</MenuItem>, adminAccountantMenuItems]} />
            </div>
            <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="row p-0 m-0" style={{ gap: '8px' }}>
                    <div className="col p-0 m-0">
                        <ButtonComponent
                            label={"Pulisci"}
                            onClick={() => {
                                dispatch(setFinancialAdvisorFilterFa(''))
                                dispatch(setAccountantFilterFa(''))
                                dispatch(findAllFinancialAdvisor({ page: financialAdvisorState.findAllFinancialAdvisorFilters.page, itemsPerPage: financialAdvisorState.findAllFinancialAdvisorFilters.itemsPerPage, accountant: '', financialAdvisor: '', allofall: false }))
                            }}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.MD}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div className="col p-0 m-0">
                        <ButtonComponent label={"Applica"} onClick={() => dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))} color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                    </div>
                </div>
            </div>
        </div>
    )
}