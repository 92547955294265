import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../utils"
import { AccountingSchemeEnum, AtecosEnum, BankEnum, CompanyCreationDTO, PeriodicityEnum, atecoToNumberMap, bankToNumberMap } from "./dto"
import { NewCompanyQuotationService } from "./service"

interface CompanyQuotationState {
    step: number
    index: number
    openCompanyQuotationWizardModal: boolean
    companiesToAdd: CompanyCreationDTO[]
    saveCompaniesStatus: PromiseStatuses
}

const initialState: CompanyQuotationState = {
    step: 0,
    index: 0,
    openCompanyQuotationWizardModal: false,
    companiesToAdd: [
        {
            accountingScheme: AccountingSchemeEnum.Ordinary,
            periodicity: PeriodicityEnum.Monthly,
            annualForeignInvoices: 0,
            atecoCodesNumber: atecoToNumberMap.get(AtecosEnum.ATECOS_0) || 0,
            banksNumber: bankToNumberMap.get(BankEnum.BANK_0) || 0,
            journal: 2500,
            specialVatSchemes: false,
            financialAdvisorId: 0,
            activityCategoryIds: [],
            professionistWithFund: false,
            specialVatScheme: null,
            activityNotifications: false,
            operationsNotifications: false,
            accountantId: 0,
            ivaStartMonth: '',
            ivaStartYear : '',
            cogeStartMonth: '',
            cogeStartYear : '',
            //startYear: new Date().getFullYear()
        }
    ],
    saveCompaniesStatus: 'idle'
}

export const createCompany = createAsyncThunk(
    'companyQuotation/createCompany',
    async (request: CompanyCreationDTO[], thunkApi): Promise<void[]> => {
        let promises: Promise<void>[] = []

        promises = request.map(async (company) => {
            const companyQuotationService = NewCompanyQuotationService()

            return companyQuotationService.saveCompany(company)
        })

        return Promise.all(promises)
    },
)

const companyQuotationSlice = createSlice({
    name: 'companyQuotation/slice',
    initialState,
    reducers: {
        setCompanyQuotationWizardStep: (state, action) => {
            state.step = action.payload
        },
        setCompanyQuotationWizardIndex: (state, action) => {
            state.index = action.payload
        },
        setOpenCompanyQuotationWizardModal: (state, action) => {
            state.openCompanyQuotationWizardModal = action.payload
        },
        setCompanyQuotationWizardCreationDTO: (state, action: PayloadAction<CompanyCreationDTO[]>) => {
            state.companiesToAdd = action.payload
        },
        setCompanyQuotationCreateCompaniesStatus: (state, action) => {
            state.saveCompaniesStatus = action.payload
        },
        resetCompanyQuotationWizardCreationDTO: (state) => {
            state.companiesToAdd = [
                {
                    accountingScheme: AccountingSchemeEnum.Ordinary,
                    periodicity: PeriodicityEnum.Monthly,
                    annualForeignInvoices: 0,
                    atecoCodesNumber: atecoToNumberMap.get(AtecosEnum.ATECOS_0) || 0,
                    banksNumber: bankToNumberMap.get(BankEnum.BANK_0) || 0,
                    journal: 2500,
                    specialVatSchemes: false,
                    financialAdvisorId: 0,
                    professionistWithFund: false,
                    activityCategoryIds: [],
                    specialVatScheme: null,
                    activityNotifications: false,
                    operationsNotifications: false,
                    accountantId: 0,
                    ivaStartMonth: '',
                    cogeStartMonth: '',
                    ivaStartYear: '',
                    cogeStartYear: '',
                    //startYear: new Date().getFullYear()
                }
            ]
        }
    },
    extraReducers(builder) {
        builder
            .addCase(createCompany.pending, (state) => {
                state.saveCompaniesStatus = 'loading'
            })
            .addCase(createCompany.fulfilled, (state) => {
                state.saveCompaniesStatus = 'successfully'
            })
            .addCase(createCompany.rejected, (state) => {
                state.saveCompaniesStatus = 'failed'
            })
    },
})

export const {
    setCompanyQuotationWizardStep,
    setCompanyQuotationWizardIndex,
    setOpenCompanyQuotationWizardModal,
    resetCompanyQuotationWizardCreationDTO,
    setCompanyQuotationWizardCreationDTO,
    setCompanyQuotationCreateCompaniesStatus
} = companyQuotationSlice.actions

export default companyQuotationSlice.reducer