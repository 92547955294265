import { useEffect, useState } from "react";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { FileInputComponent } from "../fileInput";
import { FormGroup } from "../formGroup";
import { MailIcon } from "../icons/mail";
import { SmartphoneIcon } from "../icons/smartphone";
import { ModalComponent } from "../modal";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";
import colors from '../utils/index.module.scss';
import { createAccountant, findAllAccountant, resetCreateAccountantRequest, resetErrorCreateAccountantEmail, setCreateAccountantDescription, setCreateAccountantEmail, setCreateAccountantName, setCreateAccountantPhoneNumber, setCreateAccountantStatus, setCreateAccountantSurname, setOpenAccountantModal } from "./slice";
import { setOpenFinancialAdvisorModal, resetCreateFinancialAdvisorRequest } from "../financialAdvisor/slice";
import { CloseIcon } from "../icons/close";

export function NewAccountantModal() {
    const accountantState = useAppSelector(state => state.accountant)

    const dispatch = useAppDispatch()

    let formData = new FormData();

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    useEffect(() => {
        if (accountantState.openAccountantModal) {
            setFile(null)
            dispatch(resetErrorCreateAccountantEmail())
            setAvatarToBase64('')
        }
    }, [accountantState.openAccountantModal])

    useEffect(() => {
        if (accountantState.createAccountantStatus === 'successfully') {
            dispatch(setCreateAccountantStatus('idle'))
            if (window.location.pathname.includes('company/operation')) {
                dispatch(findAllAccountant({ page: 0, itemsPerPage: 0, financialAdvisor: '', accountant: '', allofall: false }))
            } else {
                dispatch(findAllAccountant(accountantState.findAllAccountantFilters))
            }
            dispatch(resetCreateAccountantRequest())
            dispatch(setOpenAccountantModal(false))
        }
    }, [accountantState.createAccountantStatus])

    return (
        <ModalComponent
            open={accountantState.openAccountantModal}
            handleClose={() => {
                dispatch(setOpenAccountantModal(false));
                dispatch(resetCreateAccountantRequest())
            }}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >

                <div className="d-flex align-items-center justify-content-between">
                    <span className="text text--lg typography--semibold typography--black">Aggiungi contabile</span>
                    <ButtonComponent label={""} onClick={() => {
                        dispatch(setOpenAccountantModal(false));
                        dispatch(resetCreateAccountantRequest())
                    }} color={Colors.NEUTRAL} variant={Variant.LINK} size={Size.SM} iconStyle={IconStyle.ONLY} icon={<CloseIcon colorBase={""} />} />
                </div>
                <div className="d-flex flex-column align-items-start justify-content-between" style={{ gap: '16px', width: '100%' }}>
                    <div className="w-100 d-flex gap-2">
                        <AvatarComponent
                            src={avatarToBase64}
                            type={AvatarType.SINGLE}
                            size={AvatarSize.MD}
                        />
                        <FileInputComponent
                            id={"create-accountant-avatar"}
                            onChange={e => { setFile(e[0]); toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data)) }}
                        />
                    </div>
                    <TextInput
                        id={"create-accountant-name"}
                        required
                        type={"text"}
                        defaultValue={accountantState.createAccountantRequest.name}
                        placeholder={"Nome"}
                        onChange={(e) => dispatch(setCreateAccountantName(e.target.value))}
                    />
                    <TextInput
                        id={"create-accountant-surname"}
                        required
                        type={"text"}
                        defaultValue={accountantState.createAccountantRequest.surname}
                        placeholder={"Cognome"}
                        onChange={(e) => dispatch(setCreateAccountantSurname(e.target.value))}
                    />
                    <TextInput
                        startIcon={<MailIcon colorBase="" />}
                        required
                        id={"create-accountant-email"}
                        type={"text"}
                        placeholder={"Email"}
                        onFocus={e => dispatch(resetErrorCreateAccountantEmail())}
                        infoText={accountantState.errors.createAccountant.email !== undefined ? 'Questo indirizzo email è già in uso' : undefined}
                        error={accountantState.errors.createAccountant.email !== undefined}
                        defaultValue={accountantState.createAccountantRequest.email}
                        onChange={(e) => dispatch(setCreateAccountantEmail(e.target.value))}
                    />
                    <TextInput
                        startIcon={<SmartphoneIcon colorBase="" />}
                        infoText="Inserire da 9 a 10 caratteri"
                        defaultValue={accountantState.createAccountantRequest.phoneNumber}
                        id={"create-accountant-phone"}
                        type={"text"}
                        placeholder={"Telefono"}
                        onChange={(e) => {
                            dispatch(setCreateAccountantPhoneNumber(e.target.value === '' ? null : e.target.value))
                        }} />
                    <FormGroup
                        label={"Note"}
                        inputs={[
                            <TextInput
                                id={"create-accountant-note"}
                                type={"text"}
                                placeholder={"Aggiungi qui una descrizione"}
                                multiline
                                onChange={(e) => dispatch(setCreateAccountantDescription(e.target.value))}
                            />
                        ]}
                        style={"column"}
                    />
                </div>
                <div style={{ margin: 0, padding: 0, gap: '12px' }} className="row">
                    <div
                        className="col"
                        style={{ margin: 0, padding: 0 }}
                    >
                        <ButtonComponent
                            label={"Annulla"}
                            onClick={() => {
                                dispatch(setOpenAccountantModal(false));
                                dispatch(resetCreateAccountantRequest())
                            }}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.FIT}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div className="col" style={{ margin: 0, padding: 0 }}>
                        <ButtonComponent
                            label={"Avvia procedura"}
                            onClick={() => {
                                if (file !== null)
                                    formData.append('file', file)
                                formData.append('accountantCreationDTO', JSON.stringify(accountantState.createAccountantRequest))
                                dispatch(createAccountant(formData))
                            }}
                            color={Colors.PRIMARY}
                            variant={Variant.SOLID}
                            size={Size.FIT}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                </div>
            </div>
        </ModalComponent >
    )
}