import { FindAllUpdateCompanySettings, FindAllUpdateCompanySettingsFilters, UpdateCompanySettingsConfirmDTO, UpdateCompanySettingsCreationDTO, UpdateCompanySettingsDTO } from "./dto";
import { RequestToConfirmServiceImpl } from "./serviceImpl";


export interface RequestToConfirmService {
    findAllRequestToConfirm(request: FindAllUpdateCompanySettingsFilters): Promise<FindAllUpdateCompanySettings>
    updateCompanySettings(request: UpdateCompanySettingsCreationDTO): Promise<void>
    findRequestToConfirm(id: string): Promise<UpdateCompanySettingsDTO>
    confirmOrDenyRequest(id: string, data: UpdateCompanySettingsConfirmDTO): Promise<void>
}

export function NewRequestToConfirmService(): RequestToConfirmService {
    return new RequestToConfirmServiceImpl();
}
