import axios from "axios";
import { keycloak } from "../keycloak";
import { FindAllUpdateCompanySettings, FindAllUpdateCompanySettingsFilters, UpdateCompanySettingsConfirmDTO, UpdateCompanySettingsCreationDTO, UpdateCompanySettingsDTO } from "./dto";
import { RequestToConfirmService } from "./service";


export class RequestToConfirmServiceImpl implements RequestToConfirmService {

    public findAllRequestToConfirm(request: FindAllUpdateCompanySettingsFilters): Promise<FindAllUpdateCompanySettings> {
        let url: string = "/api/update-company-settings?" +
            "page=" + request.page +
            "&itemsPerPage=" + request.itemsPerPage +
            (request.companyName !== '' ? "&businessName=" + request.companyName : '') +
            (request.status !== '' ? "&status=" + request.status : '') +
            (request.financialAdvisor !== '' ? "&financialAdvisor=" + request.financialAdvisor : '')
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public updateCompanySettings(request: UpdateCompanySettingsCreationDTO): Promise<void> {
        let url: string = "/api/update-company-settings"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findRequestToConfirm(id: string): Promise<UpdateCompanySettingsDTO> {
        let url: string = "/api/update-company-settings/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public confirmOrDenyRequest(id: string, data: UpdateCompanySettingsConfirmDTO): Promise<void> {
        let url: string = "/api/update-company-settings/" + id + "/change-request-approval"
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
