import { Collapse, ListItemButton, MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { findAccountantsListAvatarsById, findAllAccountantWithoutPagination } from "../accountant/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { CheckboxComponent } from "../checkbox";
import { CheckboxSize, CheckboxStyle } from "../checkbox/dto";
import { findCompanyById } from "../company/slice";
import { AccountingSchemeEnum, PeriodicityEnum, numberToAtecoMap, numberToBankMap, numberToForeignMap, numberToJournalMap } from "../companyQuotationWizard/dto";
import { atecoLabelMap, banksLabelMap, foreignLabelMap, journalLabelMap } from "../companyQuotationWizard/summary";
import { FormGroup } from "../formGroup";
import { CheckIcon } from "../icons/check";
import { ChevronDownIcon } from "../icons/chevronDown";
import { SendIcon } from "../icons/send";
import { keycloak } from "../keycloak";
import { ModalComponent } from "../modal";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import colors from '../utils/index.module.scss';
import { companyConfirm, setCompanyConfirmRequest, setCompanyConfirmRequestStatus, setCompanyToConfirmModal, setCompanyToConfirmSettedId } from "./slice";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";

export function CompanyToConfirmModal() {
    const companyToConfirmState = useAppSelector(state => state.companyToConfirm)
    const companyState = useAppSelector(state => state.company)
    const activityState = useAppSelector(state => state.activity)
    const accountantState = useAppSelector(state => state.accountant)

    const dispatch = useAppDispatch()

    let accountantList: ReactNode[] = []

    useEffect(() => {
        if (companyToConfirmState.companyToConfirmModal) {
            if (companyToConfirmState.companyToConfirmSettedId !== '')
                dispatch(findCompanyById(companyToConfirmState.companyToConfirmSettedId))
            if (keycloak.hasRealmRole('admin')) {
                dispatch(findAllAccountantWithoutPagination())
            }
        }
    }, [companyToConfirmState.companyToConfirmModal])

    useEffect(() => {
        if (
            keycloak.hasRealmRole('admin') &&
            accountantState.findAllAccountantsWithoutPaginationStatus === 'successfully' &&
            accountantState.findAllAccountantsWithoutPaginationResponse !== undefined &&
            accountantState.findAllAccountantsWithoutPaginationResponse.data !== undefined &&
            accountantState.findAllAccountantsWithoutPaginationResponse.data.length > 0
        ) {
            const avatars = accountantState.findAllAccountantsWithoutPaginationResponse.data.map(accountant => ({ userId: accountant.userId, objectId: accountant.avatarObjectId }))
            dispatch(findAccountantsListAvatarsById(avatars))
        }
    }, [accountantState.findAllAccountantsWithoutPaginationStatus])

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(setCompanyConfirmRequest(
                [{
                    price: companyState.findCompanyResponse.price,
                    companyId: companyState.findCompanyResponse.id,
                    accountantId: companyState.findCompanyResponse.accountantId
                }]
            ))
            dispatch(setCompanyConfirmRequestStatus('idle'))
        }
    }, [companyState.findCompanyStatus])

    if ((companyState.findCompanyStatus === 'failed' || companyState.findCompanyResponse === undefined) && companyToConfirmState.companyToConfirmModal) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    if (
        accountantState.findAllAccountantsWithoutPaginationStatus === 'loading' ||
        accountantState.findAllAccountantsAvatarStatus === 'loading'
    ) {
        return (<div style={{ display: 'flex', justifyContent: 'center' }}><SpinnerComponent size={"small"} /></div>)
    }

    if (
        keycloak.hasRealmRole('admin') && (
            accountantState.findAllAccountantsWithoutPaginationStatus === 'failed' ||
            accountantState.findAllAccountantsAvatarStatus === 'failed'
        )
    ) {
        return (<></>)
    }

    const company = companyState.findCompanyResponse

    let activityCategory: string = ''
    if (company?.activityCategoryIds !== null && company?.activityCategoryIds !== undefined && company.activityCategoryIds.length > 0) {
        company.activityCategoryIds.forEach((category, _index) => {
            if (activityState.findAllActivityCategoryResponse !== undefined && activityState.findAllActivityCategoryResponse.data !== undefined && activityState.findAllActivityCategoryResponse.data.length > 0) {
                activityState.findAllActivityCategoryResponse.data.forEach(_category => {
                    if (_category.id === category) {
                        let addString = ''
                        if (_index === 0) {
                            addString = ' '
                        } else if (company.activityCategoryIds !== null && _index === company.activityCategoryIds.length - 1) {
                            addString = ' e '
                        } else {
                            addString = ', '
                        }
                        activityCategory = activityCategory + addString + _category.name
                    }
                })
            }
        })
    }

    if (
        accountantState.findAllAccountantsAvatarStatus === 'successfully' &&
        accountantState.findAllAccountantsWithoutPaginationResponse !== undefined &&
        accountantState.findAllAccountantsWithoutPaginationResponse.data !== undefined &&
        accountantState.findAllAccountantsWithoutPaginationResponse.data.length > 0 &&
        accountantState.accountantsListAvatarResponse !== undefined &&
        accountantState.accountantsListAvatarResponse.length > 0
    ) {
        accountantState.findAllAccountantsWithoutPaginationResponse.data.forEach((accountant, index) => {
            const avatar = accountantState.accountantsListAvatarResponse.find(avatar => avatar.userId === accountant.userId)?.objectId
            accountantList.push(
                <MenuItem value={accountant.id} key={'accountant-wizard-' + accountant.id}>
                    <AvatarComponent
                        type={AvatarType.USER}
                        size={AvatarSize.XS}
                        name={accountant.name + ' ' + accountant.surname}
                        src={avatar ?? ''}
                    />
                </MenuItem>
            )
        })
    }

    return (
        <ModalComponent
            open={companyToConfirmState.companyToConfirmModal}
            handleClose={() => { dispatch(setCompanyToConfirmModal(false)); dispatch(setCompanyToConfirmSettedId('')) }}
        >
            <div
                style={{
                    padding: '16px 24px 24px 24px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '24px',
                    minWidth: '480px'
                }}
            >
                {
                    companyState.findCompanyStatus === 'loading' ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}><SpinnerComponent size={"small"} /></div>
                    ) : (
                        <>
                            <div key={'company-' + company?.id} style={{ display: 'flex', flexDirection: 'column', gap: '18px', width: '100%' }}>
                                <div>
                                    <ListItemButton >
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                                                <CheckboxComponent size={CheckboxSize.SM} style={CheckboxStyle.SQUARE} checked disabled />
                                                <p className="m-0 p-0 text text--lg typography--medium typography-neutral--700">
                                                    {'Azienda ' + (company?.id) + ' · Contabilità '}
                                                    <span className="typography--bold">
                                                        {company?.accountingScheme === AccountingSchemeEnum.Ordinary ? 'Ordinaria' : 'Semplificata'}
                                                    </span>
                                                </p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px', justifyContent: 'flex-end' }}>
                                                <span className="text text--lg typography--medium typography-neutral--700">{'€ ' + company?.price.toLocaleString('it-IT', { useGrouping: true })}</span>
                                                <ChevronDownIcon colorBase={colors.neutral500} />
                                            </div>
                                        </div>
                                    </ListItemButton>
                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                        <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 0px 52px', gap: 8 }}>
                                            {
                                                company?.accountingScheme === AccountingSchemeEnum.Ordinary &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    <CheckIcon />
                                                    {company?.journal !== undefined && journalLabelMap.get(numberToJournalMap(company.journal))}
                                                </div>
                                            }
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                <CheckIcon />
                                                <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                                    {'Periodicità '}
                                                    <span className="typography--semibold">
                                                        {company?.periodicity === PeriodicityEnum.Monthly ? 'Mensile' : 'Trimestrale'}
                                                    </span>
                                                </p>
                                            </div>
                                            {
                                                company?.accountingScheme === AccountingSchemeEnum.Ordinary &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    <CheckIcon />
                                                    {company?.banksNumber !== undefined && banksLabelMap.get(numberToBankMap(company.banksNumber))}
                                                </div>
                                            }
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                <CheckIcon />
                                                {company?.annualForeignInvoices !== undefined && foreignLabelMap.get(numberToForeignMap(company.annualForeignInvoices))}
                                            </div>
                                            {company?.specialVatSchemes &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    <CheckIcon />
                                                    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                                        {'Gestione regimi IVA speciali '}
                                                        <span className="typography--semibold">
                                                        </span>
                                                    </p>
                                                </div>
                                            }
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                <CheckIcon />
                                                {company?.atecoCodesNumber !== undefined && atecoLabelMap.get(numberToAtecoMap(company.atecoCodesNumber))}
                                            </div>
                                            {
                                                company?.accountingScheme === AccountingSchemeEnum.Ordinary &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    <CheckIcon />
                                                    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                                        {'Gestione servizi ' + activityCategory}
                                                    </p>
                                                </div>
                                            }
                                            {
                                                company?.professionistWithFund &&
                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                                                    <CheckIcon />
                                                    <p className="m-0 p-0 text text--lg typography--regular typography-neutral--500">
                                                        Professionista con cassa
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </Collapse>
                                </div>
                                <div style={{ width: '100%', height: '1px', backgroundColor: colors.neutral80 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', padding: '0 52px' }}>
                                <FormGroup label="Totale annuale (+ IVA 22%)" inputs={[
                                    <NumericFormat
                                        key={"company-to-confirm-price"}
                                        prefix="€ "
                                        defaultValue={company?.price.toLocaleString('it-IT', { useGrouping: true })}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        customInput={TextInput}
                                        onValueChange={(e) => {
                                            dispatch(setCompanyConfirmRequest(
                                                [{
                                                    price: e.floatValue,
                                                    companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id : '',
                                                    accountantId: company?.accountantId
                                                }]
                                            ))
                                        }}
                                        id={"company-to-confirm-price"}
                                        placeholder={"Totale annuale (+ IVA 22%)"}
                                    />
                                ]} style={"column"} />
                            </div>
                            {/* {
                                keycloak.hasRealmRole("admin") &&
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', padding: '0 52px' }}>
                                    <SelectComponent
                                        label="Contabili"
                                        id={"company-quotation-wizard-special-accountant"}
                                        //@ts-ignore
                                        value={companyToConfirmState.companyConfirmRequest.find(accountant => accountant.accountantId)?.accountantId}
                                        onChange={(e) => {
                                            dispatch(setCompanyConfirmRequest(
                                                [{
                                                    price: company?.price,
                                                    companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id : '',
                                                    accountantId: e.target.value
                                                }]
                                            ))
                                        }
                                        }
                                        menuItems={[
                                            <MenuItem key={'no-special-vat-scheme'} value={0}>Seleziona contabile</MenuItem>,
                                            accountantList
                                        ]}
                                    />
                                </div>
                            } */}
                            <div style={{ width: '100%', height: '1px', backgroundColor: colors.neutral80 }} />
                            <div className="row m-0 p-0 w-100" style={{ gap: '12px' }}>
                                <div className="col m-0 p-0">
                                    <ButtonComponent
                                        label={"Annulla"}
                                        onClick={() => {
                                            dispatch(setCompanyToConfirmModal(false));
                                            dispatch(setCompanyToConfirmSettedId(''))
                                        }}
                                        color={Colors.NEUTRAL}
                                        variant={Variant.OUTLINE}
                                        size={Size.FIT}
                                        iconStyle={IconStyle.OFF}
                                    />
                                </div>
                                <div className="col m-0 p-0">
                                    <ButtonComponent
                                        label={"Approva"}
                                        onClick={() => { dispatch(companyConfirm({ data: companyToConfirmState.companyConfirmRequest })) }}
                                        color={Colors.PRIMARY}
                                        variant={Variant.SOLID}
                                        size={Size.FIT}
                                        iconStyle={IconStyle.RIGHT}
                                        icon={<SendIcon colorBase={""} />}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </ModalComponent>
    )
}